@import "../../common.scss";

.masterclaass_inner1 {
  position: relative;
  .banner_img {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
}

.masterclaass_inner2 {
  .profile_details {
    padding: 6.5em 0;
    text-align: center;
    .profile_cat {
      @include fontStyle(0.875em, 1.4em, normal, 500);
      width: fit-content;
      max-width: 50%;
      margin: 0 auto;
      text-transform: uppercase;
    }
    .profile_name {
      @include fontStyle(1.875em, 1.25em, normal, 200);
      width: fit-content;
      margin: 0 auto;
      padding: 1em 0 0.167em;
      text-transform: uppercase;
    }
    .profile_designation {
      @include fontStyle(0.938em, 1.33em, normal, 300);
      max-width: 24em;
      margin: 0 auto;
    }
    .profile_description {
      @include fontStyle(0.938em, 1.4em, normal, 300);
      max-width: 50.667em;
      margin: 0 auto;
      padding: 2em 0 0;
    }
  }
  .youtube_box {
    width: 100%;
    max-width: 100%;
    aspect-ratio: 16 / 9;
  }
}

.masterclaass_inner3 {
  padding: 9.25em 0 5em;
  .other_heading {
    @include fontStyle(0.875em, 1.4em, normal, 500);
    color: $color-000000;
    text-align: center;
    text-transform: uppercase;
  }
  .other_masterclass_swiper {
    position: relative;
    padding: 3.063em 0 0;
    .swiper-slide {
      height: auto;
      align-self: stretch;
    }
    .masterclass_profile {
      height: 100%;
      padding: 0 0 5.625em;
      color: $color-000000;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-transform: uppercase;
      .masterclass_img {
        width: 100%;
        max-width: 100%;
        margin: 0 0 0.625em;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
      }
      .masterclass_category {
        @include fontStyle(0.688em, 1.545em, 0.44px, 300);
      }
      .masterclass_name {
        @include fontStyle(0.875em, 1.4em, normal, 500);
        padding: 0.357em 0;
      }
      .masterclass_desc {
        @include fontStyle(0.75em, 1.25em, 0.36px, 300);
      }
      .btn_wrapper {
        margin: 0.813em 0 0;
        .general_cta {
          @include fontStyle(0.813em, normal, 0.043px, 400);
          color: $color-000000;
          cursor: pointer;
          .right_arrow {
            display: inline-block;
            width: 100%;
            max-width: 0.769em;
            margin: 0 0 0 0.769em;
          }
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .navigation_wrapper {
      position: absolute;
      top: 35%;
      display: flex;
      justify-content: space-between;
      z-index: 0;
      width: 100%;
      .arrowleft {
        margin-left: -2.5em;
        cursor: pointer;
      }
      .arrowright {
        margin-right: -2.5em;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 992px) {
  .masterclaass_inner2 {
    .profile_details {
      padding: 5.8em 0 9em;
      .profile_cat {
        font-size: 1.4em;
      }
      .profile_name {
        font-size: 2.5em;
        padding: 1.6em 0 0.2em;
      }
      .profile_designation,
      .profile_description {
        font-size: 1.5em;
      }
    }
  }
  .masterclaass_inner3 {
    padding: 7em 0 7.4em;
    .other_heading {
      font-size: 1.4em;
    }
    .other_masterclass_swiper {
      padding: 3.6em 0 0;
      .swiper-slide {
        height: auto;
        align-self: stretch;
      }
      .masterclass_profile {
        padding: 0;
        height: 100%;
        .img_text_body {
          display: block;
          height: 100%;
        }
        .masterclass_category {
          font-size: 1.1em;
        }
        .masterclass_name {
          font-size: 1.4em;
        }
        .masterclass_desc {
          font-size: 1.1em;
        }
        .btn_wrapper {
          .general_cta {
            font-size: 1.3em;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .masterclaass_inner2 {
    .text_video_container {
      padding: 0 2.5em;
    }

    .profile_details {
      .profile_cat,
      .profile_designation,
      .profile_name,
      .profile_description {
        max-width: 100%;
      }
      .profile_description {
        padding: 2em 1.667em 0;
      }
    }
  }

  .masterclaass_inner3 {
    .my_container {
      padding: 0 2.5em;
    }
    .other_heading {
      max-width: 17.857em;
      margin: 0 auto;
    }
    .other_masterclass_swiper {
      margin: 0 -2.5em 0 0;
      .masterclass_profile {
        .masterclass_img {
          margin: 0 0 1em;
        }
        .btn_wrapper{
          margin: 2.1em 0 0;
        }
      }
    }
  }
}
