@import "../../common.scss";
.forgot_password_main_wrapper {
  padding: 11.938em 0;
  .forgot_password_sec {
    max-width: 670px;
    margin: 0 auto;
  }
  .section_heading {
    text-align: center;
    text-transform: uppercase;
  }
  .forgot_desc {
    max-width: 27.5em;
    text-align: center;
    margin: 1.25rem auto 3.75rem;
  }
  .reset_otp {
    padding-top: 0;
    padding-bottom: 0;
  }
  .content_wrapper {
    width: 100%;
    max-width: 27.5em;
    margin: auto;
    .otp_inbox_msg {
      margin: 1.25rem 0 0;
      text-align: center;
    }
    .inbox_notice_msg {
      text-align: center;
      a {
        color: $color-000000;
      }
    }
  }
  .otp_input_wrapper {
    justify-content: center;
    margin-top: 3.75rem;
    div {
      &:first-child {
        .otp_input {
          margin-left: 0;
        }
      }
    }
  }
  .otp_input {
    background-color: transparent;
    border: 1.5px solid $color-000000;
    font-size: 1.875em;
    width: 3.17em !important;
    height: 3.17em;
    display: inline-block;
    border-radius: 0.333em;
    margin-left: 1.833em;
  }
  .otp_focused {
    border: 2px solid $color-000000;
  }
  .resend_otp_text {
    font-family: "GT Amrica Mono";
    font-size: 0.813rem;
    text-transform: uppercase;
    text-align: center;
    margin-top: 1.1em;
    font-size: 0.813rem;
    cursor: pointer;
  }
  .otp_sent_text {
    margin-top: 0.7em;
    text-align: center;
    font-family: "GT America Mono";
  }
  .otp_verify_btn {
    background: transparent;
    margin-top: 3.75rem;
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
  }
  .reset_password_sec {
    max-width: 670px;
    margin: 0 auto;
    .reset_pass_heading {
      text-align: center;
      margin-bottom: 3.75rem;
    }
  }
  .btn_wrapper {
    .project-button {
      background: transparent;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (max-width: 992px) {
  .forgot_password_main_wrapper {
    .forgot_desc {
      font-size: 1.125rem;
      max-width: 75%;
      margin: 1.75rem auto 3rem;
    }
    .otp_inbox_msg {
      font-size: 1.125rem;
      text-align: center;
    }
    .inbox_notice_msg {
      font-size: 1rem;
      text-align: center;
    }
    .resend_otp_text,
    .otp_sent_text {
      font-size: 0.875rem;
    }
    .otp_input {
      width: 2.833em !important;
      height: 2.5em;
    }
  }
}

@media (max-width: 767px) {
  .forgot_password_main_wrapper {
    padding: 14.7em 2.7em 12.3em 2.4em;
    .form_field_wrapper {
      margin-bottom: 1.25rem;
    }
    .content_wrapper {
      max-width: 100%;
    }
    .otp_input {
      margin-left: 0.7em;
    }
    .section_heading {
      font-size: 1.25rem;
    }
    .forgot_desc {
      font-size: 0.938rem;
      max-width: 100%;
      margin: 0.563rem auto 1.875rem;
    }
    .otp_inbox_msg {
      font-size: 0.938rem;
      text-align: center;
    }
    .inbox_notice_msg {
      font-size: 0.875rem;
      text-align: center;
    }
    .btn_wrapper {
      margin-top: 2.5rem;
      .project-button {
        font-size: 0.813rem;
        max-width: 15rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .resend_otp_text,
    .otp_sent_text {
      font-size: 0.813rem;
    }
    .otp_verify_btn {
      margin-top: 2.5rem;
    }
  }
}
