@import "../../common.scss";

.winner {
  .my_container {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto;
    padding-left: 99px;
    padding-right: 85px;
  }
}
.winner_sec1 {
  position: relative;
  // .winner_banner {
  //   position: relative;
  //   width: 100%;
  //   height: 100vh;
  // }
  .winner_banner_image {
    height: 100vh;
    background-color: #ff6b20;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .winner_image_wrapper {
      .winner_img {
        width: 100%;
        max-width: 29em;
      }
    }
  }
  .banner_content_box1 {
    position: absolute;
    top: 8.375em;
    left: 2.5em;
  }
  .winnerbanner_heading {
    font-size: 6.875em;
    color: #fbf7f3;
    font-weight: 400;
    line-height: 110px;
  }
  .winnerbanner_heading2 {
    margin: 0.9rem 0 0;
  }
  .winnerbanner_heading4 {
    margin: 0.75rem 0 0;
  }
  .banner_content_box2 {
    position: absolute;
    bottom: 15.125em;
    right: 2.25em;
  }
  .banner_content_box3 {
    position: absolute;
    bottom: 7.5em;
    right: 2.5em;
  }
  .mbwinner_banner_image {
    // height: 100vh;
    height: 41.688rem;
    background-color: #ff6b20;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    // .mbwinner_image_wrapper {
    //   .mbwinner_img {
    //     width: 100%;
    //     max-width: 20.9em;
    //   }
    // }
  }
  // .mbwinner_banner {
  //   position: relative;
  //   width: 100%;
  //   height: 100vh;
  // }
  // .mbwinner_banner_image {
  //   .mbwinner_img {
  //     width: 100%;
  //     max-width: 20.9em;
  //   }
  // }
}

.winner_sec2 {
  // padding-top: 12.25em;
  padding-top: 7.5em;
  background-color: #fbf7f3;
  .winners_container {
    text-align: center;
    .heading_1 {
      color: #ff260b;
      font-size: 3em;
      font-weight: 400;
      line-height: 3.75rem;
      margin-bottom: 0.417em;
    }
    .text_1 {
      color: #ff260b;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.55rem;
      margin: 0 auto;
      width: 100%;
      max-width: 41.813em;
      margin-bottom: 0.625em;
      a {
        color: #ff260b;
        text-decoration: none;
      }
    }
    .text_2 {
      font-family: "Inter", Good Sans;
      font-size: 0.813rem;
      font-weight: 400;
      line-height: 1.259rem;
      letter-spacing: 0em;
      width: 100%;
      max-width: 33.77em;
      margin: 0 auto;
    }
  }
}

.winner_sec3 {
  padding: 7.5rem 0;
  background-color: #fbf7f3;
  .winner_row {
    justify-content: space-between;
    .winner_col {
      text-align: center;
      margin-top: 12.25em;
      color: #1e3c4e;
      // padding: 0 2em;
      max-width: 44.189%;
      padding: 0;
      .title_1 {
        color: #ff260b;
        font-size: 2.375em;
        font-weight: 400;
        line-height: 2.969rem;
        margin-bottom: 1.29em;
        text-transform: uppercase;
      }
      .box_1 {
        .box1_img {
          width: 100%;
          max-width: 20.313em;
          display: block;
          margin: 0 auto;
        }
        .box_content {
          margin: 2.5em 0 1.875em;
          .heading_1 {
            font-size: 1em;
            font-weight: 400;
            line-height: 1.55em;
            margin-bottom: 0.438em;
          }
          .text_1 {
            font-family: "GT America Mono", Good Sans;
            font-size: 0.813em;
            font-weight: 400;
            line-height: 1.375em;
          }
        }
        .text_2 {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.55em;
          margin-bottom: 1.25em;
        }
        .box_link {
          display: flex;
          justify-content: center;
          column-gap: 1em;
          .link_1 {
            color: #1e3c4e;
            font-family: "GT America Mono", Good Sans;
            font-size: 0.815em;
            font-weight: 400;
            line-height: 1.375em;
          }
        }
      }
    }
  }
  .winner_col {
    &:nth-child(1) {
      margin-top: 0;
    }
    &:nth-child(2) {
      margin-top: 0;
    }
  }
}

@media (max-width: 1300px) {
  .winner_sec2 {
    .winners_container {
      .heading_1 {
        line-height: 3rem;
      }
    }
  }
}

@media (max-width: 1194px) {
  .winner {
    .my_container {
      max-width: 1031px;
      padding: 0 15px;
    }
  }
}

@media (max-width: 992px) {
  .winner_sec1 {
    .winner_banner_image {
      height: 75vh;
    }
    .winnerbanner_heading {
      line-height: 60px;
    }
  }
  .winner_sec2 {
    .winners_container {
      // .text_1 {
      //   max-width: 100%;
      // }
      .text_2 {
        font-size: 0.875rem;
      }
    }
  }
  .winner_sec3 {
    padding: 0em 0 6.125em;
    .winner_row {
      .winner_col {
        padding: 0 15px;
        margin-top: 5rem;
        .title_1 {
          font-size: 1.25rem;
        }
        .box_1 {
          .box_content {
            .heading_1 {
              font-size: 1rem;
            }
            .text_1 {
              font-size: 0.875rem;
            }
          }
          .box_link {
            .link_1 {
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .winner_sec2 {
    .winners_container {
      .heading_1 {
        font-size: 2.188rem;
      }
    }
  }
}

@media (max-width: 835px) {
  .winner_sec3 {
    .winner_row {
      .winner_col {
        max-width: 48%;
      }
    }
  }
}

@media (max-width: 600px) {
  .winner {
    .my_container {
      padding: 0 15px 0 16px;
    }
  }

  .winner_sec1 {
    .banner_content_box1 {
      // top: 21.375em;
      top: 14.125em;
      left: 1.6em;
    }
    .winnerbanner_heading {
      font-size: 3.8em;
      line-height: 42px;
      letter-spacing: 0.33000001311302185px;
      text-align: left;
    }
    .banner_content_box2 {
      // bottom: 25.5em;
      bottom: 18em;
      right: 1.8em;
    }
    .banner_content_box3 {
      // bottom: 21em;
      bottom: 13.75em;
      right: 1.8em;
    }
    .winnerbanner_heading2 {
      margin: 0;
    }
  }

  .winner_sec2 {
    padding-top: 8em;
    .winners_container {
      padding-right: 0.2em;
      .heading_1 {
        font-size: 2.4em;
        line-height: 1.65rem;
      }
      .text_1 {
        font-size: 1.6em;
        line-height: 1.453rem;
      }
      .text_2 {
        font-size: 1.3em;
        line-height: 1.25rem;
      }
    }
  }

  .winner_sec3 {
    padding: 8em 0 8em;
    .winner_row {
      .winner_col {
        max-width: 100%;
        margin-top: 8em;
        padding: 0;
        .title_1 {
          font-size: 2em;
          line-height: 1.688rem;
          margin-bottom: 2em;
        }
        .box_1 {
          .box1_img {
            max-width: 25.6em;
          }
          .box_content {
            margin: 2.2em 0 1.6em;
            .heading_1 {
              font-size: 1.5em;
              line-height: 1.438rem;
              margin-bottom: 0.468em;
            }
            .text_1 {
              font-size: 1.1em;
              line-height: 1.375rem;
              letter-spacing: -0.6000000238418579px;
            }
          }
          .text_2 {
            font-size: 1.5em;
            line-height: 1.438rem;
            margin-bottom: 1.335em;
          }
          .box_link {
            flex-direction: column;
            .link_1 {
              font-size: 1.3em;
              line-height: 1.375rem;
            }
            .link_1 {
              &:nth-child(1) {
                margin-bottom: 0.77em;
              }
            }
          }
        }
      }
    }
    .winner_col {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
}
