@import "../../common.scss";
.master_main {
  .master_container {
    max-width: 1170px;
    padding: 0;
  }

  .masterclass_sec1 {
    .text_container {
      padding: 14em 0;
      text-align: center;
      .master_heading {
        @include fontStyle(1.563em, normal, normal, 200);
        max-width: 24em;
        margin: 0 auto;
      }
      .master_desc {
        max-width: 33.333em;
        line-height: 1.3em;
        margin: 1.111em auto 0;
      }
    }
  }
  .masterclass_sec2 {
    padding: 6.25em 0 8.438em;
    .filter_main_box {
      text-align: center;
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      .categories_wrapper {
        margin: 0.688em 0;
        .category_list {
          display: flex;
          justify-content: center;
          .category {
            cursor: pointer;
            padding: 0 0.714em;
            border-right: 1px solid $color-BCBCBC;
            &:last-child {
              padding: 0 0 0 0.714em;
              border: none;
            }
          }
        }
        .checkbox_input_wrapper {
          .checkox_input {
            display: none;
          }
          .checkbox_label {
            cursor: pointer;
            width: fit-content;
            text-transform: uppercase;
            color: $color-BCBCBC;
            margin: 0;
          }
          .label_text {
            @include fontStyle(0.875em, 1.4em, normal, 500);
            text-transform: uppercase;
          }
          .checkox_input:checked ~ .checkbox_label {
            color: $color-000000;
          }
        }
      }

      .keywords_wrapper {
        .keyword_list {
          display: flex;
          justify-content: center;
          .keyword {
            cursor: pointer;
            padding: 0 0.714em;
            border-right: 1px solid $color-BCBCBC;
            &:last-child {
              padding: 0 0 0 0.714em;
              border: none;
            }
          }
        }
        .checkbox_input_wrapper {
          .checkox_input {
            display: none;
          }
          .checkbox_label {
            cursor: pointer;
            width: fit-content;
            text-transform: uppercase;
            color: $color-BCBCBC;
            margin: 0;
          }
          .label_text {
            @include fontStyle(0.875em, 1.4em, normal, 500);
            text-transform: uppercase;
          }
          .checkox_input:checked ~ .checkbox_label {
            color: $color-000000;
          }
        }
      }
    }

    .masterclasss_wrapper {
      padding: 6.125em 0 0;
      .masterclass_profile {
        height: 100%;
        padding: 0 1.25em 5.625em;
        color: $color-000000;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-transform: uppercase;
        .masterclass_img {
          width: 100%;
          max-width: 100%;
          margin: 0 0 0.625em;
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
        }
        .masterclass_category {
          @include fontStyle(0.688em, 1.545em, 0.44px, 300);
        }
        .masterclass_name {
          @include fontStyle(0.875em, 1.4em, normal, 500);
          padding: 0.357em 0;
          .mb_break {
            display: none;
          }
        }
        .masterclass_desc {
          @include fontStyle(0.75em, 1.25em, 0.36px, 300);
        }
        .btn_wrapper {
          margin: 0.813em 0 0;
          .general_cta {
            @include fontStyle(0.813em, normal, 0.043px, 400);
            color: $color-000000;
            cursor: pointer;
            .right_arrow {
              display: inline-block;
              width: 100%;
              max-width: 0.769em;
              margin: 0 0 0 0.769em;
            }
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .master_main {
    .masterclass_sec1 {
      .text_container {
        .master_heading {
          max-width: 95%;
          font-size: 2.5em;
        }
        .master_desc {
          max-width: 70%;
        }
      }
    }
    .masterclass_sec2 {
      .filter_main_box {
        .categories_wrapper,
        .keywords_wrapper {
          .checkbox_input_wrapper {
            .label_text {
              font-size: 1.1em;
            }
          }
        }
      }
      .masterclasss_wrapper {
        .masterclass_profile {
          .masterclass_category {
            font-size: 1.1em;
          }
          .masterclass_name {
            font-size: 1.4em;
            .mb_break {
              display: block;
            }
          }
          .masterclass_desc {
            font-size: 1.1em;
            .dp_break {
              display: none;
            }
          }
          .btn_wrapper {
            .general_cta {
              font-size: 1.3em;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .master_main {
    .masterclass_sec1 {
      .text_container {
        padding: 15em 0.5em 15em 0.6em;
        .master_heading {
          max-width: 100%;
        }
        .master_desc {
          max-width: 100%;
        }
      }
    }
    .masterclass_sec2 {
      padding: 5em 0 8em;
      .filter_main_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        .categories_wrapper,
        .keywords_wrapper {
          .category_list,
          .keyword_list {
            overflow-x: scroll;
            margin: 0 auto;
            &::-webkit-scrollbar {
              display: none;
            }
            .category,
            .keyword {
              border: 1px solid $color-BCBCBC;
              padding: 1px 5px;
              margin: 0 8px;
              &:last-child {
                border: 1px solid $color-BCBCBC;
                padding: 1px 5px;
              }
            }
            .checkbox_input_wrapper {
              .checkbox_label {
                border-radius: 3px;
              }
              .label_text {
                @include fontStyle(1.1em, 17px, normal, 300);
                white-space: nowrap;
              }
            }
          }

          .mbyear_list,
          .mbcategory_list {
            width: fit-content;
          }
          .mbkeyword_list {
            justify-content: flex-start;
            padding: 0 0 0 0.2em;
          }
        }
        .categories_wrapper {
          margin: 2em 0;
        }
      }
      .masterclasss_wrapper {
        padding: 5em 8em 0 7.7em;
        .masterclass_profile {
          padding: 0 0 7em;
          .masterclass_img {
            margin: 0 0 0.5em;
          }
          .btn_wrapper {
            margin: 1.5em 0 0;
          }
          .masterclass_desc {
            max-width: 100%;
            line-height: 17px;
          }
        }
      }
    }
  }
}
