.loader-img {
    position: relative;
    background-image: url("../../../public/loader-new.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 9.2em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    background-color: #fff;
    max-width: 9.2em;
    margin: 0 auto;
}