@import "../../common.scss";

.meetwinner_sec1 {
  position: relative;
  .meetwinner_banner_image {
    height: 100vh;
    background-color: #9fa6d4;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .meetwinner_image_wrapper {
      position: relative;
      .meetwinner_img {
        width: 100%;
        max-width: 39.3125em;
      }
    }
  }
  .banner_content_box1 {
    position: absolute;
    top: -2.35%;
    // top: 0;
    left: -60%;
  }
  .winnerbanner_heading {
    font-size: 6.875em;
    color: #fbf7f3;
    font-weight: 400;
    line-height: 1;
    text-align: left;
    text-transform: uppercase;
  }
  .banner_content_box2 {
    .winnerbanner_heading {
      text-align: right;
    }
    position: absolute;
    // bottom: 4.25em;
    // right: 2.5em;
    bottom: -24.5%;
    right: -55%;
  }
  .mbmeetwinner_banner_image {
    // height: 100vh;
    height: 41.688rem;
    background-color: #9fa6d4;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mbmeetwinner_image_wrapper {
    margin-top: -11em;
  }
}

.meetwinner_sec2 {
  background-color: #9fa6d4;
  // padding: 11.3125em 0 13.25em;
  padding: 11.0625em 0 10.9375em;
  // margin-bottom: 0.125em;
  margin: 2px 0 0;
  .meetwinner_content {
    .meetwinner_text {
      color: #fff;
      text-align: center;
      font-size: 1.25em;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4;
      letter-spacing: 0.05px;
      padding: 0 7.5em;
      a {
        color: #fff;
      }
    }
  }
}

.meetwinner_sec3 {
  .details_container {
    background-color: #fbf7f3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 2em 5.25em 2em 5.4375em;
    .category_head {
      color: #1e3c4e;
      text-align: center;
      font-size: 2.375em;
      font-weight: 400;
      line-height: 1.15;
      text-transform: uppercase;
    }
    .detail_wrapper {
      margin: 1.875em 0 1.875em;
    }
    .brand {
      color: #1e3c4e;
      text-align: center;
      font-size: 1.25em;
      font-weight: 400;
      // line-height: 1.5;
      line-height: 1.4;
      // margin: 0.5em 0;
    }
    .winner_name {
      color: #1e3c4e;
      text-align: center;
      // font-family: "GT America Mono";
      font-size: 1em;
      font-weight: 400;
      line-height: 1.4;
      // text-transform: capitalize;
      // padding: 0 12em;
    }
    .desc {
      color: #1e3c4e;
      text-align: center;
      font-size: 1em;
      font-weight: 400;
      line-height: 1.55;
      // margin-top: 2.5em;
    }
    .meetwinner_btn_container {
      align-items: center;
      display: flex;
      gap: 1.125em;
      justify-content: center;
      // margin-top: 2.5em;
      margin-top: 1.875em;
      .meetwinner_btn_wrapper {
        max-width: 8.6875em;
        width: 100%;
      }
      .project-button {
        background-color: transparent;
        border: 1.5px solid #1e3c4e;
        color: #1e3c4e;
        padding: 0.415em 0.415em;
        &:hover {
          background-color: #ff6b20;
          border: 1.5px solid #ff6b20;
          color: #fbf7f3;
        }
        &::before {
          background-color: #ff6b20;
        }
        &::after {
          background-color: #ff6b20;
        }
      }
      .project-button.purple {
        background-color: transparent;
        &:hover {
          background-color: #9fa6d4;
          border: 1.5px solid #9fa6d4;
        }
        &::before {
          background-color: #9fa6d4;
        }
        &::after {
          background-color: #9fa6d4;
        }
      }
      .project-button.blue {
        background-color: transparent;
        &:hover {
          background-color: #29afce;
          border: 1.5px solid #29afce;
        }
        &::before {
          background-color: #29afce;
        }
        &::after {
          background-color: #29afce;
        }
      }
      .project-button.yellow {
        background-color: transparent;
        &:hover {
          background-color: #fdbc00;
          border: 1.5px solid #fdbc00;
        }
        &::before {
          background-color: #fdbc00;
        }
        &::after {
          background-color: #fdbc00;
        }
      }
      .project-button.brown {
        background-color: transparent;
        &:hover {
          background-color: #c6557e;
          border: 1.5px solid #c6557e;
        }
        &::before {
          background-color: #c6557e;
        }
        &::after {
          background-color: #c6557e;
        }
      }
      .project-button.green {
        background-color: transparent;
        &:hover {
          background-color: #6da991;
          border: 1.5px solid #6da991;
        }
        &::before {
          background-color: #6da991;
        }
        &::after {
          background-color: #6da991;
        }
      }
    }
    // .watch_btn_container {
    //   margin-top: 2.125em;
    // }
  }

  .meetwinner_img_container {
    height: 100%;
    position: relative;
    .meetwinner_img {
      width: 100%;
      max-width: 100%;
    }
    .animated_text {
      width: 100%;
      max-width: 100%;
      overflow: hidden;
      background-color: red;
    }
    .img_text {
      white-space: nowrap;
      display: inline-block;
      animation: animated_text 10s linear infinite;
    }

    .meet_img_slider {
      // background-color: #ff260b;
      // position: absolute;
      // bottom: 0;

      // padding: 0.97188em 0;
      display: flex;
      align-items: center;
      height: 100%;
      min-height: 3.688em;

      // z-index: 10;
      .swiper-wrapper {
        align-items: center;
      }
      .meet_slider_logo {
        width: 100%;
        max-width: 9.1875em;
        margin: 0 auto;
        display: block;
      }
      .meet_slider_imagine {
        width: 100%;
        max-width: 3.125em;
        margin: 0 auto;
        display: block;
      }
      .meet_slider_breakthrough {
        max-width: 6.75em;
        padding: 0 0.25em;
      }
      .meet_slider_create {
        max-width: 6em;
        padding: 0 0.3em;
      }

      .meet_slider_winner {
        width: 100%;
        max-width: 5em;
        margin: 0 auto;
        display: block;
      }
    }
  }
}

@media (min-width: 1536px) {
  .meetwinner_sec1 {
    .banner_content_box1 {
      left: -63%;
    }
  }
}
@media (min-width: 2560px) {
  .meetwinner_sec1 {
    .banner_content_box2 {
      bottom: -2%;
      right: -100%;
    }
  }
}

@media (max-width: 1536px) and (max-height: 769px) {
  .meetwinner_sec1 {
    .meetwinner_banner_image {
      .meetwinner_image_wrapper {
        .meetwinner_img {
          max-width: 35em;
        }
      }
      .banner_content_box1 {
        left: -68%;
      }
      .banner_content_box2 {
        right: -58%;
      }
    }
  }
}

@media (max-width: 1366px) {
  .meetwinner_sec2 {
    .meetwinner_content {
      .meetwinner_text {
        padding: 0 17em;
      }
    }
  }
}

@media (max-width: 1280px) {
  .meetwinner_sec3 {
    .meetwinner_img_container {
      .meet_img_slider {
        min-height: 4em;
      }
    }
  }
}

@media (max-width: 992px) {
  .meetwinner_sec1 {
    .meetwinner_banner_image {
      height: 75vh;
    }
    .banner_content_box1 {
      top: -42%;
      left: -49%;
    }
    .banner_content_box2 {
      bottom: -43%;
      right: -49%;
    }
    .mbmeetwinner_banner_image {
      position: relative;
      height: 75vh;
    }
    .mbmeetwinner_image_wrapper {
      position: absolute;
      top: 7%;
      width: 100%;
      max-width: 100%;
      margin-top: 0;
      .mbmeetwinner_img {
        width: 100%;
        max-width: 100%;
        height: 53vh;
        object-fit: cover;
        object-position: top;
      }
    }
    .banner_content_box1 {
      top: initial;
      left: initial;
      bottom: 2.25em;
    }
    .winnerbanner_heading {
      color: #fff;
      font-size: 3.166666666666667em;
      letter-spacing: 0.33px;
      line-height: 1.1;
      text-align: center;
    }
    .meetwinner_content_box1 {
      .meetwinnerbanner_text_1 {
        color: #fff;
        font-size: 1.25em;
        font-weight: 400;
        line-height: 1.55;
        margin-top: 0.1em;
        text-align: center;
      }
    }
  }
}
// @media (max-width: 935px) {
//   .meetwinner_sec1 {
//     .banner_content_box1 {
//       top: -45%;
//       left: -31%;
//     }
//     .banner_content_box2 {
//       bottom: -46%;
//       right: -31%;
//     }
//   }
// }

@media (max-width: 835px) {
  .meetwinner_sec1 {
    .mbmeetwinner_image_wrapper {
      top: 10%;
      .mbmeetwinner_img {
        height: 50vh;
      }
    }
    .banner_content_box1 {
      bottom: 2.25em;
    }
  }
  .meetwinner_sec2 {
    .meetwinner_content {
      .meetwinner_text {
        padding: 0 8em;
      }
    }
  }
}

@media (max-width: 835px) and (max-height: 1120px) {
  .meetwinner_sec1 {
    // .meetwinner_banner_image {
    //   .meetwinner_image_wrapper {
    //     .meetwinner_img {
    //       max-width: 36.3125em;
    //     }
    //   }
    // }
    .banner_content_box1 {
      // top: -39%;
      bottom: 1.75em;
    }
    // .banner_content_box2 {
    //   bottom: -41%;
    // }
    // .winnerbanner_heading {
    //   font-size: 5.5em;
    // }
  }
}

@media (max-width: 600px) {
  .meetwinner_sec1 {
    .mbmeetwinner_banner_image {
      height: 41.688rem;
      .mbmeetwinner_img {
        height: 100%;
      }
    }
    .banner_content_box1 {
      top: initial;
      // bottom: 3.9375em;
      bottom: 5.9375em;
      left: initial;
    }
    .winnerbanner_heading {
      color: #fff;
      text-align: center;
      font-size: 3.8em;
      line-height: 1.1;
      letter-spacing: 0.33px;
    }

    .meetwinner_content_box1 {
      // position: absolute;
      // bottom: 0;
      // left: 50%;
      // transform: translate(-50%, -50%);
      // width: 100%;
      .meetwinnerbanner_text_1 {
        color: #fff;
        text-align: center;
        font-size: 1.5em;
        font-weight: 400;
        line-height: 1.55;
        margin-top: 0.1em;
      }
      .scroll_down {
        display: block;
        margin: 0 auto;
        // margin-top: 1.938em;
        margin-top: 1.4em;
        width: 100%;
        max-width: 1.25em;
        cursor: pointer;
      }
    }
  }

  .meetwinner_sec2 {
    padding: 5em 0 6.2em;
    // margin-bottom: 0;
    margin: 2px 0 0;
    .meetwinner_content {
      .meetwinner_text {
        font-size: 1.5em;
        line-height: 1.55;
        padding: 0 12px 0 10px;
      }
    }
  }

  .meetwinner_sec3 {
    .meetwinner_img_container {
      .meetwinner_img {
        max-width: 100%;
      }
      .meet_img_slider {
        .meet_slider_imagine {
          max-width: 1.932em;
        }
        .meet_slider_breakthrough {
          // max-width: 4.5em;
          max-width: 5em;
        }
        .meet_slider_create {
          max-width: 3.75em;
        }
        .meet_slider_logo {
          max-width: 7.725em;
        }
        .meet_slider_winner {
          max-width: 4.15em;
        }
      }
    }
    .details_container {
      padding: 5em 1.6em 5em 1.7em;
      .category_head {
        font-size: 2.4em;
        line-height: 1.1;
      }
      .detail_wrapper {
        margin: 2em 0 2em;
      }
      .brand {
        font-size: 1.7em;
        line-height: 1.35;
        letter-spacing: 0.25px;
        // margin: 0.2941176470588235em 0;
      }
      .winner_name {
        font-size: 1.3em;
        line-height: 1.69231;
      }
      .desc {
        font-size: 1.5em;
        line-height: 1.55;
        text-align: left;
        // margin-top: 2.666666666666667em;
      }
      .meetwinner_btn_container {
        gap: 1.8em;
        margin-top: 2em;
        .meetwinner_btn_wrapper {
          max-width: 13.9em;
        }
        .project-button {
          padding: 0.285em 0.415em;
        }
      }
      // .watch_btn_container {
      //   margin-top: 2.4em;
      // }
    }
  }
}

@media (max-width: 451px) {
  .meetwinner_sec1 {
    .mbmeetwinner_banner_image {
      position: relative;
    }
    .mbmeetwinner_image_wrapper {
      position: absolute;
      top: 10%;
      width: 100%;
      max-width: 100%;
      margin-top: 0;
      .mbmeetwinner_img {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
