@import "../../common.scss";

.faq_sec1 {
  padding: 16.563em 0 11.563em;
  width: 100%;
  max-width: 60.125em;
  margin: 0 auto;
  .section_subheading {
    text-align: center;
  }

  .faq_list_box {
    padding: 3.125em 0 0;
    .faq_item {
      padding: 2.5em 12em 2.5em 0;
      position: relative;
      cursor: pointer;
      border-bottom: 1px solid $color-000000;
    }
    .faq_item.active {
      box-shadow: none;
    }
    .faq_item:first-child {
      border-top: none;
    }
    .faq_title {
      @include fontStyle(1.125em, 1.4em, normal, 300);
      text-transform: uppercase;
    }
    .faq_desc {
      position: relative;
      height: 0;
      overflow: hidden;
      width: 100%;
      max-width: 100%;
      padding-left: 2.5rem;
      font-size: 1rem;
      font-weight: 300;
      line-height: 1.55rem;
      letter-spacing: 0;
      li {
        font-size: 1rem;
        line-height: 1.55rem;
        letter-spacing: 0;
        position: relative;
        list-style-type: decimal;
        display: list-item;
        list-style-position: outside;
        padding: 0.75rem 0 0.75rem 0;
        a {
          color: $color-000000;
          text-decoration: underline;
        }
        .disc_style {
          padding-top: 1.5rem;
          li {
            position: relative;
            list-style-type: none;
            padding: 0 0 0.75rem 1.5rem;
            &::before {
              content: "";
              position: absolute;
              top: 0.6em;
              left: 0em;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: $color-000000;
            }
          }
        }
      }
    }
    .faq_para {
      margin-left: -2.5em;
      font-size: 1rem;
      font-weight: 300;
      line-height: 1.55rem;
      letter-spacing: 0;
      position: relative;
      a {
        color: $color-000000;
        text-decoration: underline;
      }
    }
    .faq_desc.active {
      margin-top: 1.25em;
      height: auto;
    }
    .faq_open_close_logo {
      position: absolute;
      width: 100%;
      max-width: 2.063em;
      top: 2.5em;
      right: 0;
    }
  }
}

@media (max-width: 992px) {
  .faq_sec1 {
    .faq_list_box {
      .faq_desc {
        font-size: 1em;
        li {
          padding-left: 1.4em;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .faq_sec1 {
    padding: 16.1em 0 15em;
    .section_subheading {
      padding: 0 0 6em;
    }
    .faq_list_box {
      padding: 15em 3em 0;
      border-top: 1px solid $color-BCBCBC;
      .faq_item {
        padding: 2em 6.5em 2em 0;
      }
      .faq_title {
        font-size: 1.4em;
        text-align: left;
      }
      .faq_open_close_logo {
        max-width: 2.1em;
        top: 2.5em;
      }
      .faq_desc {
        li {
          padding-left: 0.5rem;
          font-size: 0.813rem;
          &:before {
            top: 0.9em;
          }
          .disc_style {
            li {
              padding: 0 0 0.75rem 1rem;
            }
          }
        }
      }
    }
  }
}
