@import "../../common.scss";

.eventnight_sec1 {
  position: relative;
  overflow: hidden;
  .exhibition_banner_image_wrapper {
    height: 100vh;
    width: 100%;
    max-width: 100%;
    .exhibition_banner_img {
      width: 100%;
      max-width: 100%;
      height: 100vh;
      object-position: center top;
      // object-fit: cover;
    }
    .exhibition_banner_content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;
      max-width: 100%;
      .exhibition_head {
        @include fontStyle(3.125em, 1em, -5%, 200);
        color: $color-FFFFFF;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}

.eventnight_sec2 {
  padding: 14.75em 0;
  overflow: hidden;
  max-width: 47.5em;
  margin: 0 auto;
  .desc {
    @include fontStyle(1.125em, 1.4em, normal, 300);
    text-align: center;
  }
}

.eventnight_sec3 {
  overflow: hidden;
  .exhibiton_img_container {
    position: relative;
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    .exhibiton_img {
      width: 100%;
      max-width: 100%;
      object-fit: cover;
    }
    .play_button {
      width: 100%;
      max-width: 4.6875em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .img_desc {
    @include fontStyle(0.875em, 1.4em, normal, 500);
    text-transform: uppercase;
    padding: 1.072em 0 0 2.8572em;
    width: 100%;
    max-width: 51.5em;
  }
}


.eventnight_sec4 {
  padding-top: 6.25em;
  overflow: hidden;
  .sec4_row {
    margin: 0 auto;
    width: 100%;
    max-width: 34.6875em;
    .sec4_col {
      margin-bottom: 6.6875em;
      &:last-child {
        margin-bottom: 0;
      }
      .sec4Swiper {
        .swiper-horizontal > .swiper-pagination-bullets,
        .swiper-pagination-bullets.swiper-pagination-horizontal,
        .swiper-pagination-custom,
        .swiper-pagination-fraction {
          bottom: initial;
          top: 40.5em;
        }

        .swiper-pagination-bullet {
          background-color: #ffffff66;
          opacity: 1;
          width: 1.5em;
          height: 2px;
          border-radius: 0;
          margin: 0 0.25em;
          position: relative;
          &::before {
            content: "";
            background: rgba(255, 255, 255, 0.4);
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
          }
        }

        .swiper-pagination-bullet-active {
          background-color: #ffffff66;
          color: #ffffff;
          &::before {
            background-color: #ffffff;
            -webkit-animation: slide-progress 3.5s cubic-bezier(0.3, 0, 0.3, 1)
              forwards;
            animation: slide-progress 3.5s cubic-bezier(0.3, 0, 0.3, 1) forwards;
          }
        }

        .swiper-paused.swiper-pagination-bullet-active {
          &::before {
            -webkit-animation-play-state: paused;
            animation-play-state: paused;
          }
        }
      }
      .img_wrapper {
        width: 100%;
        max-width: 100%;

        .sec4_img {
          width: 100%;
          max-width: 100%;
        }
        .desc {
          @include fontStyle(0.875em, 1.4em, normal, 500);
          text-transform: uppercase;
          padding: 1.071em 0 0;
        }
        .artist {
          @include fontStyle(0.688em, 1.545em, normal, 300);
          text-transform: uppercase;
          padding-top: 0.909em;
        }
      }
    }
  }
}

.eventnight_sec5 {
  padding-top: 6.25em;
  overflow: hidden;
  .eventnight_sec5Slider_wrapper {
    position: relative;
    .sec5_slide_img_wrapper {
      width: 100%;
      max-width: 100%;
      .sec5_slide_img {
        width: 100%;
        max-width: 100%;
      }
    }
    .sec5_arrow_wrapper {
      position: absolute;
      left: 2.9375em;
      right: 2.9375em;
      bottom: 0;
      top: calc(0% - 3.875em);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .sec5_prev,
    .sec5_next {
      width: 100%;
      max-width: 1.375em;
      height: 2.6875em;
      object-fit: contain;
      position: relative;
      z-index: 2;
      cursor: pointer;
    }
    .swiper-pagination-bullet {
      width: 0.75em;
      height: 0.75em;
      // margin: 0.21875em 1em;
      background: var(--swiper-pagination-bullet-inactive-color, #ffffff);
      opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
    }
    .swiper-pagination-bullet-active {
      opacity: var(--swiper-pagination-bullet-opacity, 1);
    }
    .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-pagination-horizontal.swiper-pagination-bullets
      .swiper-pagination-bullet {
      margin: 0.21875em;
    }
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 8%;
    }
    .swiper-button-disabled {
      opacity: 1;
      pointer-events: inherit;
    }
    .sec5_slider_text_wrapper {
      margin-left: 2.5em;
    }
    .sec5_slider_text {
      @include fontStyle(0.875em, 1.4em, normal, 500);
      text-transform: uppercase;
      padding-top: 1.072em;
      width: 100%;
      max-width: 59.9233em;
    }
    .sec5_slider_artist {
      font-size: 0.625em;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.09px;
      padding-top: 1.5em;
    }
  }
}

.eventnight_sec6 {
  padding-top: 6.25em;
  overflow: hidden;
  .sec6_row {
    margin: 0 auto;
    width: 100%;
    max-width: 34.6875em;
    .sec6_col {
      margin-bottom: 6.6875em;
      &:last-child {
        margin-bottom: 0;
      }
      .sec6Swiper {
        .swiper-horizontal > .swiper-pagination-bullets,
        .swiper-pagination-bullets.swiper-pagination-horizontal,
        .swiper-pagination-custom,
        .swiper-pagination-fraction {
          bottom: initial;
          top: 40.5em;
        }

        .swiper-pagination-bullet {
          background-color: #ffffff66;
          opacity: 1;
          width: 1.5em;
          height: 2px;
          border-radius: 0;
          margin: 0 0.25em;
          position: relative;
          &::before {
            content: "";
            background: rgba(255, 255, 255, 0.4);
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
          }
        }

        .swiper-pagination-bullet-active {
          background-color: #ffffff66;
          color: #ffffff;
          &::before {
            background-color: #ffffff;
            -webkit-animation: slide-progress 3.5s cubic-bezier(0.3, 0, 0.3, 1)
              forwards;
            animation: slide-progress 3.5s cubic-bezier(0.3, 0, 0.3, 1) forwards;
          }
        }

        .swiper-paused.swiper-pagination-bullet-active {
          &::before {
            -webkit-animation-play-state: paused;
            animation-play-state: paused;
          }
        }
      }
      .img_wrapper {
        width: 100%;
        max-width: 100%;

        .sec6_img {
          width: 100%;
          max-width: 100%;
        }
        .desc {
          @include fontStyle(0.875em, 1.4em, normal, 500);
          text-transform: uppercase;
          padding: 1.071em 0 0;
        }
        .artist {
          @include fontStyle(0.688em, 1.545em, normal, 300);
          text-transform: uppercase;
          padding-top: 0.909em;
        }
      }
    }
  }
}

.eventnight_sec7 {
  padding-top: 6.25em;
  overflow: hidden;
  .eventnight_sec7Slider_wrapper {
    position: relative;
    .sec7_slide_img_wrapper {
      width: 100%;
      max-width: 100%;
      .sec7_slide_img {
        width: 100%;
        max-width: 100%;
      }
    }
    .sec7_arrow_wrapper {
      position: absolute;
      left: 2.9375em;
      right: 2.9375em;
      bottom: 0;
      top: calc(0% - 3.875em);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .sec7_prev,
    .sec7_next {
      width: 100%;
      max-width: 1.375em;
      height: 2.6875em;
      object-fit: contain;
      position: relative;
      z-index: 2;
      cursor: pointer;
    }
    .swiper-pagination-bullet {
      width: 0.75em;
      height: 0.75em;
      // margin: 0.21875em 1em;
      background: var(--swiper-pagination-bullet-inactive-color, #ffffff);
      opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
    }
    .swiper-pagination-bullet-active {
      opacity: var(--swiper-pagination-bullet-opacity, 1);
    }
    .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-pagination-horizontal.swiper-pagination-bullets
      .swiper-pagination-bullet {
      margin: 0.21875em;
    }
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 8%;
    }
    .swiper-button-disabled {
      opacity: 1;
      pointer-events: inherit;
    }
    .sec7_slider_text_wrapper {
      margin-left: 2.5em;
    }
    .sec7_slider_text {
      @include fontStyle(0.875em, 1.4em, normal, 500);
      text-transform: uppercase;
      padding-top: 1.072em;
      width: 100%;
      max-width: 59.9233em;
    }
    .sec7_slider_artist {
      font-size: 0.625em;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.09px;
      padding-top: 1.5em;
    }
  }
}

.eventnight_sec9 {
  padding: 6.25em 0 12.5em;
  overflow: hidden;
  border-bottom: 1px solid #bcbcbc;
  .sec9_row {
    margin: 0 auto;
    width: 100%;
    max-width: 34.6875em;
    .sec9_col {
      margin-bottom: 6.6875em;
      &:last-child {
        margin-bottom: 0;
      }
      .sec9Swiper {
        .swiper-horizontal > .swiper-pagination-bullets,
        .swiper-pagination-bullets.swiper-pagination-horizontal,
        .swiper-pagination-custom,
        .swiper-pagination-fraction {
          bottom: initial;
          top: 40.5em;
        }

        .swiper-pagination-bullet {
          background-color: #ffffff66;
          opacity: 1;
          width: 1.5em;
          height: 2px;
          border-radius: 0;
          margin: 0 0.25em;
          position: relative;
          &::before {
            content: "";
            background: rgba(255, 255, 255, 0.4);
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
          }
        }

        .swiper-pagination-bullet-active {
          background-color: #ffffff66;
          color: #ffffff;
          &::before {
            background-color: #ffffff;
            -webkit-animation: slide-progress 3.5s cubic-bezier(0.3, 0, 0.3, 1)
              forwards;
            animation: slide-progress 3.5s cubic-bezier(0.3, 0, 0.3, 1) forwards;
          }
        }

        .swiper-paused.swiper-pagination-bullet-active {
          &::before {
            -webkit-animation-play-state: paused;
            animation-play-state: paused;
          }
        }
      }
      .img_wrapper {
        width: 100%;
        max-width: 100%;

        .sec9_img {
          width: 100%;
          max-width: 100%;
        }
        .desc {
          @include fontStyle(0.875em, 1.4em, normal, 500);
          text-transform: uppercase;
          padding: 1.071em 0 0;
        }
        .artist {
          @include fontStyle(0.688em, 1.545em, normal, 300);
          text-transform: uppercase;
          padding-top: 0.909em;
        }
      }
    }
  }
}

.eventnight_sec11 {
  padding: 13.875em 0 13.875em;
  overflow: hidden;
  max-width: 45em;
  margin: 0 auto;

  .desc {
    @include fontStyle(1.125em, 1.4, 0, 300);
    text-align: center;
  }
  .exhibition_concept_wrapper {
    width: 100%;
    max-width: 36em;
    margin: 0 auto;
    text-align: center;
    padding: 3.125em 0 0;
    .concept_text {
      @include fontStyle(0.875em, 1.4, normal, 500);
      text-align: center;
      margin: 0 0 1.5em;
      text-transform: uppercase;
      &:last-child {
        margin: 0;
      }
    }
  }
}

.eventnight_sec10 {
  .fslightbox-fade-in-strong {
    animation: none;
  }
}

.line_section {
  position: relative;
  .white_line {
    background-color: #ffffff;
    height: 2px;
    width: 100%;
    max-width: 100%;
  }
}

@-webkit-keyframes slide-progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes slide-progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

// @media (max-width: 992px) {
//   .eventnight_sec1 {
//     .exhibition_banner_image_wrapper {
//       height: 75vh;
//       .exhibition_banner_img {
//         height: 75vh;
//       }
//     }
//   }
// }

@media (max-width: 767px) {
  .eventnight_sec1 {
    .exhibition_banner_image_wrapper {
      // height: 33.875rem;
      height: 100vh;
      .exhibition_banner_img {
        width: 100%;
        max-width: 100%;
        // height: 33.875rem;
        height: 100vh;
      }
      .exhibition_banner_content {
        .exhibition_head {
          font-size: 3em;
          line-height: 1.2em;
          font-weight: 200;
          letter-spacing: 0.04px;
          //   margin-bottom: 0.5em;
        }
      }
    }
  }

  .eventnight_sec2 {
    padding: 15em 4.9em 14.4em;
    .heading {
      font-size: 1.5em;
      font-weight: 300;
    }
    .desc {
      font-size: 1.5em;
      line-height: 1.35;
      text-align: center;
      font-weight: 300;
    }
  }

  .eventnight_sec3 {
    .exhibiton_img_container {
      .exhibiton_img {
        height: 100%;
      }
      .play_button {
        max-width: 3.2em;
      }
    }
    .img_desc {
      font-size: 1em;
      line-height: 1.35;
      letter-spacing: 0.04px;
      max-width: 100%;
      margin-left: 0;
      padding: 0.8em 3.3em 0 1.6em;
    }
  }

  .eventnight_sec4 {
    padding-top: 8em;
    .sec4_row {
      max-width: 26.3em;
      .sec4_col {
        margin-bottom: 4em;
        .sec4Swiper {
          .swiper-horizontal > .swiper-pagination-bullets,
          .swiper-pagination-bullets.swiper-pagination-horizontal,
          .swiper-pagination-custom,
          .swiper-pagination-fraction {
            top: 30.5em;
          }
        }
        .img_wrapper {
          .desc {
            font-size: 1em;
            line-height: 1.35;
            letter-spacing: 0.04px;
            padding: 0.8em 0 0;
          }
          .artist {
            font-size: 0.7em;
            line-height: normal;
            letter-spacing: 0.35px;
            padding: 0.8571428571428571em 0 0;
          }
        }
      }
    }
  }

  .eventnight_sec5 {
    padding-top: 8em;
    .eventnight_sec5Slider_wrapper {
      .sec5_slide_img_wrapper {
        .sec5_slide_img {
          height: 100%;
        }
      }
      .sec5_slider_text_wrapper {
        margin-left: 0;
        padding: 0 3.3em 0 1.6em;
      }
      .sec5_slider_text {
        font-size: 1em;
        line-height: 1.35;
        letter-spacing: 0.04px;
        margin-left: 0;
        padding: 0.8em 0 0;
      }
      .sec5_arrow_wrapper {
        left: 1.834em;
        right: 1.834em;
        top: calc(0% - 5.2em);
      }
      .sec5_prev,
      .sec5_next {
        max-width: 0.8em;
        height: 1.5em;
      }
      .swiper-horizontal > .swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal,
      .swiper-pagination-custom,
      .swiper-pagination-fraction {
        bottom: 20%;
      }
      .swiper-pagination-bullet {
        width: 0.65em;
        height: 0.65em;
      }
    }
  }

  .eventnight_sec6 {
    padding-top: 8em;
    .sec6_row {
      max-width: 26.3em;
      .sec6_col {
        margin-bottom: 4em;
        .sec6Swiper {
          .swiper-horizontal > .swiper-pagination-bullets,
          .swiper-pagination-bullets.swiper-pagination-horizontal,
          .swiper-pagination-custom,
          .swiper-pagination-fraction {
            top: 30.5em;
          }
        }
        .img_wrapper {
          .desc {
            font-size: 1em;
            line-height: 1.35;
            letter-spacing: 0.04px;
            padding: 0.8em 0 0;
          }
          .artist {
            font-size: 0.7em;
            line-height: normal;
            letter-spacing: 0.35px;
            padding: 0.8571428571428571em 0 0;
          }
        }
      }
    }
  }

  .eventnight_sec7 {
    padding-top: 8em;
    .eventnight_sec7Slider_wrapper {
      .sec7_slide_img_wrapper {
        .sec7_slide_img {
          height: 100%;
        }
      }
      .sec7_slider_text_wrapper {
        margin-left: 0;
        padding: 0 3.3em 0 1.6em;
      }
      .sec7_slider_text {
        font-size: 1em;
        line-height: 1.35;
        letter-spacing: 0.04px;
        margin-left: 0;
        padding: 0.8em 0 0;
      }
      .sec7_slider_artist {
        font-size: 0.7em;
        line-height: normal;
        letter-spacing: 0.35px;
        padding: 0.8571428571em 0 0;
      }
      .sec7_arrow_wrapper {
        left: 1.834em;
        right: 1.834em;
        top: calc(0% - 5.2em);
      }
      .sec7_prev,
      .sec7_next {
        max-width: 0.8em;
        height: 1.5em;
      }
      .swiper-horizontal > .swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal,
      .swiper-pagination-custom,
      .swiper-pagination-fraction {
        bottom: 20%;
      }
      .swiper-pagination-bullet {
        width: 0.65em;
        height: 0.65em;
      }
    }
  }

  .eventnight_sec9 {
    padding: 8em 0 8em;
    .sec9_row {
      max-width: 26.3em;
      .sec9_col {
        margin-bottom: 4em;
        .sec9Swiper {
          .swiper-horizontal > .swiper-pagination-bullets,
          .swiper-pagination-bullets.swiper-pagination-horizontal,
          .swiper-pagination-custom,
          .swiper-pagination-fraction {
            top: 30.5em;
          }
        }
        .img_wrapper {
          .desc {
            font-size: 1em;
            line-height: 1.35;
            letter-spacing: 0.04px;
            padding: 0.8em 0 0;
          }
          .artist {
            font-size: 0.7em;
            line-height: normal;
            letter-spacing: 0.35px;
            padding: 0.8571428571428571em 0 0;
          }
        }
      }
    }
  }

  .eventnight_sec11 {
    padding: 8em 1.6em 8em;
    .desc {
      font-size: 1.6em;
      line-height: 1.35;
      max-width: 18.4375em;
      margin: 0 auto;
      text-transform: inherit;
      font-weight: 300;
    }
    .exhibition_concept_wrapper {
      padding-top: 5.6em;
      max-width: 27.1em;
      text-transform: uppercase;
      .concept_text {
        font-size: 1em;
        font-style: normal;
        font-weight: 400;
        line-height: 1.35;
        letter-spacing: 0.04px;
        margin-bottom: 2em;
      }
    }
  }

  .line_section {
    .white_line {
      height: 1px;
    }
  }
}
