@import "../../common.scss";

.press {
  .my_container {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 0;
  }
}

.press_sec1 {
  padding: 18.125em 0;
  text-align: center;
  .section_subheading {
    max-width: 18.1em;
    margin: 0 auto;
  }
}

.press_sec2 {
  padding: 9.063em 0 0.375em;
  .press_row {
    .press_col {
      padding: 0 2.5em 8.125em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .col_img_wrapper {
        padding: 0 0 2.438em;
        min-height: 8.125em;
        .col_img {
          width: 100%;
          max-width: 13.125em;
          // max-height: 8.125em;
          object-fit: contain;
        }
      }
      .content_box {
        color: $color-000000;
        margin: 0 0 1.875em;
        text-align: left;
        .content_date {
          @include fontStyle(0.875em, 1.4em, normal, 500);
          padding: 0 0 1.071em;
          text-transform: uppercase;
        }
        .press_content {
          @include fontStyle(1.125em, 1.4em, normal, 300);
          padding: 0 0 1.111em;
          height: 100%;
        }
        .press_author {
          @include fontStyle(0.875em, 1.4em, normal, 500);
        }
      }
      .press_btn_wrapper {
        max-width: 150px;
        .press_button {
          min-width: 150px;
          &:hover {
            color: $color-FFFFFF;
          }
        }
      }
    }
  }

  .loader_text {
    padding: 0 0 8.125em;
  }
}

@media (max-width: 1100px) {
  .press {
    .my_container {
      padding: 0 3.1em;
    }
  }
}

@media (max-width: 992px) {
  .press_sec2 {
    .press_row {
      .press_col {
        .content_box {
          .content_date {
            font-size: 1.4em;
          }
          .press_content {
            font-size: 1.8em;
          }
          .press_author {
            font-size: 1em;
            font-size: 1.4em;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .press {
    .my_container {
      padding: 0 7em 0 6.7em;
    }
  }
  .press_sec1 {
    padding: 27.9em 0 28.6em;
  }

  .press_sec2 {
    padding: 9em 0 0.8em;
    .press_row {
      .press_col {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 0 0 7em;
        .col_img_wrapper {
          padding: 0 0 1.5em;
          min-height: auto;
          .col_img {
            max-width: 100%;
            // max-height: auto;
            max-height: 100%;
            height: auto;
          }
        }
        .content_box {
          max-width: 23.8em;
          margin: 0 0 3em;
          .press_content {
            font-size: 1.5em;
            padding: 0 0 0.667em;
          }
        }
      }
    }
  }
}
