@import "../../common.scss";

.voting_btn {
  width: 100%;
  max-width: fit-content;
  margin: 0 0 0 1.563em;
  border: none;
  outline: none;

  .vote_cta {
    max-width: fit-content;
    @include textStyle(0.813em, 1.205em, 0.33%);
    border-bottom: 1px solid $color-000000;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      font-weight: bold;
    }
  }
}

.modal-lg {
  width: 100%;
  max-width: fit-content;

  .emailid_modal {
    background-color: $color-FFFFFF;

    .modal-header {
      border: 0;
      padding: 0;

      .btn-close {
        background: transparent url("../../images/voting/close-icon.svg") center no-repeat;
        padding: 0;
        margin: 0;
        border: none;
        cursor: pointer;
        background-size: 7.5em;
        width: 5em;
        height: 5em;
      }
    }

    .modal-body {
      max-width: fit-content;
      margin: 3.875em 18.688em 8.875em;
      padding: 0;

      .email_heading {
        max-width: fit-content;
        @include textStyle(3.125em, 1em, -5%);
        color: $color-000000;
        text-align: center;
        font-weight: 200;
        text-transform: uppercase;
        margin: 0 auto;
      }

      .desc {
        @include textStyle(0.875em, 1.4em, 0);
        color: $color-000000;
        text-align: center;
        font-weight: 500;
        max-width: 25.714em;
        margin: 1.071em auto 2.143em;
        text-transform: uppercase;
      }
    }

    .form_wrapper {
      width: 100%;
      max-width: 26.375em;
      margin: 0 auto;

      .form_input {
        border-radius: 5px;
        border: 1px solid $color-000000;
        color: $color-000000;
        font-family: "GT America Mono";
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.33px;
        padding: 1.308em 6em 1.308em 2.308em;
        -webkit-text-fill-color: $color-000000 !important;

        &::placeholder {
          color: $color-000000;
        }
      }
    }

    // .form_field_container {
    //   width: 100%;
    //   max-width: 41.875em;
    //   margin: 1.625em auto 0;

    //   .error_message {
    //     color: $color-000000;
    //     padding-left: 1.25rem;
    //   }
    // }

    .consent_checkbox_wrapper {
      text-align: left;
      margin: 1.25em 1.25em 1.25em 0;

      .error_message {
        margin-left: 3em;
        padding-left: 0;
        margin-top: 0.5em;
        text-align: left;
      }

      .consent_input {
        display: none;
      }

      .consent_label {
        @include textStyle(0.938em, 1.333em, 0);
        position: relative;
        cursor: pointer;
        text-align: left;
        padding-left: 1.875em;
        display: block;
        color: $color-000000;
        font-weight: 300;
        margin-bottom: 0;
      }

      .consent_label::before {
        content: "";
        background-color: white;
        border-radius: 50%;
        border: none;
        width: 100%;
        max-width: 1.2em;
        height: 1.2em;
        border: 1px solid $color-000000;
        cursor: pointer;
        position: absolute;
        top: 0.25em;
        left: 0;
        // border: 1.8px solid #ff260b;
      }

      .consent_input:checked+.consent_label::after {
        content: "";
        display: block;
        position: absolute;
        // top: 0.5em;
        // left: 0.525em;
        // width: 0.3em;
        // height: 0.7em;
        // border: solid #000;
        // border-width: 0 2px 2px 0;
        // transform: rotate(45deg);
        // top: 0.507em;
        // left: 0.2624999881em;
        top: 0.45em;
        left: 0.19em;
        background-color: $color-000000;
        width: 0.8em;
        height: 0.8em;
        border-radius: 50px;
      }
    }

    .form_submit_btn {
      text-align: left;
      cursor: pointer;

      .form_input {
        &:hover {
          color: $color-000000;
        }
      }
    }

    .btn_wrapper {
      .project-button {
        width: 100%;
        max-width: 100%;
        background-color: transparent;
        justify-content: flex-start;
        height: 100%;
        min-height: 52.01px;
        border: 1px solid $color-000000;
        padding: 1.0878em 6em 1.0878em 1.54em;

        &:hover {
          background-color: $color-FFFFFF;
          color: $color-FFFFFF;
          font-weight: bold;
        }

        &::before {
          background-color: $color-000000;
        }

        &::after {
          background-color: $color-000000;
        }
      }
    }
  }

  .otp_modal {
    background-color: $color-FC9500;

    .modal-header {
      border: 0;
      padding: 0;

      .btn-close {
        background: transparent url("https://flaticons.net/icon.php?slug_category=mobile-application&slug_icon=close") center/2em auto no-repeat;
        opacity: 0.8;
        padding: 0.5rem 0.5rem;
        margin: 1.5rem 1.5rem 1.5rem auto;
        border: none;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }

    .modal-body {
      margin: 2.563em 11.75em 6em;
      padding: 0;

      .desc {
        text-align: center;
        @include textStyle(1.25em, normal, $color-FFFFFF);
        margin: 0.563em 0 3.75rem;
      }
    }
  }

  .otp_input_wrapper {
    justify-content: center;
    margin-top: 3.313em;

    div {
      &:first-child {
        .otp_input {
          margin-left: 0;
        }
      }
    }
  }

  .otp_input {
    background-color: transparent;
    border: 1.5px solid $color-FFFFFF;
    font-size: 1.875em;
    color: $color-FFFFFF !important;
    width: 2.833em !important;
    height: 2.5em;
    display: inline-block;
    border-radius: 0.333em;
    margin-left: 1.833em;
  }

  .otp_focused {
    border: 2.5px solid $color-FFFFFF;
  }

  .resend_otp_text {
    text-align: center;
    margin-top: 1.1em;
    cursor: pointer;
  }

  .otp_sent_text {
    margin-top: 0.7em;
    text-align: center;
  }

  .otp_verify_btn {
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
  }
}

@media screen and (max-width: 992px) {
  .voting_btn {
    margin: 1em 0 0 1.563em;
  }

  .modal-lg {
    max-width: 64em;

    .emailid_modal {
      .modal-body {
        margin: 2.563em 4.75em 6em;
        max-width: 100%;

        .desc {
          line-height: 1.5em;
          font-size: 1.3em;
        }
      }

      .consent_checkbox_wrapper {
        .error_message {
          margin-left: 2.3em;
        }

        .consent_label {
          font-size: 1.3em;
          padding-left: 2.3em;
          margin-left: 0;
        }
      }
    }

    .otp_modal {
      .modal-body {
        margin: 2.563em 4.75em 6em;

        .desc {
          line-height: 1.5em;
          font-size: 1.3em;
        }
      }
    }

    .otp_input {
      margin-left: 0.7em;
    }
  }
}


@media (max-width: 600px) {

  .voting_btn {
    margin: 1em 0 0;
  }

  .modal-dialog {
    margin: 0;
    padding: 0.5em;
  }

  .modal-lg {
    max-width: 100%;

    .emailid_modal {
      .modal-body {
        margin: 1.5em 2.5em 6em;

        .email_heading {
          font-size: 2.5em;
        }

        .desc {
          font-size: 1.3em;
          max-width: 90%;
        }

        .consent_checkbox_wrapper {
          .consent_label {
            font-size: 1.3em;
            line-height: 1.55;
          }

          .consent_input:checked+.consent_label::after {
            width: 0.75em;
            height: 0.75em;
            top: 0.465em;
            left: 0.23em;
          }
        }
      }
      .form_wrapper {
        max-width: 100%;
      }

      .btn_wrapper {
        .project-button {
          min-height: 47.88px;
        }
      }
    }

    .otp_modal {
      .modal-body {
        margin: 2.563em 1.5em 6em;
      }
    }

    .otp_input {
      margin-left: 0.7em;
    }
  }
}

@media (max-width: 340px) {
  .modal-lg {
    .otp_input {
      width: 2em !important;
      height: 2em;
    }
  }
}