@import "../../common.scss";

.aboutus {
  .my_container {
    width: 100%;
    max-width: 1480px;
    padding: 0 77.5px;
    margin: 0 auto;
  }
}
@media (max-width: 1194px) {
  .aboutus {
    .my_container {
      max-width: 1031px;
      padding: 0 15px;
    }
  }
}

@media (max-width: 600px) {
  .aboutus {
    .my_container {
      padding: 0 16px 0 20px;
    }
  }
}

.about_sec2 {
  padding: 13.125em 0 13.125em;
  .aboutus_content {
    width: 100%;
    max-width: 38.75em;
    margin: 0 auto;
    .aboutus_text1 {
      color: $color-000000;
      font-size: 1.125em;
      font-style: normal;
      font-weight: 300;
      line-height: 1.4;
      text-align: center;
      margin-bottom: 1.5em;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.about_sec3 {
  background-color: #862547;
  padding: 6.5625em 0 6.625em;
  .content_wrapper {
    width: 100%;
    max-width: 38.75em;
    margin: 0 auto;
    .title {
      color: #ffffff;
      text-align: center;
      font-size: 1.875em;
      font-weight: 200;
      line-height: 1.2;
      text-transform: uppercase;
    }
    .desc {
      color: #ffffff;
      font-size: 1.125em;
      font-weight: 300;
      line-height: 1.4;
      text-align: center;
      margin-top: 1.1112em;
    }
  }
}

.about_sec4 {
  .img_text_wrap {
    padding: 0;
    .shana_img {
      width: 100%;
      height: 100%;
      // max-width: 45.063rem;
      object-fit: cover;
    }
  }
  .niv_wrapper {
    max-width: 32.5em;
    margin: 0 6.375em 0 6.125em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    .content_box {
      padding: 8.875em 0 10.5em;
      .niv_img {
        width: 100%;
        max-width: 26.4375em;
        margin: 0 0 1.563em;
      }
      .section_subdesc {
        color: $color-000000;
        font-size: 0.9375em;
        font-weight: 300;
        line-height: 1.334;
      }
      .text_container {
        margin: 1.5625em 0 0;
        .name {
          @include fontStyle(0.875em, 1.4em, normal, 500);
          text-transform: uppercase;
        }
        .designation {
          @include fontStyle(0.688em, 1.545em, 0.44px, 300);
          text-transform: uppercase;
          margin: 5px 0 0;
        }
      }
    }
  }
}

.about_sec5 {
  padding: 0;
  border-bottom: 1px solid #bcbcbc;
  .img_text_wrap {
    padding: 0;
    .anchit_img {
      width: 100%;
      max-width: 100%;
      // max-width: 45.063em;
      object-fit: cover;
      height: 100%;
    }
  }
  .nykaa_wrapper {
    max-width: 32.5em;
    margin: 0 6.1875em 0 6.3125em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    .content_box {
      padding: 5.563em 0 10.25em;
      .nykaa_img {
        width: 100%;
        max-width: 10.625em;
        margin: 0 0 1.563em;
      }
      .section_desc {
        color: $color-000000;
        font-size: 0.875em;
        font-weight: 500;
        line-height: 1.4;
        text-transform: uppercase;
        margin-bottom: 1.42857em;
      }
      .section_subdesc {
        color: $color-000000;
        font-size: 0.9375em;
        font-weight: 300;
        line-height: 1.334;
      }
      .text_container {
        color: $color-000000;
        margin: 1.563em 0 0;
        .name {
          @include fontStyle(0.875em, 1.4em, normal, 500);
          text-transform: uppercase;
        }
        .designation {
          @include fontStyle(0.688em, 1.545em, 0.44px, 300);
          text-transform: uppercase;
          margin: 5px 0 0;
        }
      }
    }
  }
}

.about_sec6 {
  padding: 14.75em 0;
  .my_container {
    max-width: 975px;
    padding: 0;
  }
  .about_brand_wrap {
    .idex_img {
      width: 100%;
      max-width: 16.8125em;
    }
    .lattice_img {
      width: 100%;
      max-width: 26.1832em;
    }
    .section_subdesc {
      color: $color-000000;
      font-size: 0.9375em;
      font-weight: 300;
      line-height: 1.334;
      margin: 2.6667em 0 1.3334em;
    }
    .brand_site {
      color: $color-000000;
      font-size: 0.9375em;
      font-weight: 300;
      line-height: 1.334;
    }
  }
  .box1 {
    max-width: 28.125em;
    margin: 0 auto;
    text-align: center;
  }
  .box2 {
    padding-left: 4.21875em;
  }
}

@media (min-width: 2000px) {
  .about_sec6 {
    .my_container {
      max-width: 1200px;
    }
  }
}

@media (min-width: 1800px) {
  .about_sec4,
  .about_sec5 {
    .custom_row_style {
      align-items: center;
    }
    .img_text_wrap {
      .shana_img,
      .anchit_img {
        height: auto;
      }
    }
  }
}

@media (max-width: 1300px) {
  .about_sec4,
  .about_sec5,
  .about_sec6 {
    .my_container {
      padding: 0 36px 0 38px;
    }
  }
}

@media (max-width: 1194px) {
  .about_sec4 {
    .niv_wrapper {
      max-width: 100%;
      margin: 0 3.375em 0 3.125em;
    }
  }

  .about_sec5 {
    .nykaa_wrapper {
      max-width: 100%;
      margin: 0 3.1875em 0 3.3125em;
    }
  }
}

@media (max-width: 992px) {
  .about_sec2 {
    .aboutus_content {
      max-width: 80%;
      // .aboutus_text1 {
      //   font-size: 1.5em;
      //   line-height: 1.8rem;
      // }
    }
  }

  // .about_sec3 {
  //   .content_wrapper {
  //     .desc {
  //       font-size: 1.25em;
  //       line-height: 1.4;
  //     }
  //   }
  // }
}

@media (max-width: 991px) {
  .about_sec2 {
    padding: 4.688em 0 5.063em;
    .aboutus_content {
      .aboutus_text1 {
        font-size: 1.5em;
      }
    }
  }

  .about_sec3 {
    .content_wrapper {
      .title {
        font-size: 2.5em;
      }
      .desc {
        font-size: 1.5em;
      }
    }
  }

  .about_sec4 {
    .niv_wrapper {
      margin: 0 36px 0 38px;
      .content_box {
        padding: 4em 0 4.7em;
        .section_subdesc {
          font-size: 1.25em;
        }
        .text_container {
          .name,
          .designation {
            font-size: 1.4em;
          }
        }
      }
    }
    .img_text_wrap {
      .shana_img {
        height: inherit;
      }
    }
  }

  .about_sec5 {
    .img_text_wrap {
      .anchit_img {
        height: inherit;
      }
    }
    .nykaa_wrapper {
      margin: 0 36px 0 38px;
      .content_box {
        padding: 4em 0 6.6em;
        .section_subdesc {
          font-size: 1.25em;
        }
        .text_container {
          .name,
          .designation {
            font-size: 1.4em;
          }
        }
      }
    }
  }

  .about_sec6 {
    padding: 3.5em 0 6.851em;
    .about_brand_wrap {
      .section_subdesc {
        font-size: 1.25em;
      }
      .brand_site {
        font-size: 1.25em;
      }
    }
  }
}

@media (max-width: 600px) {
  .about_sec2 {
    padding: 15em 0;
    .aboutus_content {
      padding: 0 3.1em 0 2.6em;
      max-width: 100%;
      .aboutus_text1 {
        font-size: 1.5em;
        font-weight: 300;
        line-height: 1.334;
        margin-bottom: 1.5em;
      }
    }
  }

  .about_sec3 {
    padding: 5.4em 5.1em 6.2em 4.6em;
    .content_wrapper {
      max-width: 100%;
      .title {
        font-size: 2.5em;
        font-weight: 200;
        line-height: normal;
      }
      .desc {
        font-size: 1.5em;
        font-weight: 300;
        line-height: 1.334;
        margin-top: 1.334em;
      }
    }
  }

  .about_sec4 {
    .my_container {
      padding: 0 26px 0 35px;
    }
    .niv_wrapper {
      margin: 0 4.6em 0 4.7em;
      .content_box {
        .niv_img {
          max-width: 29.7em;
          margin-bottom: 2.2em;
        }
        .section_subdesc {
          font-size: 1.5em;
          font-weight: 300;
          line-height: 1.334;
        }
        .text_container {
          margin: 1.5em 0 0;
        }
      }
    }
  }

  .about_sec5 {
    .nykaa_wrapper {
      margin: 0 4.6em 0 4.7em;
      .content_box {
        padding: 4em 0 7.5em;
        .nykaa_img {
          max-width: 13.3em;
          margin: 0;
        }
        .section_desc {
          font-size: 1.4em;
          font-weight: 500;
          line-height: 1.4;
          margin: 0 0 1.0715em;
        }
        .section_subdesc {
          font-size: 1.5em;
          font-weight: 300;
          line-height: 1.334;
          margin-top: 1.333em;
        }
        .text_container {
          margin: 1.5em 0 0;
        }
      }
    }
  }
  .about_sec6 {
    padding: 8em 0 15em;
    .my_container {
      padding: 0 4.6em 0 4.7em;
    }
    .about_brand_wrap {
      .section_subdesc {
        font-size: 1.5em;
        font-weight: 300;
        line-height: 1.334;
        margin: 2em 0 1.25em;
      }
      .brand_site {
        font-size: 1.5em;
        font-weight: 300;
        line-height: 1.334;
      }
      .idex_img {
        max-width: 16.075em;
      }
      .lattice_img {
        max-width: 25.035em;
      }
    }
    .box2 {
      padding-left: 1.5em;
    }
  }
}

@media (max-width: 576px) {
  .about_sec6 {
    .box1 {
      text-align: left;
    }
    .box2 {
      padding: 0;
    }
  }
}
