@import "../../common.scss";

.app_sec1 {
  padding: 19.188em 0 16.938em;
}

.application_box {
  .app_cat_box {
    max-width: 34.438em;
    padding: 2.5em 0 0;
    &:first-child {
      padding: 0;
    }
    .cat_head {
      @include fontStyle(0.875em, 1.4em, normal, 500);
      text-transform: uppercase;
      padding: 0 0 0.714em;
    }
    &.app_sub_title {
      .cat_head {
        padding: 0 0 0.215em;
      }
    }
  }

  .app_notice {
    max-width: 34.438em;
    padding: 3.125em 0 0;
    margin: 0;
    &:first-child {
      padding: 0;
    }
  }
}

.app_sec2 {
  background: $color-BBE5FC;
  color: $color-000000;
  padding: 5.313em 9.375em 4.438em 2.625em;
  .text_container {
    max-width: 31.813em;
    .app_desc {
      @include fontStyle(0.938em, 1.4em, normal, 300);
      text-transform: uppercase;
      // padding: 2em 0 0 1.25em;
      padding: 2em 0 0;
      max-width: 29.333em;
      .ol_list_item {
        list-style-type: decimal;
      }
    }
  }
}

.app_sec3 {
  background: $color-862547;
  color: $color-FFFFFF;
  padding: 5.313em 10.063em 4.438em 2.625em;
  .text_container {
    max-width: 32.563em;
    padding: 0 0 5em;
    .app_desc {
      @include fontStyle(0.938em, 1.4em, normal, 300);
      text-transform: uppercase;
      padding: 0.667em 0 0;
    }
  }
  .app_notice {
    padding: 3.125em 0 0 2.5em;
    .ol_list_item {
      list-style-type: decimal;
      padding: 0 0 2.222em;
      &:last-child {
        padding: 0;
      }
    }
    .app_list {
      .list_item {
        list-style-type: disc;
      }
    }
  }
}

.app_sec4 {
  padding: 12em 0;
  .section_desc {
    max-width: 40%;
    margin: 0.833em auto 0;
  }
  .cta_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.875em 0 0;
  }
}

@media (max-width: 992px) {
  .application_box {
    .app_cat_box {
      max-width: 100%;
      .cat_head {
        font-size: 1.4em;
      }
    }
    .app_notice {
      max-width: 100%;
    }
  }
  .app_sec2,
  .app_sec3 {
    padding: 5.313em 4.6em 4.438em;
    .text_container {
      max-width: 85%;
      padding: 0 0 3em;
      .app_desc {
        font-size: 1.1em;
      }
    }
  }

  .app_sec4 {
    .section_desc {
      max-width: 75%;
      margin: 0.833em auto 0;
    }
  }
}

@media (max-width: 600px) {
  .app_sec1 {
    padding: 15em 1.5em;
    .section_subheading {
      max-width: 12.4em;
      margin: 0 auto;
      br {
        display: none;
      }
    }
  }

  .app_sec2 {
    padding: 4.8em 4.1em 5em 4.2em;
    .text_container {
      max-width: 100%;
      .app_desc {
        // padding: 1.364em 0 0 1.25em;
        padding: 1.364em 0 0;
        margin: 0;
      }
    }
  }

  .app_sec3 {
    padding: 4.5em 4.6em 5em 4.7em;
    .text_container {
      max-width: 100%;
      .app_desc {
        padding: 1.364em 0 0;
      }
    }
    .app_notice {
      padding: 5em 0 0 2em;
      &:first-child {
        padding: 0 0 0 2em;
      }
    }
  }

  .app_sec4 {
    padding: 11.2em 0 15em;
    max-width: 31em;
    margin: 0 auto;
    .section_desc {
      max-width: 100%;
      margin: 1.333em auto 0;
    }
  }
}
