@import "../../common.scss";

.awards_sec1 {
  position: relative;
  overflow: hidden;
  .awards_banner_image_wrapper {
    height: 100vh;
    width: 100%;
    max-width: 100%;
    .awards_banner_img {
      width: 100%;
      max-width: 100%;
      height: 100vh;
    }
    .awards_banner_content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;
      max-width: 100%;
      .awards_head {
        @include fontStyle(3.125em, 1em, -5%, 200);
        color: $color-FFFFFF;
        text-align: center;
        text-transform: uppercase;
        .break {
          display: block;
        }
      }
    }
  }
}

.awards_sec2 {
  padding: 15.125em 0;
  overflow: hidden;
  max-width: 47.5em;
  margin: 0 auto;
  .desc {
    @include fontStyle(1.125em, 1.4em, normal, 300);
    text-align: center;
    .desc_link {
      text-decoration: underline;
      color: inherit;
    }
  }
}

.awards_sec3 {
  overflow: hidden;
  .awards_img_container {
    position: relative;
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    .awards_img {
      width: 100%;
      max-width: 100%;
      // height: 56.1875em;
      // height: 100%;
      object-fit: cover;
    }
    .play_button {
      width: 100%;
      // max-width: 10.0625em;
      max-width: 5.03125em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .img_desc {
    @include fontStyle(0.875em, 1.4em, normal, 500);
    text-transform: uppercase;
    padding: 1.071em 0 0 3.143em;
    width: 100%;
    max-width: 57.143em;
  }
}

.awards_sec4 {
  padding-top: 10.4375em;
  overflow: hidden;
  .sec4_row {
    margin: 0 auto;
    justify-content: center;
    width: 100%;
    max-width: 88.4375em;
    .sec4_col {
      padding: 0;
      &:nth-child(2n + 1) {
        .img_wrapper {
          padding-right: 2.40625em;
          padding-left: 7.125em;
        }
      }
      &:nth-child(2n + 2) {
        .img_wrapper {
          padding-left: 2.40625em;
          padding-right: 7.125em;
        }
      }
      &:last-child {
        margin-top: 6.0625em;
        .img_wrapper {
          padding-left: 4.765625em;
          padding-right: 4.765625em;
        }
      }
    }
    .img_wrapper {
      width: 100%;
      max-width: 100%;
    }
    .sec4_img {
      width: 100%;
      max-width: 100%;
    }
    .desc {
      @include fontStyle(0.875em, 1.4em, normal, 500);
      text-transform: uppercase;
      padding: 1.071em 0 0;
    }
    .artist {
      @include fontStyle(0.688em, 1.545em, normal, 300);
      text-transform: uppercase;
      padding-top: 0.909em;
    }
  }
}

.awards_sec5 {
  padding-top: 6.6875em;
  overflow: hidden;
  .awards_sec5Slider_wrapper {
    position: relative;
    .sec5_slide_img_wrapper {
      width: 100%;
      max-width: 100%;
      // height: 56.9375em;
      .sec5_slide_img {
        width: 100%;
        max-width: 100%;
        // height: 56.75em;
        // height: 100%;
      }
    }
    .sec5_arrow_wrapper {
      position: absolute;
      left: 2.9375em;
      right: 2.9375em;
      bottom: 0;
      top: calc(0% - 3.875em);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .sec5_prev,
    .sec5_next {
      width: 100%;
      max-width: 1.375em;
      height: 2.6875em;
      object-fit: contain;
      position: relative;
      z-index: 2;
      cursor: pointer;
    }
    .swiper-pagination-bullet {
      width: 0.75em;
      height: 0.75em;
      background: var(--swiper-pagination-bullet-inactive-color, #ffffff);
      opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
    }
    .swiper-pagination-bullet-active {
      opacity: var(--swiper-pagination-bullet-opacity, 1);
    }
    .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-pagination-horizontal.swiper-pagination-bullets
      .swiper-pagination-bullet {
      margin: 0.21875em;
    }
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 8%;
    }
    .swiper-button-disabled {
      opacity: 1;
      pointer-events: inherit;
    }
    .sec5_slider_text_wrapper {
      margin-left: 2.75em;
    }
    .sec5_slider_text {
      @include fontStyle(0.875em, 1.4em, normal, 500);
      text-transform: uppercase;
      padding-top: 1.071em;
      width: 100%;
      max-width: 60.38461538461538em;
    }
    .sec5_slider_artist {
      font-size: 0.625em;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.09px;
      padding-top: 1.5em;
    }
  }
}

.awards_sec6 {
  padding-top: 7.1875em;
  overflow: hidden;
  .sec6_row {
    margin: 0 auto;
    justify-content: center;
    // row-gap: 4.3125em;
    width: 100%;
    // max-width: 74.1875em;
    max-width: 88.4375em;
    .sec6_col {
      padding: 0;
      &:nth-child(2n + 1) {
        .img_wrapper {
          padding-right: 2.40625em;
          padding-left: 7.125em;
        }
      }
      &:nth-child(2n + 2) {
        .img_wrapper {
          padding-left: 2.40625em;
          padding-right: 7.125em;
        }
      }
      &:last-child {
        // margin-top: 2.4375em;
        margin-top: 5.9375em;
        .img_wrapper {
          padding-left: 4.765625em;
          padding-right: 4.765625em;
        }
      }
    }
    .img_wrapper {
      width: 100%;
      max-width: 100%;
    }
    .sec6_img {
      width: 100%;
      max-width: 100%;
    }
    .desc {
      @include fontStyle(0.875em, 1.4em, normal, 500);
      text-transform: uppercase;
      padding: 1.071em 0 0;
    }
    .artist {
      @include fontStyle(0.688em, 1.545em, normal, 300);
      padding-top: 1.5em;
    }
  }
}

.awards_sec7 {
  padding-top: 8.375em;
  overflow: hidden;
  .awards_sec7Slider_wrapper {
    position: relative;
    .sec7_slide_img_wrapper {
      width: 100%;
      max-width: 100%;
      .sec7_slide_img {
        width: 100%;
        max-width: 100%;
        // height: 56.75em;
        // height: 100%;
      }
    }
    .sec7_arrow_wrapper {
      position: absolute;
      left: 2.9375em;
      right: 2.9375em;
      bottom: 0;
      top: calc(0% - 3.875em);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .sec7_prev,
    .sec7_next {
      width: 100%;
      max-width: 1.375em;
      height: 2.6875em;
      object-fit: contain;
      position: relative;
      z-index: 2;
      cursor: pointer;
    }
    .swiper-pagination-bullet {
      width: 0.75em;
      height: 0.75em;
      // margin: 0.21875em 1em;
      background: var(--swiper-pagination-bullet-inactive-color, #ffffff);
      opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
    }
    .swiper-pagination-bullet-active {
      opacity: var(--swiper-pagination-bullet-opacity, 1);
    }
    .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-pagination-horizontal.swiper-pagination-bullets
      .swiper-pagination-bullet {
      margin: 0.21875em;
    }
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 8%;
    }
    .swiper-button-disabled {
      opacity: 1;
      pointer-events: inherit;
    }
    .sec7_slider_text_wrapper {
      margin-left: 2.75em;
    }
    .sec7_slider_text {
      @include fontStyle(0.875em, 1.4em, normal, 500);
      text-transform: uppercase;
      padding-top: 1.071em;
      width: 100%;
      max-width: 60.38461538461538em;
    }
    .sec7_slider_artist {
      @include fontStyle(0.688em, 1.545em, normal, 300);
      padding-top: 1.5em;
    }
  }
}

.awards_sec9 {
  padding: 7.1875em 0 7.1875em;
  overflow: hidden;
  border-bottom: 1px solid $color-000000;
  .sec9_row {
    margin: 0 auto;
    justify-content: center;
    // row-gap: 4.3125em;
    width: 100%;
    // max-width: 74.1875em;
    max-width: 88.4375em;
    .sec9_col {
      padding: 0;
      &:nth-child(2n + 1) {
        .img_wrapper {
          padding-right: 2.40625em;
          padding-left: 7.125em;
        }
      }
      &:nth-child(2n + 2) {
        .img_wrapper {
          padding-left: 2.40625em;
          padding-right: 7.125em;
        }
      }
      // &:last-child {
      //   margin-top: 2.4375em;
      //   .img_wrapper {
      //     padding-left: 4.765625em;
      //     padding-right: 4.765625em;
      //   }
      // }
    }
    .img_wrapper {
      width: 100%;
      max-width: 100%;
    }
    .sec9_img {
      width: 100%;
      max-width: 100%;
    }
    .desc {
      @include fontStyle(0.875em, 1.4em, normal, 500);
      text-transform: uppercase;
      padding: 1.071em 0 0 0;
    }
    .artist {
      @include fontStyle(0.688em, 1.545em, normal, 300);
      text-transform: uppercase;
      padding-top: 0.9091em;
    }
  }
}

.awards_sec11 {
  padding: 16.625em 0;
  overflow: hidden;
  max-width: 47.5em;
  margin: 0 auto;
  .desc {
    width: 100%;
    max-width: 48.625em;
    margin: 0 auto;

    .desc_details {
      text-align: center;
      margin: 0 auto 0.833em;
      &:last-child {
        margin: 0;
      }
    }
    .head {
      @include fontStyle(1.875em, 1.2em, normal, 200);
      max-width: 19.667em;
      text-transform: uppercase;
    }
    .para {
      @include fontStyle(1.125em, 1.4em, normal, 300);
    }
    .desc_link {
      text-decoration: underline;
      color: inherit;
    }
  }
}

.awards_sec10 {
  .fslightbox-fade-in-strong {
    animation: none;
  }
}

.award_line_section {
  position: relative;
  .white_line {
    height: 2px;
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 992px) {
  .awards_sec1 {
    .awards_banner_image_wrapper {
      height: 75vh;
      .awards_banner_img {
        height: 75vh;
      }
    }
  }

  // .awards_sec4 {
  //   .sec4_row .desc {
  //     padding: 1em 2em 0 0;
  //   }
  // }

  // .awards_sec6 {
  //   .sec6_row .desc {
  //     padding: 1em 2em 0 0;
  //   }
  // }
}

@media (max-width: 767px) {
  .awards_sec1 {
    .awards_banner_image_wrapper {
      // height: 54.2rem;
      height: 33.875rem;
      .awards_banner_img {
        width: 100%;
        max-width: 100%;
        // height: 54.2rem;
        height: 33.875rem;
      }
      .awards_banner_content {
        .awards_head {
          font-size: 2.5em;
          line-height: 1.2em;
          .break {
            display: block;
          }
        }
      }
    }
  }

  .awards_sec2 {
    padding: 15em 4.9em;
    .desc {
      // font-size: 2em;
      // line-height: 1.35;
      font-size: 1.6em;
      line-height: 1.35;
      text-align: center;
    }
  }

  .awards_sec3 {
    .awards_img_container {
      .awards_img {
        height: 100%;
      }
      .play_button {
        max-width: 3.7em;
      }
    }
    .img_desc {
      font-size: 1em;
      line-height: 1.35em;
      letter-spacing: 0.04px;
      max-width: 100%;
      margin-left: 0;
      padding: 0.8em 2.5em 0 2.6em;
    }
  }

  .awards_sec4 {
    padding-top: 3em;
    .sec4_row {
      .sec4_col {
        margin-top: 4.2em;
        &:nth-child(2n + 1) {
          .img_wrapper {
            padding: 0 6.9em;
          }
        }
        &:nth-child(2n + 2) {
          .img_wrapper {
            padding: 0 6.9em;
          }
        }
        &:last-child {
          margin-top: 4.2em;
          .img_wrapper {
            padding: 0 6.9em;
          }
        }
      }
      .desc {
        // font-size: 1.2em;
        // line-height: 1.4;
        // letter-spacing: 0.03px;
        font-size: 1em;
        line-height: 1.35;
        letter-spacing: 0.04px;
        // padding: 0.4166666666666667em 0 0;
        padding: 0.8em 0 0;
      }
      .artist {
        font-size: 0.7em;
        line-height: normal;
        letter-spacing: 0.35px;
        padding: 0.8571428571em 0 0;
      }
    }
  }

  .awards_sec5 {
    padding-top: 6.6em;
    .awards_sec5Slider_wrapper {
      .sec5_slide_img_wrapper {
        .sec5_slide_img {
          height: 100%;
        }
      }
      .sec5_slider_text_wrapper {
        margin-left: 0;
        padding: 0 2.6em 0 2.69em;
      }
      .sec5_slider_text {
        // font-size: 1.2em;
        // line-height: 1.4;
        // letter-spacing: 0.03px;
        font-size: 1em;
        line-height: 1.35;
        letter-spacing: 0.04px;
        margin-left: 0;
        // padding: 0.4166666666666667em 15.5px 0 1.5283333333em;
        padding: 0.8em 0 0;
      }
      .sec5_slider_artist {
        font-size: 0.7em;
        line-height: normal;
        letter-spacing: 0.35px;
        padding: 0.8571428571em 0 0;
      }
      .sec5_arrow_wrapper {
        left: 1.834em;
        right: 1.834em;
        top: calc(0% - 5.2em);
      }
      .sec5_prev,
      .sec5_next {
        max-width: 0.8em;
        height: 1.5em;
      }
      .swiper-horizontal > .swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal,
      .swiper-pagination-custom,
      .swiper-pagination-fraction {
        bottom: 20%;
      }
      .swiper-pagination-bullet {
        width: 0.65em;
        height: 0.65em;
      }
    }
  }

  .awards_sec6 {
    padding-top: 2.1em;
    .sec6_row {
      .sec6_col {
        margin-top: 4.2em;
        &:nth-child(2n + 1) {
          .img_wrapper {
            padding: 0 6.9em;
          }
        }
        &:nth-child(2n + 2) {
          .img_wrapper {
            padding: 0 6.9em;
          }
        }
        &:last-child {
          margin-top: 1.5em;
          .img_wrapper {
            padding: 0 6.9em;
          }
        }
      }
      .desc {
        font-size: 1em;
        line-height: 1.35;
        letter-spacing: 0.04px;
        padding: 0.8em 0 0;
      }
      .artist {
        font-size: 0.7em;
        line-height: normal;
        letter-spacing: 0.35px;
        padding: 0.8571428571em 0 0;
      }
    }
  }

  .awards_sec7 {
    padding-top: 7.9em;
    .awards_sec7Slider_wrapper {
      .sec7_slide_img_wrapper {
        .sec7_slide_img {
          height: 100%;
        }
      }
      .sec7_slider_text_wrapper {
        margin-left: 0;
        padding: 0 2.7em 0 2.79em;
      }
      .sec7_slider_text {
        // font-size: 1.2em;
        // line-height: 1.4;
        // letter-spacing: 0.03px;
        // letter-spacing: 0.025px;
        font-size: 1em;
        line-height: 1.35;
        letter-spacing: 0.04px;
        margin-left: 0;
        // padding: 1em 15.5px 0;
        // padding: 0.4166666666666667em 15.5px 0 1.5283333333em;
        padding: 0.8em 0 0;
      }
      .sec7_slider_artist {
        font-size: 0.7em;
        line-height: normal;
        letter-spacing: 0.35px;
        padding: 0.8571428571em 0 0;
      }
      .sec7_arrow_wrapper {
        left: 1.834em;
        right: 1.834em;
        top: calc(0% - 5.2em);
      }
      .sec7_prev,
      .sec7_next {
        max-width: 0.8em;
        height: 1.5em;
      }
      .swiper-horizontal > .swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal,
      .swiper-pagination-custom,
      .swiper-pagination-fraction {
        bottom: 20%;
      }
      .swiper-pagination-bullet {
        width: 0.65em;
        height: 0.65em;
      }
    }
  }

  .awards_sec9 {
    padding: 2.1em 0 4.5em;
    border: none;
    .sec9_row {
      .sec9_col {
        margin-top: 4.2em;
        &:nth-child(2n + 1) {
          .img_wrapper {
            padding: 0 6.9em;
          }
        }
        &:nth-child(2n + 2) {
          .img_wrapper {
            padding: 0 6.9em;
          }
        }
        // &:last-child {
        //   margin-top: 2.9em;
        //   .img_wrapper {
        //     padding: 0 6.9em;
        //   }
        // }
      }
      .desc {
        // font-size: 1.2em;
        // line-height: 1.4;
        // letter-spacing: 0.03px;
        font-size: 1em;
        line-height: 1.35;
        letter-spacing: 0.04px;
        // padding: 1em 0 0;
        // padding: 0.4166666667em 0 0;
        padding: 0.8em 0 0;
      }
      .artist {
        font-size: 0.7em;
        line-height: normal;
        letter-spacing: 0.35px;
        padding: 0.8571428571em 0 0;
      }
    }
  }

  .awards_sec11 {
    padding: 4.5em 15.5px 15em;
    .desc {
      max-width: 31.9em;
      .desc_details {
        text-align: center;
        margin: 0 auto 1.5em;
        &.head {
          font-size: 1.4em;
          line-height: 1.4em;
          font-weight: 500;
        }
        &.para {
          font-size: 1.6em;
          line-height: 1.35em;
          font-weight: 400;
        }
      }
    }
  }

  .award_line_section {
    .white_line {
      height: 1px;
    }
  }
}
