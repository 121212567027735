@import "../../common.scss";
.privacysec {
  padding-top: 12.25em;
  padding-bottom: 6em;
  .otnotice-section {
    padding: 0 0 2.5em;
  }
  .otnotice-section-content ul {
    padding: 0 !important;
  }
  table {
    margin: 2.5em 0;
    td {
      padding: 1em 0.5em;
    }
  }
  a {
    color: $color-000000;
    text-decoration: underline;
    width: 100%;
    &:hover {
      color: $color-000000;
    }
  }
  .col2 {
    border-left: 1px solid $color-000000;
    padding: 0;
    padding-left: 3em;
  }
  .bold_text {
    font-weight: bold;
  }
  .single_para {
    margin-top: 1em;
  }
  .term_box_para {
    margin-top: 0.7em;
  }
  .term_content_box {
    margin-top: 3em;
  }
  .bullet_points_wrapper {
    padding-left: 2em;
    // list-style: disc;
    li {
      list-style-type: disc;
      margin-top: 0.7em;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  .section_desc {
    font-size: 1.25rem;
  }
}
@media (min-width: 2560px) {
  .privacysec {
    padding-top: 19.25em;
    padding-bottom: 10em;
  }
}
@media (max-width: 1200px) {
  .privacysec {
    .my_container {
      padding: 0 38px;
    }
  }
}
@media (max-width: 991px) {
  .privacysec {
    overflow: hidden;
    .col2 {
      border-left: none;
      padding-left: 0;
      border-top: 1px solid $color-000000;
      margin-top: 2em;
      padding-top: 2em;
    }
    .section_desc {
      font-size: 0.813rem;
    }
  }
}
@media (max-width: 767px) {
  .privacysec {
    padding-top: 9.7em;
    .my_container {
      padding: 0 15px;
    }
  }
}
