@import "../../common.scss";

.prize_sec1 {
  .sec1_wrapper {
    padding: 12.625em 0;
    .text_container {
      text-align: center;
      max-width: 43.75em;
      margin: 0 auto;
      .prize_desc {
        padding: 1.111em 0 1.667em;
        font-weight: 300;
      }
      .cta_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.grow_sec {
  background: $color-3172A9;
}

.imagine_sec {
  background: $color-C774A0;
}

.create_sec {
  background: $color-FC9500;
}

.prize_category {
  border-bottom: 1px solid $color-FFFFFF;
  &:last-child {
    border: none;
  }
  .sec2_wrapper {
    padding: 6.875em 0;
    color: $color-FFFFFF;
    .text_container {
      text-align: center;
      margin: 0 auto;
      .category_heading {
        font-size: 3.125em;
      }
      .prize_desc {
        max-width: 26em;
        font-size: 1.25em;
        padding: 1em 0 0;
        font-weight: 200;
        margin: 0 auto;
      }
      .imagine_prize_desc {
        max-width: 25em;
      }
      .prize_amount {
        padding: 1.111em 0 2.75em;
      }
      .highlights_list {
        max-width: 33em;
        margin: 0 auto;
        .list-item {
          @include fontStyle(0.875em, 1.4em, normal, 200);
          text-transform: uppercase;
          padding: 1.071em 0;
          border-bottom: 1px solid $color-FFFFFF;
          margin: 0 auto;
          &:first-child {
            padding: 0 0 1.071em;
          }
          &:last-child {
            border: none;
            padding: 1.071em 0 0;
          }
          .external {
            color: inherit;
          }
          &.mentorship_list_item {
            max-width: 32em;
          }
          &.create_mentorship_list_item {
            max-width: 30em;
          }
        }
      }
    }
  }
}

.prize_bootcamp {
  text-align: center;
  padding: 12.5em 0;
  .bootcamp_content {
    width: 100%;
    max-width: 43.625em;
    margin: 0 auto;
  }
  .logo {
    width: 100%;
    max-width: 37.375em;
  }
  .bootcamp_title {
    color: #000;
    font-size: 1.875em;
    font-weight: 200;
    line-height: 1.2;
    text-transform: uppercase;
    margin: 0.3334em 0 1em;
  }
  .bootcamp_desc {
    color: #000;
    font-size: 1.125em;
    font-weight: 300;
    line-height: 1.4;
  }
}

@media (max-width: 992px) {
  .prize_sec1 {
    .sec1_wrapper {
      .text_container {
        max-width: 80%;
      }
    }
  }

  .prize_category {
    .sec2_wrapper {
      .text_container {
        max-width: 60%;
        .prize_desc,
        .prize_amount {
          font-size: 1.5em;
        }
        .highlights_list {
          .list-item {
            @include fontStyle(1em, 1.4em, normal, 200);
          }
        }
      }
    }
  }

  .prize_bootcamp {
    .bootcamp_content {
      max-width: 50em;
    }
    .bootcamp_title {
      font-size: 2em;
    }
    .bootcamp_desc {
      font-size: 1.33333em;
    }
  }
}

@media (max-width: 600px) {
  .prize_sec1 {
    .sec1_wrapper {
      padding: 15em 1em;
      .text_container {
        max-width: 100%;
        .prize_desc {
          padding: 1.333em 0;
        }
      }
    }
  }

  .prize_category {
    .my_container {
      padding: 0 1.5em;
    }
    .sec2_wrapper {
      padding: 9.1em 0 15em;
      .text_container {
        max-width: 100%;
        .category_heading {
          font-size: 2.5em;
        }
        .prize_desc {
          padding: 1.333em 0 0;
          max-width: 20em;
          margin: 0 auto;
        }
        .prize_amount {
          padding: 1.333em 0 2.667em;
        }
        .highlights_list {
          max-width: 100%;
          .list-item {
            @include fontStyle(1.1em, 1.545em, 0.44px, 200);
            padding: 1.364em 0;
            &:first-child {
              padding: 0 0 1.364em;
            }
            &:last-child {
              padding: 1.364em 0 0;
            }
          }
        }
      }
    }
  }

  .prize_bootcamp {
    padding: 15em 0;
    .logo {
      max-width: 30.2em;
    }
    .bootcamp_content {
      max-width: 30.4em;
    }
    .bootcamp_title {
      font-size: 2.5em;
      line-height: normal;
      margin: 0.4em 0 1.2em;
    }
    .bootcamp_desc {
      font-size: 1.5em;
      line-height: 1.334;
    }
  }
}
