/*============================
    B&Y 2024 colors    
============================*/
$color-000000: #000000;
$color-BCBCBC: #bcbcbc;
$color-FFFFFF: #ffffff;
$color-FBF7F3: #fbf7f3;
$color-FC9500: #fc9500;
$color-C774A0: #c774a0;
$color-862547: #862547;
$color-BBE5FC: #bbe5fc;
$color-3172A9: #3172a9;
$color-D9D9D9: #d9d9d9;

// FONTS
$GT: "GT America Mono";

$container-size: 1130px;
$container-1194: 1031px;
$container-1080: 931px;
$container-992: 700px;

body {
  font-family: "Good Sans";
  color: $color-000000;
}

// video {
//   width: 100% !important;
// }
.dummy {
  min-height: 100vh;
}

img {
  object-fit: cover;
  height: auto;
}

::-webkit-scrollbar {
  width: 0;
  background-color: #545353;
}

::-webkit-scrollbar-thumb {
  background-color: $color-000000;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

// select {
//   -moz-appearance: none;
//   -webkit-appearance: none;
//   appearance: none;
// }
a {
  text-decoration: none;
  color: $color-000000;

  &:hover {
    color: $color-000000;
  }
}

.row {
  margin-left: 0;
  margin-right: 0;
}

:focus {
  outline: none;
  outline: -webkit-focus-ring-color auto 0px;
}

::-moz-focus-inner {
  border: 0;
}

button:focus,
textarea:focus,
input:focus {
  outline: none;
}

.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.swiper-container-multirow-column>.swiper-wrapper {
  flex-direction: column;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 2px;
}

li {
  list-style-type: none;
}

[type="submit"],
button,
html [type="button"] {
  appearance: none;
  -webkit-appearance: none;
}

input [type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $color-000000 !important;
}

table {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.my_container {
  max-width: $container-size;
  width: 100%;
  margin: 0 auto;
}

.form_field_wrapper {
  margin: 0 0 1.375em;
  // &:nth-child(n + 2) {
  //   margin-top: 1.25em;
  // }
}

.company_date {
  text-align: center;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.form_input {
  display: block;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent !important;
  font-size: 0.813em;
  line-height: normal;
  font-weight: 400;
  letter-spacing: 0.043px;
  color: $color-000000;
  font-family: "GT America Mono";
  padding: 1.308em 6em 1.308em 2.308em;
  border: 1.5px solid $color-000000;
  border-radius: 4.875em;
  outline: none;

  &::-webkit-input-placeholder {
    /* Edge */
    color: $color-000000;
    text-transform: uppercase;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-000000;
    text-transform: uppercase;
  }

  &::placeholder {
    color: $color-000000;
    text-transform: uppercase;
  }
}

.form_input:-webkit-autofill,
.form_input:-webkit-autofill:hover,
.form_input:-webkit-autofill:focus,
.form_input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  font-weight: 400;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

input:not(:placeholder-shown) {
  font-weight: 400;
}

textarea:not(:placeholder-shown) {
  font-weight: 400;
}

.home_sec9 .swiper-pagination-bullet,
.prize_sec4 .swiper-pagination-bullet {
  background: rgba($color: $color-FFFFFF, $alpha: 1);
}

.divider_line {
  border-bottom: 1px solid $color-BCBCBC;
}

@media (min-width: 2561px) {
  body {
    font-size: 24px;
  }
}

@media (min-width: 1921px) {
  body {
    font-size: 20px;
  }
}

@media (max-device-width: 1536px) {
  body {
    font-size: 16px;
  }
}

@media (max-device-width: 1366px) {
  body {
    font-size: 14px;
  }
}

@media (max-device-width: 1279px) {
  body {
    font-size: 12px;
  }
}


@media (max-device-width: 1279px) {
  [data-aos^="fade"][data-aos^="fade"] {
    opacity: 1 !important;
  }

  [data-aos="fade-right"] {
    transform: translate3d(0, 0, 0) !important;
  }

  [data-aos="fade-left"] {
    transform: translate3d(0, 0, 0) !important;
  }
}

@media (max-device-width: 1194px) {
  .my_container {
    max-width: $container-1194;
    padding: 0 15px;
  }
}

@media (max-device-width: 834px) {
  body {
    font-size: 11.5px;
  }

  .my_container {
    max-width: 100%;
    padding: 0 36px;
  }
}

@media (max-device-width: 767px) {
  body {
    font-size: 10px;
  }
}

// Talha common css start

.full_width_container {
  max-width: 100%;
}

.zero_padding {
  padding: 0;
}

.Toastify__toast-body {
  font-size: 0.875rem;
  font-family: "Good Sans";
}

.error_message {
  display: block;
  color: $color-862547;
  font-family: "GT America Mono";
  font-size: 0.813em;
  line-height: 1.385em;
  font-weight: 400;
  letter-spacing: 0.33px;
  padding: 0.615em 0 0 2.308em;

  &:first-letter {
    text-transform: uppercase;
  }
}

.error_zero_padding {
  padding-left: 0;
  // margin-top: 1em;
}

.link_btn_nohover:hover {
  font-weight: normal;
}

.desc_with_padding {
  padding-left: 2.9em;
}

.navigate_link {
  text-decoration: underline;
  color: $color-FFFFFF;

  &:hover {
    color: $color-FFFFFF;
  }
}

.project-button {
  cursor: pointer;
  font-size: 0.8125em;
  line-height: 10px;
  font-family: "GT America Mono";
  font-weight: 400;
  letter-spacing: 0.043px;
  padding: 14.2px;
  border: 1px solid $color-000000;
  border-radius: 5px;
  color: $color-000000;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  // min-width: fit-content;
  max-width: 240px;
  position: relative;
  overflow: hidden;
  transition: 0.3s all;
  z-index: 2;

  &:hover {
    color: $color-FFFFFF;
    text-decoration: none;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: $color-000000;
    transition: 0.3s all;
  }

  &:hover::before,
  &:hover::after {
    height: 100%;
  }
}

.mincontent {
  min-width: fit-content;
}

.form_cta {
  border-radius: 2.389em;
  min-width: 16.875em;
}

.project-button.black-filled {
  color: $color-FFFFFF;
  background-color: $color-000000;

  &::before,
  &::after {
    display: none;
  }
}

.project-button.white-cta {
  border: 1px solid $color-FFFFFF;
  color: $color-FFFFFF;

  &::after {
    background-color: $color-FFFFFF;
  }

  &:hover {
    color: $color-000000;
  }
}

.navigation_wrapper {
  .nav_arrow {
    cursor: pointer;
    user-select: none;
  }
}

.swiper-button-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.section_heading {
  font-size: 3.125em;
  line-height: 1em;
  font-weight: 200;
}

.section_subheading {
  font-size: 1.875em;
  line-height: 1.2em;
  font-weight: 200;
  text-transform: uppercase;
}

.section_desc {
  font-weight: 300;
  font-size: 1.125em;
}

.anchor {
  color: $color-000000;
  font-weight: 300;

  &:hover {
    color: $color-000000;
    font-weight: 300;
  }
}

.section_subdesc {
  font-size: 1rem;
  line-height: 1.55rem;
  color: $color-000000;
}

.desc_italic {
  font-size: 1.2em;
  line-height: 1.5em;
  font-style: italic;
  color: $color-000000;
}

// @media (max-device-width: 1440px) {
//   .section_heading {
//     font-size: 3.84em;
//   }
// }

@media (max-device-width: 991px) {
  .section_heading {
    font-size: 2.188rem;
  }

  .section_desc {
    font-size: 1.5em;
  }

  .project-button {
    font-size: 0.875rem;
  }

  .form_field_wrapper {
    margin: 0 0 1.5em;
  }

  .form_input {
    font-size: 1.1em;
  }

  .error_message {
    font-size: 1em;
  }
}

@media (max-device-width: 600px) {
  .error_message {
    font-size: 11px;
    line-height: 22px;
  }

  .project-button {
    font-size: 0.6875rem;
    padding: 1em 2em;
    min-height: 40px;
    border-radius: 5px;
    max-width: 116px;
  }

  .section_heading {
    font-size: 1.5rem;
    line-height: 1.65rem;
  }

  .section_subheading {
    font-size: 2.5em;
  }

  .section_desc {
    font-size: 1.5em;
  }

  .section_subdesc {
    font-size: 1.5em;
    line-height: 23.25px;
  }

  .desc_italic {
    line-height: 1.525em;
    font-size: 1.4em;
  }

  .form_input {
    letter-spacing: 0.036px;
  }

  .form_cta {
    max-width: 24em;
  }
}

// Talha common css end

@mixin masterlass-type-status {
  font-size: 1.25em;
  color: $color-FFFFFF;
  text-transform: uppercase;
}

@mixin textStyle($font-size, $line-height, $letter-spacing) {
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}

@mixin fontStyle($font-size, $line-height, $letter-spacing, $font-weight) {
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  font-weight: $font-weight;
}