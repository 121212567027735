@import "../../common.scss";

.banner_text_container {
  position: relative;
  width: 100%;
  max-width: 35.875em;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 0 0 0 2.5em;
  padding: 0 2.5em 30vh 0;
  &.banner03 {
    .banner_desc {
      max-width: 23em
    }
  }
  .banner_title {
    @include fontStyle(3.125em, 1em, normal, 200);
    text-transform: uppercase;
    // max-width: 11em;
    vertical-align: middle;
    .title_icon {
      width: 100%;
      max-width: 2.34em;
      object-fit: contain;
      display: inline;
      padding: 0 0 0.1em;
    }
  }
  .banner_desc {
    @include fontStyle(1.125em, 1.4em, normal, 300);
    // max-width: 30.556em;
    padding: 0.833em 0 0;
  }
  .cta_wrapper {
    width: 100%;
    margin: 1.875em 0 0;
    .banner_cta {
      max-width: 150px;
    }
  }
  .featured_wrap {
    position: absolute;
    left: 0;
    bottom: 2.188em;
    .feature_text {
      @include fontStyle(0.75em, 1em, 0, 200);
      padding: 0 0 1em;
      text-transform: uppercase;
    }
    .featured_img {
      width: 100%;
      max-width: 20em;
    }
  }
  &.banner09 {
    max-width: 45em;
    .banner_desc {
      max-width: 26.722em;
    }
  }
}

@media (max-width: 600px) {
  .banner_text_container {
    max-width: 100%;
    margin: 0 0 0 1.5em;
    padding: 0 1.5em 20em 0;
    .banner_title {
      @include fontStyle(3em, 1.2em, normal, 200);
      max-width: 100%;
      .mobile_break_disable {
        display: none;
      }
    }
    .banner_desc {
      @include fontStyle(1.5em, 1.4em, normal, 300);
      padding: 0.667em 0 0;
      max-width: 95%;
      .mobile_break_disable {
        display: none;
      }
    }
    .cta_wrapper {
      margin: 1.5em 0 0;
      .banner_cta {
        max-width: 116px;
      }
    }
    .featured_wrap {
      bottom: 4.2em;
      .feature_text {
        @include fontStyle(0.6em, 1em, 0, 200);
      }
      .featured_img {
        max-width: 16.8em;
      }
    }
  }
  .banner01 {
    .banner_title {
      max-width: 11.333em;
    }
  }

  .banner02 {
    .banner_title {
      max-width: 9.933em;
    }
    .banner_desc {
      max-width: 18.6em;
    }
  }

  .banner03 {
    .banner_title {
      max-width: 9.933em;
    }
    .banner_desc {
      max-width: 19.267em;
    }
  }

  .banner04 {
    .banner_title {
      max-width: 9.933em;
    }
    .banner_desc {
      max-width: 12.467em;
    }
  }

  .banner05 {
    .banner_title {
      max-width: 9.933em;
    }
    .banner_desc {
      max-width: 21.4em;
    }
  }

  .banner06 {
    .banner_title {
      max-width: 11.333em;
    }
    .banner_desc {
      max-width: 16.867em;
    }
  }

  .banner07 {
    .banner_desc {
      max-width: 16.867em;
      br {
        display: none;
      }
    }
  }
}
