@import "../../../common.scss";
.signupthankyousec {
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  padding: 17em 0 12.063em;
  .comingbg {
    width: 100%;
    height: 100%;
  }
  .coming_content {
    position: relative;
    width: 100%;
    max-width: calc(670px + 7.1em);
    margin: 0 auto;
    padding: 0 3.5em 0 3.6em;
    text-align: center;
    .section_subdesc {
      @include fontStyle(1.125em, 1.4em, normal, 300);
      margin: 1.389em auto;
    }

    .news_input {
      font-family: "GT-america-mono-lt-normal";
      font-size: 1em;
      padding: 1.6em 2.579em;
      padding-right: 6.2em;
      border-radius: 2.688em;
    }
    .news_form {
      position: relative;
    }
    .news_submit_btn {
      background-color: transparent;
      border: 0;
      position: absolute;
      top: 1.9em;
      // transform: translateY(-50%);
      right: 1.7em;
      font-size: 1em;
      color: $color-000000;
      font-family: "GT-america-mono-lt-normal";
      text-transform: uppercase;
    }
    .thank_message {
      margin-top: 0.5em;
    }
    .logout_text {
      @include fontStyle(1.125em, 1.4em, normal, 300);
      color: $color-000000;
      max-width: 26.563em;
      margin: 0 auto;
    }
    .anchor {
      color: $color-000000;
    }
    .btn_wrapper {
      // max-width: 16.875rem;
      margin: 2.5em auto 0;
      .form_cta {
        margin: 0 auto;
      }
    }
  }
}
@media (min-height: 1400px) {
  .signupthankyousec {
    min-height: 82.5vh;
  }
}

@media (max-width: 1366px) {
  .signupthankyousec {
    .coming_content {
      .section_subdesc {
        max-width: 35em;
      }
    }
  }
}

@media (max-width: 992px) {
  .signupthankyousec {
    height: 70vh;
  }
}

@media (max-width: 767px) {
  .signupthankyousec {
    height: initial;
    padding: 14.7em 0 9em;
    .coming_content {
      .section_subdesc{
        font-size: 1.5em;
        margin: 3.333em auto 1.25em;
      }
      .logout_text{
        font-size: 1.5em;
      }
      .news_input {
        font-size: 1.2em;
      }
      .news_submit_btn {
        font-size: 1.2em;
      }
      .btn_wrapper{
        margin: 5em auto 0;
      }
      .thank_message {
        margin-top: 1.5em;
      }
    }
  }
}
