@import "../../common.scss";
.signup_sec1 {
  padding: 16.563em 0 11.563em;
  .my_container {
    max-width: 670px;
  }
  .text_container {
    text-align: center;
    .section_heading {
      text-transform: uppercase;
      @include fontStyle(2.063em, 1.25em, normal, 300);
    }
    .section_subdesc {
      @include fontStyle(1.125em, 1.4em, normal, 300);
      max-width: 30.167em;
      margin: 1.389em auto 0;
    }
  }
  .form_container {
    padding: 4.375em 0 0;
    .checkbox_wrapper {
      position: relative;
      margin: 0 0 1.313em;
      .checkbox_div2 {
        display: block;
        @include fontStyle(0.938em, 1.4em, normal, 300);
        label,
        a {
          color: $color-000000;
        }
        .checkbox_desc {
          margin-bottom: 0;
          a {
            text-decoration: underline;
          }
        }
      }
      .checkbox_div2 input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
      }

      .checkbox_div2 label {
        position: relative;
        cursor: pointer;
        text-align: left;
        margin: 0 0 0 2em;
      }

      .checkbox_div2 label:before {
        position: absolute;
        height: fit-content;
        top: 0;
        bottom: 0;
        margin: auto 0;
        left: -2em;
        content: "";
        appearance: none;
        -webkit-appearance: none;
        background-color: white;
        border-radius: 50%;
        border: 3px solid $color-FFFFFF;
        outline: 1px solid $color-000000;
        // box-shadow: 0 0 5px $color-000000;
        //   inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 0.413em;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
      }

      .checkbox_div2 input:checked + label::before {
        background-color: $color-000000;
      }
    }
    .confirmation_wrapper {
      // margin-top: 1.5em;
      margin-right: 2em;
      // margin-bottom: 1.438rem;
      .confirmation_input {
        display: none;
      }
      .confirmation_label {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        cursor: pointer;
        width: fit-content;
        margin-bottom: 1.438em;
        margin-top: 2.5rem;
        .confirmation_outer {
          margin-top: 0.15rem;
          width: 1.5rem;
        }
        .confirmation_outer2,
        .confirmation_outer3 {
          width: 1.2rem;
        }
      }
      .confirmation_outer {
        width: 1.25rem;
        height: 1.125rem;
        border-radius: 50%;
        border: 1.8px solid $color-000000;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .confirmation_right_tick {
        display: inline-block;
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 50%;
      }
      .confirmation_label_text {
        font-size: 1rem;
        padding-left: 1rem;
        line-height: 22px;
        .link_btn {
          text-decoration: underline;
        }
      }
      .confirmation_input:checked
        ~ .confirmation_label
        .confirmation_right_tick {
        transition: all 0.5s;
        background-color: $color-000000;
      }
    }
    .btn_wrapper {
      // max-width: 16.875rem;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      .submit_btn {
        background-color: transparent;
      }
    }
  }
}

.otp_sec {
  padding: 11.938em 0;
  .content_wrapper {
    width: 100%;
    max-width: 40.25em;
    margin: auto;
  }
  .otp_input_wrapper {
    justify-content: center;
    margin-top: 3.313em;
    div {
      &:first-child {
        .otp_input {
          margin-left: 0;
        }
      }
    }
  }
  .otp_input {
    background-color: transparent;
    border: 1.5px solid $color-000000;
    font-size: 1.875em;

    width: 3.17em !important;
    height: 3.17em;
    display: inline-block;
    border-radius: 0.333em;
    margin-left: 1.833em;
  }
  .otp_focused {
    border: 2px solid $color-000000;
  }
  .resend_otp_text {
    font-size: 0.813rem;
    text-transform: uppercase;
    text-align: center;
    margin-top: 1.1em;
    cursor: pointer;

    font-size: 0.813em;
  }
  .otp_inbox_msg {
    font-size: 1.25em;
    margin: 1em 0 0;
    text-align: center;
  }
  .inbox_notice_msg {
    font-size: 1em;
    margin: 1.5em 0 0;
    text-align: center;

    a {
      color: $color-000000;
    }
  }
  .otp_sent_text {
    margin-top: 0.7em;
    text-align: center;
  }
  .otp_verify_btn {
    background: transparent;
    margin: 2.5em auto 0;
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
  }
}

@media (min-width: 1800px) {
  .signup_sec1,
  .otp_sec {
    min-height: 80vh;
    display: flex;
  }
}

@media (max-width: 991px) {
  .signup_sec1 {
    .text_container {
      .section_heading {
        font-size: 2.5em;
      }
      .section_subdesc {
        font-size: 1.5em;
        max-width: 22.5em;
        // max-width: 16.8em;
      }
    }
    .form_container {
      padding: 9em 0 0;
      .checkbox_wrapper {
        .checkbox_div2 {
          .checkbox_desc {
            font-size: 0.875rem;
            line-height: 1.25rem;
            // &::before {
            //   top: 0;
            // }
          }
        }
      }
      .confirmation_wrapper {
        .confirmation_label {
          .confirmation_outer {
            margin-top: 0.05rem;
            width: 1.75rem;
            height: 1.25rem;
          }

          .confirmation_outer2,
          .confirmation_outer3 {
            width: 1.25rem;
          }
        }
        .confirmation_right_tick {
          width: 0.75rem;
          height: 0.75rem;
        }
      }
    }
  }

  .otp_sec {
    .content_wrapper {
      .otp_inbox_msg {
        font-size: 1.125rem;
      }
      .inbox_notice_msg {
        font-size: 1rem;
      }
      .resend_otp_text,
      .otp_sent_text {
        font-size: 0.875rem;
      }
    }
    .otp_input {
      width: 2.833em !important;
      height: 2.5em;
    }
  }
}

@media (max-width: 767px) {
  .confirmation_wrapper {
    .confirmation_label_text {
      font-size: 0.938rem;
    }
  }
}

@media (max-width: 600px) {
  .signup_sec1 {
    padding: 14.7em 0 12.3em;
    .my_container {
      padding: 0 2.7em 0 2.2em;
    }
    .text_container {
      .section_heading {
        line-height: normal;
      }
      .section_subdesc {
        max-width: 16.8em;
        margin: 1em auto 0;
      }
    }
    .form_container {
      .checkbox_wrapper {
        margin: 0 0 1.54em;
        .checkbox_div2 {
          .checkbox_desc {
            @include fontStyle(0.9em, 1.778em, normal, 400);
            letter-spacing: -0.33px;
            text-transform: uppercase;
          }
        }
      }
      .btn_wrapper {
        max-width: 15rem;
      }
      .confirmation_wrapper {
        .confirmation_label {
          .confirmation_outer {
            margin-top: 0.5rem;
            width: 18px !important;
            height: 18px !important;
          }
          .confirmation_outer2,
          .confirmation_outer3 {
            width: 18px !important;
            height: 18px !important;
          }
          .confirmation_right_tick {
            width: 10px;
            height: 10px;
          }
        }
        .confirmation_right_tick {
          width: 10px;
          height: 10px;
        }
        .confirmation_label_text {
          max-width: 95%;
        }
      }
    }
  }

  .otp_sec {
    padding: 14.7em 2.7em 12.3em 2.4em;
    .otp_input {
      margin-left: 0.7em;
    }
    .content_wrapper {
      .section_heading {
        font-size: 1.25rem;
      }
      .otp_inbox_msg {
        font-size: 1rem;
      }
      .inbox_notice_msg {
        font-size: 0.938rem;
      }
      .resend_otp_text,
      .otp_sent_text {
        font-size: 0.813rem;
      }
    }
    .otp_verify_btn {
      margin: 2.5rem auto 0;
    }
  }
}
