@import "../../common.scss";

.warning_desc_wrapper {
  max-width: 85%;
}
// .checkbox_wrapper {
//   .checkbox-div {
//     display: block;
//     margin-top: 2.5rem;
//     margin-bottom: 1.438em;
//   }
//   .checkbox-div input {
//     padding: 0;
//     height: initial;
//     width: initial;
//     margin-bottom: 0;
//     display: none;
//     cursor: pointer;
//   }
//   .section_desc {
//     margin-bottom: 0.5em;
//     .consent_line {
//       margin-left: 2.6em;
//     }
//   }

//   .checkbox-div label {
//     position: relative;
//     cursor: pointer;
//     text-align: left;
//   }

//   .checkbox-div label:before {
//     content: "";
//     -webkit-appearance: none;
//     background-color: $color-FFFFFF;
//     border-radius: 50%;
//     border: 1.8px solid $color-862547;
//     // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
//     //   inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
//     padding: 0.8em;
//     display: inline-block;
//     position: relative;
//     vertical-align: middle;
//     cursor: pointer;
//     margin-bottom: 0.45em;
//     margin-right: 1em;
//   }

//   .checkbox-div input:checked + label:after {
//     content: "";
//     display: block;
//     position: absolute;
//     top: 0.25em;
//     left: 0.6em;
//     width: 0.5em;
//     height: 0.9em;
//     background-color: $color-FFFFFF;
//     border: solid $color-862547;
//     border-width: 0 2px 2px 0;
//     transform: rotate(45deg);
//   }

//   .checkbox-div2.active {
//     opacity: 1;
//   }
//   .checkbox-div2 {
//     // display: none;
//     display: block;
//     // opacity: 0.7;
//     margin-bottom: 2.604em;
//     .for_mobile {
//       br {
//         display: none;
//       }
//     }
//   }
//   .checkbox-div2 input {
//     padding: 0;
//     height: initial;
//     width: initial;
//     margin-bottom: 0;
//     display: none;
//     cursor: pointer;
//   }

//   .checkbox-div2 label {
//     position: relative;
//     cursor: pointer;
//     text-align: left;
//   }

//   .checkbox-div2 label:before {
//     content: "";
//     -webkit-appearance: none;
//     background-color: white;
//     border-radius: 50%;
//     border: none;
//     // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
//     //   inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
//     padding: 0.8em;
//     display: inline-block;
//     position: relative;
//     vertical-align: middle;
//     cursor: pointer;
//     margin-bottom: 0.45em;
//     margin-right: 1em;
//   }

//   // .checkbox-div2 input:checked + label:after {
//   //   content: "";  
//   //   display: block;
//   //   position: absolute;
//   //   top: 0.25em;
//   //   left: 0.5em;
//   //   width: 0.5em;
//   //   height: 0.9em;
//   //   background-color: $color-FFFFFF;
//   //   border: solid #000;
//   //   border-width: 0 2px 2px 0;
//   //   transform: rotate(45deg);
//   // }
// }
.checkbox_wrapper2 {
  position: relative;
  .checkbox-div2 {
    display: block;
    margin-top: 2.5rem;
    margin-bottom: 1.438em;
    line-height: 1.7em;
    label,
    a {
      color: $color-862547;
    }
    .checkbox_desc {
      font-family: "GT America Mono";
    }
  }
  .checkbox-div2 input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .checkbox-div2 label {
    position: relative;
    cursor: pointer;
    text-align: left;
    margin-left: 2.5rem;
  }

  .checkbox-div2 label:before {
    position: absolute;
    top: 0.25rem;
    left: -2.5em;
    content: "";
    -webkit-appearance: none;
    background-color: white;
    border-radius: 50%;
    border: 3px solid $color-FFFFFF;
    outline: 1.8px solid $color-862547;
    // box-shadow: 0 0 5px $color-862547;
    //   inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 0.463em;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }

  .checkbox-div2 input:checked + label::before {
    background-color: $color-862547;
  }
  .confirmation_error {
    padding-left: 2.5rem;
  }
  // .checkbox-div2 input:checked + label:after {
  //   content: "";
  //   display: block;
  //   background-color: gray;
  //   position: absolute;
  //   top: 0;
  //   left: -2.75em;
  //   width: 0.5em;
  //   height: 0.9em;
  //   padding: 0.6em;
  //   // background-color: $color-FFFFFF;
  //   // border: solid #000;
  //   border-radius: 50%;
  //   // border-width: 0 2px 2px 0;
  //   // transform: rotate(45deg);
  // }

  a {
    color: $color-000000;
  }
}
.link_btn {
  text-decoration: underline;
}

@media (max-width: 992px) {
  .warning_desc_wrapper {
    text-align: left;
    max-width: 100%;
  }
  .checkbox_wrapper {
    max-width: 80%;
    .error_message {
      font-size: 1em;
      bottom: -1em;
    }
  }
}
