.pagenotsec {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  h1 {
    text-align: center;
    text-transform: capitalize;
    font-size: 7em;
    color: #000;
    a {
      color: #000;
      text-decoration: underline;
    }
  }
}
