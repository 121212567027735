@import "../../common.scss";

.step_count_wrapper {
  margin: 0 0 4.563em;
  .section_heading {
    @include fontStyle(2.063em, 1.25em, normal, 400);
  }
  .step_count {
    margin: 0.875rem 0;
    @include fontStyle(1.125em, 1.4em, normal, 300);
  }
  .section_subdesc {
    color: $color-000000;
    font-size: 0.813em;
  }
}

@media (max-width: 992px) {
  .step_count_wrapper {
    .step_count {
      font-size: 1.25rem;
      line-height: 27px;
      margin: 1.5rem 0 0.5rem;
    }
    .section_heading {
      font-size: 2.188rem;
      line-height: 26.2px;
    }
  }
}

@media (max-width: 600px) {
  .step_count_wrapper {
    // text-align: center;
    margin-bottom: 4em;
    .step_count {
      @include fontStyle(1.4em, 1.4em, normal, 500);
      margin: 0.5em 0;
    }
    .section_heading {
      @include fontStyle(2.5em, normal, normal, 200);
    }
    .section_subdesc {
      @include fontStyle(1.4em, 1.4em, normal, 200);
    }
  }
}
