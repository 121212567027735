@import "../../common.scss";
.footer {
  border-top: 1px solid $color-000000;
  .my_container {
    max-width: 100%;
    margin: 0;
    padding: 0 2.5em;
  }
  .footerform_wrapper {
    position: relative;
    max-width: 27.5em;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1.8px solid $color-000000;
    border-radius: 2.389rem;
    margin: 2px 0 1.25em;
    .news_submit_btn {
      position: relative;
      background-color: transparent;
      border: 0;
      border-left: 1.8px solid $color-000000;
      font-size: 0.75em;
      line-height: 1.572em;
      color: $color-000000;
      font-family: "GT America Mono";
      text-transform: uppercase;
      padding: 1.438em 2.063em;
      cursor: pointer;
    }
    .footer_form_field {
      font-size: 0.813rem;
      line-height: 1.7em;
      color: $color-000000;
      font-family: "GT America Mono";
      // padding-top: 1.444em;
      // padding-bottom: 1.444em;

      padding: 1.438em 6em 1.438em 1.54em;
      border-radius: 2.389em;
      width: 100%;
      border: none;
      display: block;
      &::-webkit-input-placeholder {
        /* Edge */
        color: $color-000000;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $color-000000;
      }

      &::placeholder {
        color: $color-000000;
      }
    }
  }
  .checkbox_wrapper {
    position: relative;
    max-width: 27.5em;
    .checkbox-div2 {
      display: block;
      margin-bottom: 0;
      font-size: 0.813em;
      line-height: 1.7em;
      text-transform: uppercase;
      label,
      a {
        color: $color-000000;
        &:hover {
          font-weight: normal;
        }
      }
      .checkbox_desc {
        font-family: "GT America Mono";
      }
    }
    .checkbox-div2 input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }

    .checkbox-div2 label {
      position: relative;
      cursor: pointer;
      text-align: left;
      margin-left: 2.5em;
    }

    .checkbox-div2 label:before {
      position: absolute;
      top: 0.25rem;
      left: -2.5em;
      content: "";
      appearance: none;
      -webkit-appearance: none;
      background-color: white;
      border-radius: 50%;
      border: 3px solid $color-FFFFFF;
      outline: 1.8px solid $color-000000;
      // box-shadow: 0 0 5px $color-000000;
      //   inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 0.463em;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }

    .checkbox-div2 input:checked + label::before {
      background-color: $color-000000;
    }
    // .checkbox-div2 input:checked + label:after {
    //   content: "";
    //   display: block;
    //   background-color: gray;
    //   position: absolute;
    //   top: 0;
    //   left: -2.75em;
    //   width: 0.5em;
    //   height: 0.9em;
    //   padding: 0.6em;
    //   // background-color: $color-FFFFFF;
    //   // border: solid #000;
    //   border-radius: 50%;
    //   // border-width: 0 2px 2px 0;
    //   // transform: rotate(45deg);
    // }
  }
  .confirmation_wrapper {
    position: relative;
    .confirmation_input {
      display: none;
    }
    .confirmation_label {
      max-width: 100%;
      color: $color-000000;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      cursor: pointer;
      width: fit-content;
      margin-bottom: 1.438em;
      margin-top: 2rem;
      .confirmation_outer {
        margin-top: 0.4rem;
        width: 1.75rem;
        height: 1.125rem;
      }
      .link_btn {
        text-decoration: none;
      }
    }
    .confirmation_outer {
      width: 1.75rem;
      padding: 0.1rem;
      height: 1.125rem;
      border-radius: 50%;
      border: 1.8px solid $color-000000;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .confirmation_right_tick {
      display: inline-block;
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 50%;
    }
    .confirmation_label_text {
      padding-left: 1rem;
      font-size: 0.813rem;
      line-height: 1.7em;
      font-family: "GT America Mono";
      text-transform: uppercase;
      label,
      a {
        color: $color-000000;
        &:hover {
          font-weight: normal;
        }
      }
    }
    .confirmation_input:checked ~ .confirmation_label .confirmation_right_tick {
      transition: all 0.5s;
      background-color: $color-000000;
    }
    .error_message {
      font-size: 0.813rem;
      color: $color-000000;
      padding-left: 2rem;
    }
  }

  .error_message {
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    font-size: 0.813rem;
    color: $color-000000;
  }
  .footer_left,
  .footer_right {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }
  .footer_left {
    max-width: 42.5%;
    .thank_message {
      margin-left: 2.5em;
      font-size: 0.813rem;
      color: $color-000000;
      text-transform: uppercase;
      font-family: "GT America Mono";
    }
    .line1 {
      font-size: 0.813em;
      line-height: 1.7em;
      color: $color-000000;
      font-family: "GT America Mono";
      text-transform: uppercase;
    }
    .line2_img {
      padding: 0.625em 0 1.1em;
      img {
        max-width: 26em;
      }
    }
  }
  .footer_right {
    position: relative;
    max-width: 57.5%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    // padding-left: 7em;
    // padding-right: 7em;
    .footer_link {
      display: block;
      color: $color-000000;
      font-family: "GT America Mono";
      text-transform: uppercase;
      font-size: 0.813em;
      line-height: 1.7em;
      &:nth-child(n + 2) {
        margin-top: 0.625rem;
      }
      &:hover {
        font-weight: normal;
      }
    }
    .footer_social_icons_wrapper {
      margin-top: 0.625rem;
      display: flex;
      align-items: flex-end;
      a {
        margin-left: 1em;
        &:first-child {
          margin-left: 0;
        }
      }
      img {
        max-width: 1.563em;
        transition: 0.3s transform;
        &:hover {
          transform: scale(1.3);
        }
      }
    }
    .col2_middle {
      .sponsor_flex {
        .sponsor_img {
          width: 100%;
          max-width: 3.688em;
          margin-right: 1em;
        }
      }
      .supporting_sponsor_flex {
        margin-top: 1em;
        .supporting_sponsor_img {
          width: 100%;
          max-width: 2.688em;
          margin-right: 1em;
        }
      }
    }
    .col2_right {
      position: absolute;
      right: 0;
    }
  }

  .main_footer_wrap {
    padding: 2.375rem 0 1.469rem;
    .box_1 {
      .feauterd_img {
        .heading {
          font-size: 0.813em;
          line-height: 1.7em;
          color: $color-000000;
          font-family: "GT America Mono";
          text-transform: uppercase;
        }
        .img_strip {
          padding: 0.438rem 0 1.1rem;
          img {
            max-width: 26em;
          }
        }
      }
    }
    .quick_wrap {
      .quick_link {
        display: block;
        color: $color-000000;
        text-transform: uppercase;
        font-family: "GT America Mono";
        text-decoration: none;
        font-size: 0.813rem;
        line-height: 1.375rem;

        margin: 0 0 0.625rem;
        &:hover {
          font-weight: 600;
        }
      }
    }
    .contact_flex {
      display: flex;
      justify-content: flex-end;
      padding: 0;
    }
    .contact_wrap {
      .quick_link {
        display: block;
        color: $color-000000;
        text-transform: uppercase;
        font-family: "GT America Mono";
        text-decoration: none;
        font-size: 0.813rem;
        line-height: 1.375rem;

        margin: 0 0 0.625rem;
        &:hover {
          font-weight: 600;
        }
      }
      .footer_social_icons_wrapper {
        display: flex;
        align-items: flex-end;
        a {
          margin-left: 1em;
          &:first-child {
            margin-left: 0;
          }
        }
        img {
          max-width: 1.5em;
          transition: 0.05s transform;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
    .thank_message {
      margin-left: 2.5em;
      font-size: 0.813rem;
      color: $color-000000;
      text-transform: uppercase;
      font-family: "GT America Mono";
    }
    .signup_heading {
      font-size: 0.813rem;
      color: $color-000000;
      text-transform: uppercase;
      font-family: "GT America Mono";
      margin-bottom: 0.688rem;
    }
  }

  .copyright_wrapper {
    text-align: center;
    padding-top: 0.625em;
    padding-bottom: 0.625em;
    background-color: $color-000000;
    p,
    a {
      color: $color-FFFFFF;
      font-size: 0.781em;
      line-height: 1.172em;
    }
  }
}

@media (max-width: 992px) {
  .footer {
    .footerform_wrapper {
      max-width: 80%;
      margin: 2px 0 2.5em;
      .news_submit_btn {
        font-size: 0.813rem;
        padding: 0.75em 15px;
      }
    }
    .confirmation_wrapper {
      .confirmation_label {
        max-width: 80%;
        margin-top: 1.75rem;
        .confirmation_outer {
          margin-top: 0.25rem;
          width: 1rem;
          height: 1rem;
        }
      }
      .confirmation_right_tick {
        width: 0.5rem;
        height: 0.5rem;
      }
      .confirmation_label_text {
        font-size: 0.813rem;
      }
      .error_message {
        font-size: 0.5rem;
        bottom: -1rem;
      }
    }
    .footer_left {
      max-width: 35.5%;
      .line1 {
        font-size: 1em;
      }
      .line2_img img {
        max-width: 20em;
      }
      .footer_form_field {
        max-width: 90%;
        font-size: 1em;
        padding: 0.75em 15px;
      }
    }
    .footer_right {
      max-width: 64%;
      .footer_link {
        font-size: 1em;
      }
    }
    .error_message {
      font-size: 0.5rem;
    }
    .copyright_wrapper p,
    .footer .copyright_wrapper a {
      font-size: 1.3em;
    }
    .main_footer_wrap {
      padding: 2.5rem 0 1.469rem;
      .box_1 {
        margin-bottom: 2rem;
        display: flex;
        .feauterd_img {
          margin: 0 5rem 0 0;
          .heading {
            font-size: 0.813rem;
          }
          .img_strip {
            padding: 0.75rem 0 0;
          }
        }
      }
      .quick_wrap,
      .contact_wrap {
        margin-top: 1rem;
      }
    }
  }
}

@media (max-width: 767px) {
  .footer {
    .confirmation_wrapper {
      .confirmation_label_text {
        font-size: 0.813rem;
      }
    }
    .footerform_wrapper {
      max-width: 100%;
      .news_submit_btn {
        font-size: 1.4em;
      }
    }
    .footer_flex {
      flex-direction: column;
      padding: 1.5em 0 3.5em;
    }
    .footer_left,
    .footer_right {
      max-width: 100%;
    }
    .footer_right {
      padding-top: 0;
      border-top: 0;
      // flex-direction: column;
      justify-content: space-between;
      .footer_link {
        font-size: 1.4em;
        line-height: 1.714em;
      }
      .col2_left {
        margin-right: 0;
      }
      .col2_right {
        position: relative;
        margin-top: 0.7em;
      }
    }
    .footer_left {
      .thank_message {
        margin-bottom: 2em;
        font-size: 0.813rem;
      }
      .line1 {
        font-size: 1.4em;
      }
      .line2_img img {
        max-width: 100%;
      }
      .footer_form_field {
        max-width: 100%;
        font-size: 1.4em;
        padding-top: 1em;
        padding-bottom: 1em;
      }
    }
  }
  .full_nav_wrapper {
    .footer_flex {
      padding-bottom: 2.5em;
      .footer_right {
        .footer_social_icons_wrapper {
          a {
            margin-left: 2.5em;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .footer {
    border-top: 1px solid $color-D9D9D9;
    .my_container {
      padding: 0 15px;
    }
    .footerform_wrapper {
      margin: 0.606rem 0 0;
    }
    .confirmation_wrapper {
      .confirmation_label {
        max-width: 100%;
        // padding: 1.2em 0 0;
        padding: 2.8em 0 0;
        margin: 0;
        .confirmation_outer {
          margin-top: 0.5rem;
          width: 18px;
          height: 18px;
        }
        .confirmation_right_tick {
          width: 10px;
          height: 10px;
        }
      }
      .confirmation_right_tick {
        width: 10px;
        height: 10px;
      }
      .confirmation_label_text {
        max-width: 95%;
      }
      .error_message {
        font-size: 0.75rem;
      }
    }
    .footer_right {
      flex-direction: column;
      .line1 {
        font-size: 1.4em;
        line-height: 24.09px;
        color: $color-000000;
        font-family: "GT America Mono";
        text-transform: uppercase;
        margin: 0 0 5px;
      }
      .col2_left,
      .col2_middle,
      .col2_right {
        width: 100%;
        max-width: 100%;
      }
      .col2_left {
        padding: 15px 0 11px;
        .footer_link {
          font-size: 13px;
          line-height: 22px;
          width: 100%;
          margin: 0;
          padding: 13px 0;
          border-top: 1px solid #dddddd;
          border-bottom: 1px solid #dddddd;
        }
      }
      .col2_middle {
        padding-bottom: 16px;
        border-bottom: 1px solid #dddddd;
        .supporting_sponsor_flex {
          margin-top: 20px;
        }
      }
      .col2_right {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 11px;
        .footer_link,
        .footer_social_icons_wrapper {
          margin-top: 0;
        }
      }
    }
    .error_message {
      font-size: 0.75rem;
    }
    .main_footer_wrap {
      padding: 6em 0 2.5em;
      .box_1 {
        margin-bottom: 4.2em;
        display: block;
        .feauterd_img {
          .img_strip {
            padding: 0.438rem 0 1.119rem;
          }
        }
      }
      .quick_wrap {
        margin: 0 -15px;
        .quick_link {
          font-size: 13px;
          line-height: 22px;
          width: 100%;
          margin: 0;
          padding: 13px 15px;
          border-top: 1px solid #dddddd;
          border-bottom: none;
          &:last-child {
            border-bottom: 1px solid #dddddd;
          }
        }
      }
      .contact_wrap {
        display: flex;
        justify-content: space-between;
        .quick_link {
          margin-bottom: 0;
        }
        .mobile_box {
          display: flex;
          align-items: center;
          .quick_link {
            margin-right: 1.188rem;
          }
        }
      }
      .signup_heading {
        margin: 1.561rem 0 0;
      }
      .contact_flex {
        flex-direction: column;
        justify-content: flex-start;
      }
    }
    .copyright_wrapper {
      padding: 0.9em 0 1em;
    }
  }
}
