@import "../../common.scss";

.bof_sec1 {
  position: relative;
  overflow: hidden;
  .bof_banner_image_wrapper {
    height: 100vh;
    width: 100%;
    max-width: 100%;
    .bof_banner_img {
      width: 100%;
      max-width: 100%;
      height: 100vh;
    }
    .bof_banner_content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;
      max-width: 100%;
      .bof_head {
        @include fontStyle(3.125em, 1em, -5%, 200);
        color: $color-FFFFFF;
        text-align: center;
        text-transform: uppercase;
        .head_break {
          display: block;
        }
      }
    }
  }
}

.bof_sec2 {
  padding: 9.188em 0 6.438em;
  overflow: hidden;
  max-width: 44.625em;
  margin: 0 auto;
  .desc {
    @include fontStyle(1.125em, 1.4em, normal, 300);
    text-align: center;
  }
}

.bof_sec3 {
  overflow: hidden;
  .bof_img_container {
    position: relative;
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    .bof_img {
      width: 100%;
      max-width: 100%;
      object-fit: cover;
    }
    .play_button {
      width: 100%;
      max-width: 6.038em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .img_desc {
    @include fontStyle(0.875em, 1.4em, normal, 500);
    text-transform: uppercase;
    padding: 1.071em 0 0 3.143em;
    width: 100%;
    max-width: 57.143em;
  }
}

.bof_sec4 {
  padding-top: 9.3125em;
  .sec4_row {
    margin: 0 auto;
    .sec4_col {
      padding: 0;
    }
    .sec4_col:last-child .img_wrapper {
      margin: 0 auto;
    }
    .img_wrapper {
      width: 100%;
      max-width: 56.25em;
      height: 100%;
      margin: 0 auto 3.4375em;
    }
    .sec4_img {
      width: 100%;
      max-width: 100%;
    }
    .desc {
      @include fontStyle(0.875em, 1.4em, normal, 500);
      text-transform: uppercase;
      padding: 1.071em 0 0;
    }
    .artist {
      @include fontStyle(0.688em, 1.545em, normal, 300);
      text-transform: uppercase;
      padding-top: 0.909em;
    }
  }
}

.bof_sec5 {
  padding-top: 12.5em;
  .img_desc {
    width: 100%;
    max-width: 60.385em;
  }
}

.bof_sec6 {
  padding-top: 10.4375em;
  .sec4_row {
    .desc_wrapper {
      padding-top: 0.9375em;
      width: 100%;
      max-width: 54.3125em;
      .title {
        @include fontStyle(0.688em, 1.545em, normal, 300);
        margin-bottom: 0.5em;
      }
      .desc {
        padding: 0;
      }
    }
  }
}

.bof_sec7 {
  padding-top: 5.0625em;
  .img_desc {
    width: 100%;
    max-width: 60.385em;
  }
}

.bof_sec8 {
  padding: 10.4375em 0 12.5em;
  .sec4_row {
    .desc_wrapper {
      padding-top: 0.9375em;
      width: 100%;
      max-width: 54.3125em;
      .title {
        @include fontStyle(0.688em, 1.545em, normal, 300);
        margin-bottom: 0.5em;
      }
      .desc {
        padding: 0;
      }
    }
  }
}

.bof_fsligthbox {
  .fslightbox-fade-in-strong {
    animation: none;
  }
}

@media (max-width: 992px) {
  .bof_sec1 {
    .bof_banner_image_wrapper {
      height: 75vh;
      .bof_banner_img {
        height: 75vh;
      }
    }
  }
}

@media (max-width: 767px) {
  .bof_sec1 {
    .bof_banner_image_wrapper {
      // height: 54.2rem;
      height: 33.875rem;
      .bof_banner_img {
        width: 100%;
        max-width: 100%;
        height: 33.875rem;
      }
      .bof_banner_content {
        .bof_head {
          color: #fff;
          font-size: 2.5em;
          line-height: 1.2em;
        }
      }
    }
  }

  .bof_sec2 {
    padding: 15em 1.5em 15em 1.1em;
    .desc {
      font-size: 1.6em;
      line-height: 1.35;
      text-align: center;
    }
  }

  .bof_sec3 {
    .img_desc {
      font-size: 1em;
      line-height: 1.35;
      letter-spacing: 0.04px;
      max-width: 100%;
      margin-left: 0;
      padding: 0.8em 2.5em 0 2.6em;
    }
    .bof_img_container {
      .play_button {
        // max-width: 4.2em;
        // height: 4.2em;
        max-width: 4.44em;
        object-fit: contain;
      }
    }
  }

  .bof_sec4 {
    padding-top: 9em;
    .my_container {
      padding: 0 6.9em;
    }
    .sec4_row {
      .sec4_col {
        height: 100%;
      }
      .img_wrapper {
        margin: 0 0 1.6em;
      }
      .desc {
        font-size: 1em;
        line-height: 1.35;
        letter-spacing: 0.04px;
        padding: 0.6em 0 0;
      }
      .artist {
        font-size: 0.7em;
        line-height: normal;
        letter-spacing: 0.35px;
        padding: 0.857143em 0 0;
      }
    }
  }

  .bof_sec5 {
    padding-top: 8em;
    .img_desc {
      padding: 0.8em 2.3em 0 2.4em;
    }
  }

  .bof_sec6 {
    padding-top: 10.368em;
    .sec4_row {
      .img_wrapper {
        .desc_wrapper {
          padding-top: 0.8em;
          .title {
            font-size: 0.7em;
            line-height: normal;
            letter-spacing: 0.35px;
            margin-bottom: 0.857143em;
          }
        }
        .artist {
          padding-top: 1em;
        }
      }
    }
  }

  .bof_sec7 {
    padding-top: 10.232em;

    .image_by {
      padding: 0 0 1em 3.571em;
      font-size: 0.7em;
    }
  }

  .bof_sec8 {
    padding: 10.368em 0 15em;
    .sec4_row {
      .img_wrapper {
        .desc_wrapper {
          padding-top: 0.8em;
          .title {
            font-size: 0.7em;
            line-height: normal;
            letter-spacing: 0.35px;
            margin-bottom: 0.857143em;
          }
        }
        .artist {
          padding-top: 0.857143em;
        }
      }
    }
  }
}
