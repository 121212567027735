@font-face {
    font-family: 'Good Sans';
    src: url('./fonts/GoodSans-Medium.woff2') format('woff2'),
        url('./fonts/GoodSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Good Sans';
    src: url('./fonts/GoodSans-Light.woff2') format('woff2'),
        url('./fonts/GoodSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Good Sans';
    src: url('./fonts/GoodSans-Regular.woff2') format('woff2'),
        url('./fonts/GoodSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Good Sans';
    src: url('./fonts/GoodSans-Thin.woff2') format('woff2'),
        url('./fonts/GoodSans-Thin.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'GT America Mono light';
    src: url('./fonts/GTAmericaMono-Lt.eot');
    src: url('./fonts/GTAmericaMono-Lt.eot?#iefix') format('embedded-opentype'),
        url('./fonts/GTAmericaMono-Lt.woff2') format('woff2'),
        url('./fonts/GTAmericaMono-Lt.woff') format('woff'),
        url('./fonts/GTAmericaMono-Lt.ttf') format('truetype'),
        url('./fonts/GTAmericaMono-Lt.svg#GTAmericaMono-Lt') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America Mono Bold';
    src: url('./fonts/GTAmericaMono-Bold.woff2') format('woff2'),
        url('./fonts/GTAmericaMono-Bold.woff') format('woff'),
        url('./fonts/GTAmericaMono-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America Mono';
    src: url('./fonts/GTAmericaMono-Regular.woff2') format('woff2'),
        url('./fonts/GTAmericaMono-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}