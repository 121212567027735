@import "../../common.scss";

.voting_sec1 {
  background: #BBE5FC;

  .banner_img_wrapper {
    .banner-img {
      object-position: center center !important;
      object-fit: contain;
    }
  }
}

.voting_sec2 {
  .sec1_wrapper {
    padding: 17.875em 0;

    .text_container {
      text-align: center;
      max-width: 43.75em;
      margin: 0 auto;

      .voting_heading {
        @include textStyle(1.875em, 1.2em, 0);
        font-weight: 200;
        margin: 0 0 1em;
      }

      .voting_desc {
        font-weight: 300;
        font-size: 1.125em;
      }
    }
  }
}

.voting_sec3 {
  background-color: $color-C774A0;
  padding: 6.25em 0 2.375em;
  margin-bottom: 0.125em;

  .content_wrapper {
    width: 100%;
    max-width: 56.125em;
    margin: 0 auto 6.25em;

    .voting_heading {
      @include textStyle(3.125em, 1em, -5%);
      color: $color-000000;
      text-align: center;
      font-weight: 200;
      text-transform: uppercase;
    }

    .voting_text {
      @include textStyle(1.125em, 1.4em, 0);
      color: $color-000000;
      text-align: center;
      font-weight: 300;
      width: 100%;
      max-width: 33.333em;
      margin: 1.111em auto 0;
    }
  }

  .imagine_row {
    width: 100%;
    // max-width: 55em;
    margin: 0 auto;
    justify-content: center;

    .imagine_col {
      padding: 0;
      height: auto;
    }

    .imagine_wrapper {
      position: relative;
      padding: 0 1.875em 6.75em;
      height: 100%;

      .img_link {
        position: relative;
        display: block;
      }

      .imagine_img {
        width: 100%;
        max-width: 100%;
        // min-height: 350px;
      }

      .image_content {
        // min-height: 6.5em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .imagine_company {
          @include fontStyle(0.875em, 1.4em, 0, 500);
          color: $color-000000;
          margin: 1.071em 0 0;
          text-transform: uppercase;
        }

        .imagine_name {
          @include textStyle(0.688em, 1.545em, 5%);
          color: $color-000000;
          font-weight: 400;
          margin: 0.455em 0 0;
          text-transform: uppercase;
        }

        .imagine_info {
          position: absolute;
          bottom: 4em;
          color: $color-000000;
          font-family: "GT America Mono";
          font-weight: 400;
          text-transform: uppercase;
          margin: 1.688em 0 0;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
        }

        .imagine_link {
          max-width: fit-content;
          @include textStyle(0.813em, 1.205em, 0.33%);
          border-bottom: 1px solid $color-000000;
          cursor: pointer;

          &:nth-child(2) {
            margin-left: 1.538461538461538em;
          }

          &:hover {
            text-decoration: none;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.voting_sec4 {
  background-color: $color-3172A9;
  padding: 6.25em 0 2.375em;
  margin-bottom: 0.125em;

  .content_wrapper {
    width: 100%;
    max-width: 56.125em;
    margin: 0 auto 6.25em;

    .voting_heading {
      @include textStyle(3.125em, 1em, -5%);
      color: $color-000000;
      text-align: center;
      font-weight: 200;
      text-transform: uppercase;
    }

    .voting_text {
      @include textStyle(1.125em, 1.4em, 0);
      color: $color-000000;
      text-align: center;
      font-weight: 300;
      width: 100%;
      max-width: 33.333em;
      margin: 1.111em auto 0;
    }
  }

  .grow_row {
    width: 100%;
    // max-width: 55em;
    margin: 0 auto;
    justify-content: center;

    .grow_col {
      padding: 0;
      height: auto;
    }

    .grow_wrapper {
      position: relative;
      padding: 0 1.875em 6.75em;
      height: 100%;

      .img_link {
        position: relative;
        display: block;
      }


      .grow_img {
        width: 100%;
        max-width: 100%;
        // min-height: 350px;
        // width: clamp(300px, 300px, 300px);
        // height: clamp(360px, 360px, 360px);
      }

      .image_content {
        // min-height: 6.5em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .grow_company {
          @include fontStyle(0.875em, 1.4em, 0, 500);
          color: $color-000000;
          margin: 1.071em 0 0;
          text-transform: uppercase;
          height: 100%
        }

        .grow_name {
          @include textStyle(0.688em, 1.545em, 5%);
          color: $color-000000;
          font-weight: 400;
          margin: 0.455em 0 0;
          text-transform: uppercase;
        }

        .grow_info {
          position: absolute;
          bottom: 4em;
          color: $color-000000;
          font-family: "GT America Mono";
          font-weight: 400;
          text-transform: uppercase;
          margin: 1.688em 0 0;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
        }

        .grow_link {
          @include textStyle(0.813em, 1.205em, 0.33%);
          border-bottom: 1px solid $color-000000;
          cursor: pointer;

          &:nth-child(2) {
            margin-left: 1.923em;
          }

          &:hover {
            text-decoration: none;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.voting_sec5 {
  background-color: $color-FC9500;
  padding: 6.25em 0 2.375em;

  .content_wrapper {
    width: 100%;
    max-width: 56.125em;
    margin: 0 auto 6.25em;

    .voting_heading {
      @include textStyle(3.125em, 1em, -5%);
      color: $color-000000;
      text-align: center;
      font-weight: 200;
      text-transform: uppercase;
    }

    .voting_text {
      @include textStyle(1.125em, 1.4em, 0);
      color: $color-000000;
      text-align: center;
      font-weight: 300;
      width: 100%;
      max-width: 33.333em;
      margin: 1.111em auto 0;
    }
  }

  .create_row {
    width: 100%;
    // max-width: 55em;
    margin: 0 auto;
    justify-content: center;

    .create_col {
      padding: 0;
      height: auto;
    }

    .create_wrapper {
      position: relative;
      padding: 0 1.875em 6.75em;
      height: 100%;

      .img_link {
        position: relative;
        display: block;
      }

      .create_img {
        width: 100%;
        max-width: 100%;
        // min-height: 350px;
      }

      .image_content {
        // min-height: 6.5em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .create_company {
          @include fontStyle(0.875em, 1.4em, 0, 500);
          color: $color-000000;
          margin: 1.071em 0 0;
          text-transform: uppercase;
        }

        .create_name {
          @include textStyle(0.688em, 1.545em, 5%);
          color: $color-000000;
          font-weight: 400;
          margin: 0.455em 0 0;
          text-transform: uppercase;
        }

        .create_info {
          position: absolute;
          bottom: 4em;
          color: $color-000000;
          font-family: "GT America Mono";
          font-weight: 400;
          text-transform: uppercase;
          margin: 1.688em 0 0;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
        }

        .create_link {
          @include textStyle(0.813em, 1.205em, 0.33%);
          border-bottom: 1px solid $color-000000;
          cursor: pointer;

          &:nth-child(2) {
            margin-left: 1.538461538461538em;
          }

          &:hover {
            text-decoration: none;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.voting_ligibox_wrapper {
  .fslightbox-fade-in-strong {
    animation: none;
  }
}

@media screen and (min-width:1700px) {
  .voting_container {
    max-width: 1350px !important;
  }
}

@media screen and (max-width: 992px) {
  .voting_sec2 {
    .sec1_wrapper {
      .text_container {
        max-width: 80%;

        .voting_desc {
          font-size: 1.5em;

          br {
            display: none;
          }
        }
      }
    }
  }


  .voting_sec3 {
    .content_wrapper {
      .voting_heading {
        font-size: 2.188rem;
      }

      .voting_text {
        font-size: 1rem;
      }
    }

    .imagine_row {
      max-width: 100%;

      .imagine_wrapper {
        padding: 0 1.25em 9.5em;

        .image_content {
          // min-height: 6.5em;

          .imagine_company {
            @include textStyle(1.4em, 1.4em, 0);
            margin: 1.071em 0 0;
          }

          .imagine_name {
            @include textStyle(1.1em, 1.545em, 5%);
            margin: 0.455em 0 0;
          }

          .imagine_info {
            margin: 1.5em 0 0;
          }

          .imagine_link,
          .vote_cta {
            @include textStyle(1.1em, 1.205em, 0.33%);
          }
        }
      }
    }
  }

  .voting_sec4 {
    .content_wrapper {
      .voting_heading {
        font-size: 2.188rem;
      }

      .voting_text {
        font-size: 1rem;
      }
    }

    .grow_row {
      max-width: 100%;

      .grow_wrapper {
        padding: 0 1.25em 9.5em;

        .image_content {
          // min-height: 6.5em;

          .grow_company {
            @include textStyle(1.4em, 1.4em, 0);
            margin: 1.071em 0 0;
          }

          .grow_name {
            @include textStyle(1.1em, 1.545em, 5%);
            margin: 0.455em 0 0;
          }

          .grow_info {
            margin: 1.5em 0 0;
          }

          .grow_link,
          .vote_cta {
            @include textStyle(1.1em, 1.205em, 0.33%);
          }
        }
      }
    }
  }


  .voting_sec5 {
    .content_wrapper {
      .voting_heading {
        font-size: 2.188rem;
      }

      .voting_text {
        font-size: 1rem;
      }
    }

    .create_row {
      max-width: 100%;

      .create_wrapper {
        padding: 0 1.25em 9.5em;

        .image_content {
          // min-height: 6.5em;

          .create_company {
            @include textStyle(1.4em, 1.4em, 0);
            margin: 1.071em 0 0;
          }

          .create_name {
            @include textStyle(1.1em, 1.545em, 5%);
            margin: 0.455em 0 0;
          }

          .create_info {
            margin: 1.5em 0 0;
          }

          .create_link,
          .vote_cta {
            @include textStyle(1.1em, 1.205em, 0.33%);
          }
        }
      }
    }
  }

  .voting_sec6 {
    .content_wrapper {
      .voting_heading {
        font-size: 2.188rem;
      }

      .voting_text {
        font-size: 1rem;
      }
    }

    .create_row {
      max-width: 58em;

      .create_name {
        font-size: 1rem;
      }

      .create_desg {
        font-size: 0.75rem;
      }

      .create_info {
        font-size: 0.875rem;
      }
    }
  }
}

@media screen and (max-width: 600px) {

  .voting_sec2 {
    .sec1_wrapper {
      padding: 15em 0;

      .text_container {
        max-width: 100%;
      }
    }
  }

  .voting_sec3 {
    padding: 9.6em 0 4.7em;

    .content_wrapper {
      padding-right: 0.2em;
      margin: 0 auto 8em;

      .voting_heading {
        font-size: 2.4em;
        line-height: 1.1;
      }

      .voting_text {
        font-size: 1.6em;
        line-height: 1.4;
        margin: 0.5em auto 0;
      }
    }

    .imagine_row {
      max-width: 100%;

      .imagine_wrapper {
        padding: 0 42px 5em 41px;

        .image_content {
          min-height: auto;

          .imagine_info {
            position: relative;
            bottom: auto;
            justify-content: center;
            position: relative;
            bottom: auto
          }

          .imagine_link,
          .vote_cta {
            @include textStyle(1.3em, 1.205em, 0.33%);

            &:nth-child(2) {
              margin: 0 1.538em;
            }
          }
        }
      }
    }
  }

  .voting_sec4 {
    padding: 9.6em 0 4.7em;
    margin-bottom: 0.2em;

    .content_wrapper {
      padding-right: 0.2em;
      margin: 0 auto 8em;

      .voting_heading {
        font-size: 2.4em;
        line-height: 1.1;
      }

      .voting_text {
        font-size: 1.6em;
        // line-height: 1.55;
        line-height: 1.4;
        // margin: 1.333333333333333em auto 0;
        margin: 0.5em auto 0;
      }
    }

    .grow_row {
      max-width: 100%;

      .grow_wrapper {
        padding: 0 42px 5em 41px;

        .image_content {
          min-height: auto;

          .grow_info {
            position: relative;
            bottom: auto;
            justify-content: center
          }

          .grow_link,
          .vote_cta {
            @include textStyle(1.3em, 1.205em, 0.33%);

            &:nth-child(2) {
              margin: 0 1.538em;
            }
          }
        }
      }
    }
  }

  .voting_sec5 {
    padding: 9.6em 0 4.7em;

    .content_wrapper {
      padding-right: 0.2em;
      margin: 0 auto 8em;

      .voting_heading {
        font-size: 2em;
        line-height: 1.35;
      }

      .voting_text {
        font-size: 1.5em;
        line-height: 1.55;
        margin: 1.333333333333333em auto 0;
      }
    }

    .create_row {
      max-width: 100%;

      .create_wrapper {
        padding: 0 42px 5em 41px;

        .image_content {
          min-height: auto;


          .create_info {
            position: relative;
            bottom: auto;
            justify-content: center
          }

          .create_link,
          .vote_cta {
            @include textStyle(1.3em, 1.205em, 0.33%);

            &:nth-child(2) {
              margin: 0 1.538em;
            }
          }
        }
      }
    }
  }
}