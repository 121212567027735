@import "../../common.scss";
.login_sec1 {
  .row {
    align-items: stretch;
  }
  .login_img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    transition: all 0.3s;
  }
  .form_container {
    width: 100%;
    height: 100%;
    max-width: 32.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    padding: 18.313em 2.5em 16.406em;
    .section_subheading {
      text-transform: uppercase;
      margin: 0 auto 1.833em;
      max-width: fit-content;
    }
    .checkbox_wrapper {
      position: relative;
      margin: 0 0 3.125em;
      display: flex;
      justify-content: space-between;
      .forgot_pass {
        font-family: "GT America Mono";
        @include fontStyle(0.813em, normal, 0.043px, 400);
        text-transform: uppercase;
        display: flex;
        align-items: center;
        a {
          color: $color-000000;
        }
      }
    }
    .btn_wrapper {
      .submit_btn {
        background: transparent;
        width: 100%;
        margin: 0 auto 2.5em;
      }
    }
    .register_text {
      font-family: "GT America Mono";
      @include fontStyle(0.813em, normal, 0.043px, 400);
      text-transform: uppercase;
      text-align: center;
      a {
        text-decoration: underline;
        color: $color-000000;
      }
    }
  }
}

.otp_sec {
  padding: 11.938em 0;
  .content_wrapper {
    width: 100%;
    max-width: 40.25em;
    margin: auto;
  }
  .otp_input_wrapper {
    justify-content: center;
    margin-top: 3.313em;
    div {
      &:first-child {
        .otp_input {
          margin-left: 0;
        }
      }
    }
  }
  .otp_input {
    background-color: transparent;
    border: 1.5px solid $color-000000;
    font-size: 1.875em;
    width: 3.17em !important;
    height: 3.17em;
    display: inline-block;
    border-radius: 0.333em;
    margin-left: 1.833em;
    &:focus {
      outline: 0px dotted;
      outline: 0px auto -webkit-focus-ring-color;
    }
  }
  .otp_focused {
    border: 2px solid $color-000000;
    outline: "none";
  }
  .resend_otp_text {
    font-family: "GT America Mono";
    font-size: 0.813rem;
    text-align: center;
    margin-top: 1.1em;
    cursor: pointer;
  }
  .otp_inbox_msg {
    font-size: 1.25em;
    margin: 1em 0 0;
    text-align: center;
  }
  .inbox_notice_msg {
    font-size: 1em;
    margin: 1.5em 0 0;
    text-align: center;
    a {
      color: $color-000000;
    }
  }
  .otp_sent_text {
    margin-top: 0.7em;
    font-family: "GT America Mono";
    font-size: 0.813rem;
    text-align: center;
  }
  .otp_verify_btn {
    margin: 2.5em auto 0;
    background: transparent;
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
  }
}

@media (min-width: 1800px) {
  .signup_sec1,
  .otp_sec {
    min-height: 80vh;
    display: flex;
  }
}

@media (max-width: 991px) {
  .login_sec1 {
    .form_container {
      max-width: 40em;
      padding: 14.7em 2.5em 9.8em;
      .section_subheading {
        font-size: 2.5em;
      }
      .checkbox_wrapper {
        .forgot_pass {
          font-size: 0.9em;
        }
      }
      .register_text {
        font-size: 0.9em;
      }
    }
  }

  .otp_sec {
    .content_wrapper {
      .section_heading {
        font-size: 2.188rem;
      }
      .otp_inbox_msg {
        font-size: 1.125rem;
      }
      .inbox_notice_msg {
        font-size: 1rem;
      }
      .resend_otp_text,
      .otp_sent_text {
        font-size: 0.875rem;
      }
      .otp_input {
        width: 2.833em !important;
        height: 2.5em;
      }
    }
  }
}

@media (max-width: 600px) {
  .login_sec1 {
    .form_container {
      .section_subheading {
        margin: 0 auto 1.48em;
      }
    }
  }
  .otp_sec {
    padding: 14.7em 2.7em 12.3em 2.4em;
    .otp_input {
      margin-left: 0.7em;
    }
    .content_wrapper {
      .section_heading {
        font-size: 1.25rem;
      }
      .otp_inbox_msg {
        font-size: 1rem;
      }
      .inbox_notice_msg {
        font-size: 0.938rem;
      }
      .resend_otp_text,
      .otp_sent_text {
        font-size: 0.813rem;
      }
    }
    .otp_verify_btn {
      max-width: 15rem;
    }
  }
}
