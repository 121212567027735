@import "../../common.scss";

.login_sec1 {
  .checkbox_input_wrapper {
    margin: 0 2em 0 0;
    display: block;
    text-transform: uppercase;
    .checkox_input {
      display: none;
    }
    .checkbox_label {
      color: $color-000000;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      width: fit-content;
      margin: 0;
    }
    .checkbox_outer {
      width: 1.125em;
      height: 1.125em;
      border-radius: 50%;
      border: 1.5px solid $color-000000;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .checkbox_right_tick {
      display: inline-block;
      width: 0.625em;
      height: 0.625em;
      border-radius: 50%;
    }
    .label_text {
      font-family: "GT America Mono";
      @include fontStyle(0.813em, normal, 0.043px, 400);
      padding: 0 0 0 1.077em;
    }
    .checkox_input:checked ~ .checkbox_label .checkbox_right_tick {
      transition: all 0.5s;
      background-color: $color-000000;
    }
  }
}

@media (max-width: 991px) {
  .login_sec1 {
    .checkbox_input_wrapper {
      .checkbox_outer {
        width: 1.25em;
        height: 1.25em;
      }
      .checkbox_right_tick {
        width: 0.75em;
        height: 0.75em;
      }
      .label_text {
        font-size: 0.9em;
      }
    }
  }
}

@media (max-width: 600px) {
  .login_sec1 {
    .checkbox_input_wrapper {
      .checkbox_outer {
        width: 18px;
        height: 18px;
      }
      .checkbox_right_tick {
        width: 10px;
        height: 10px;
      }
    }
  }
}
