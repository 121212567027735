@import "../../common.scss";

@mixin judges_desg_company {
  font-family: "GT America Mono", Good Sans;
  font-size: 0.813em;
  font-weight: 400;
  line-height: 1.375rem;
}

@mixin mbjudges_desg_company {
  font-size: 1.1em;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: -0.6000000238418579px;
}

.judges {
  .judges_container {
    max-width: 1130px;
  }
  .my_container {
    width: 100%;
    // max-width: 100%;
    // padding: 0 8.438em;
    margin: 0 auto;
  }
}

.judges_sec1 {
  border: 1px solid rgba($color: $color-BCBCBC, $alpha: 0.5);
  .text_container {
    padding: 17.625em 0;
    text-align: center;
    .judges_desc {
      padding: 1.111em 0 0;
      font-weight: 300;
    }
  }
}

.judges_sec2 {
  padding: 5.438em 0 1.875em;
  .judges_row {
    .judges_col {
      position: relative;
      color: $color-000000;
      padding: 0 1.25em 6.875em;
      .judges_inner_body {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .judges_image {
        width: 100%;
        max-width: 100%;
        margin: 0 0 0.625em;
      }
      .judges_details {
        letter-spacing: 0;
        height: 100%;
        min-height: 6.722em;
        padding: 0 0 0.938em;
        .judges_name {
          @include fontStyle(0.875em, 1.4em, normal, 500);
          text-transform: uppercase;
        }
        .judges_desg {
          @include fontStyle(0.688em, 1.545em, 0.44px, 300);
          padding: 0.45em 0 0;
          text-transform: uppercase;
        }
        .judges_company {
          @include fontStyle(0.688em, 1.545em, 0.44px, 300);
          text-transform: uppercase;
        }
      }
      .read_more_cta {
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s;
        padding: 0;
        .read_more {
          @include fontStyle(0.813em, normal, 0.043px, 400);
          text-transform: uppercase;
          padding: 0 0.625em 0 0;
          color: $color-000000;
          font-family: $GT;
        }
        .plus_icon {
          width: 100%;
          height: 100%;
          max-width: 0.625em;
          max-height: 0.625em;
          // opacity: 0.3;
        }
        &:hover {
          opacity: 0.3;
        }
        &.active {
          opacity: 0.3;
          .plus_icon {
            transform: rotate(-45deg);
          }
        }
      }
    }
    .judges_desc {
      @include fontStyle(0.938em, 1.333em, normal, 300);
      color: $color-000000;
      height: 0;
      overflow: hidden;
      text-align: left;
      transition: height 0.5s ease-in;
    }
    .judges_desc.active {
      // display: block;
      margin: 3em 0 0;
      height: auto;
      transition: height 0.5s ease-in;
      animation: dropdown 0.5s ease-in;
    }
  }
}

@media (max-width: 1194px) {
  .judges {
    .my_container {
      max-width: 1031px;
      padding: 0 15px;
    }
  }
}

@media (max-width: 992px) {
  .judges_sec2 {
    .judges_row {
      .judges_col {
        .judges_details {
          min-height: 9.913em;
          padding: 0 0 1.5em;
          .judges_name {
            font-size: 1.4em;
          }
          .judges_desg {
            font-size: 1.1em;
            line-height: 17px;
            max-width: 90%;
            padding: 0.364em 0 0;
          }
          .judges_company {
            font-size: 1.1em;
            line-height: 17px;
          }
        }
        .read_more_cta {
          max-width: 100%;
          .read_more {
            font-size: 1.3em;
            color: $color-000000;
          }
          .plus_icon {
            opacity: 1;
            max-width: 1.1em;
            max-height: 1.1em;
          }
          &:hover,
          &.active {
            opacity: 1;
          }
        }
        .judges_desc {
          font-size: 1.3em;
        }
      }
    }
  }
}

@media (max-width: 835px) {
  .judges_sec2 {
    .judges_row {
      .judges_col {
        width: 100%;
      }
    }
  }
}

@media (max-width: 600px) {
  .judges {
    .my_container {
      padding: 0 67px 0 68px;
    }
  }
  .judges_sec1 {
    .text_container {
      padding: 15em 0;
    }
  }
  .judges_sec2 {
    padding: 7.5em 0 10em;
    .judges_row {
      .judges_col {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 0 0 5em;
        .judges_details {
          min-height: auto;
          max-height: auto;
        }
        .judges_image {
          margin: 0 0 0.8em;
        }
        .judges_desc {
          font-size: 1.3em;
          font-weight: 400;
          line-height: 1.375rem;
          letter-spacing: 0;
          // margin: 2em 0 6em;
        }
      }
    }
  }
}

@keyframes dropdown {
  0% {
    opacity: 0;
    height: 0;
  }
  25% {
    height: 25%;
  }
  50% {
    height: 50%;
  }
  75% {
    height: 75%;
  }

  100% {
    opacity: 1;
    height: 100%;
    overflow: visible;
  }
}
