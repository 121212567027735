@import "../../common.scss";
.registerformsec {
  padding: 16.563em 0 11.563em;
  min-height: 80vh;

  .my_container {
    max-width: calc(907px + 5em);
    padding: 0 2.5em;
  }

  .calender_wrapper {
    width: 100%;
    color: $color-000000;
    border: 1.5px solid $color-000000;
    border-radius: 4.875em;
    padding-top: 1.375em;
    padding-bottom: 1.375em;
    padding-left: 3.125em;
    cursor: pointer;
    .react-date-picker__calendar-button {
      margin-right: 1.5em;
    }
    .react-date-picker__wrapper {
      border: none;
    }
    .react-date-picker__inputGroup__input {
      color: $color-000000;
      &::-webkit-input-placeholder {
        /* Edge */
        color: $color-000000;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $color-000000;
      }
      &::placeholder {
        color: $color-000000;
      }
    }
  }

  .btn_wrapper {
    display: flex;
    margin-top: 1.25em;
    .project-button {
      margin-top: 0;
      margin-right: 1.5em;
    }
  }
  .btn_wrapper_margin {
    margin-top: 4.547em;
  }

  .form_field_wrapper.form_field_wrapper_margin {
    margin-bottom: 1em;
  }

  .form_field_wrapper.form_field_wrapper_margin_zero {
    margin-bottom: 0;
  }

  .word_break {
    white-space: nowrap;
    max-width: 100%;
  }

  .form_field_wrapper.form_field_wrapper_full_width.full_text {
    margin-bottom: 1.438em;
  }

  .registerform_heading {
    line-height: 1em;
  }

  .form_flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .logout_text {
    @include fontStyle(0.75em, normal, 0.04px, 400);
    white-space: nowrap;
    font-family: "GT America Mono";
    text-transform: uppercase;
    margin: 2em 0 0;
  }

  .form_field_wrapper {
    position: relative;
    width: 100%;
    max-width: 49%;
    margin-bottom: 1.375em;
    &:nth-child(n + 2) {
      margin-top: 0;
    }
    .inputdownarrow {
      width: 100%;
      max-width: 1.125em;
      position: absolute;
      top: 1.4rem;
      right: 1.5rem;
      pointer-events: none;
    }
    .form_input {
      padding: 17px 6rem 16px 30px;
      border-radius: 4.875em;
    }
    .textarea_input {
      padding: 17px 2rem 16px 30px;
      &::-webkit-scrollbar {
        right: 3rem !important;
        border-radius: 1em;
        width: 4px;
        background-color: $color-000000;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $color-000000;
        border-radius: 1em;
      }
    }
    &:last-child {
      margin: 0;
    }
    &.first_question_box {
      margin-top: 1.375em;
    }
    &.question_box {
      margin-bottom: 2.75em;
    }
  }
  .form_with_icon {
    display: flex;
    .form_input {
      padding: 17px 6rem 16px 3.5rem;
    }
    .sociallogoimg {
      display: block;
      position: relative;
    }
  }
  .form_field_wrapper.form_field_wrapper_full_width {
    max-width: 100%;
  }

  .select_box_wrapper {
    .select-placeholder-text {
      font-weight: normal;
    }
    .select_box_el__value-container {
      padding: 17px 6rem 16px 30px;
    }
    .select_box_el__control {
      background-color: transparent;
      // min-height: 4.375em;
      border-radius: 4.875em;
      cursor: pointer;
      border-color: $color-000000;
      border-width: 1.5px;
      outline: none;
      box-shadow: none;
      // box-shadow: 0 0 0 1px $color-FFFFFF;
      &:hover {
        border-color: $color-000000;
      }
    }
    .select_box_el__placeholder {
      color: $color-000000;
      font-size: 0.813em;
      line-height: normal;
      font-weight: 400;
      letter-spacing: 0.043px;
    }
    .select_box_el__indicator-separator {
      display: none;
    }
    .select_box_el__indicator {
      display: none;
    }
    .select_box_el__single-value {
      color: $color-000000;
      margin-right: 3em;
      font-size: 0.813em;
      line-height: normal;
      font-weight: 400;
      letter-spacing: 0.043px;
    }
    .select_box_el__input-container {
      color: $color-000000;
      font-size: 0.813em;
      line-height: normal;
      font-weight: 400;
      letter-spacing: 0.043px;
      margin: 0;
      padding: 0;
    }
    .select_box_el__menu {
      div {
        cursor: pointer;
      }
    }
  }

  .textarea_input {
    border-radius: 1em !important;
    // padding-top: 1em;
    // padding-bottom: 3em;
    min-height: 9.5em;
    resize: none;
    overflow-x: hidden;
    padding: 17px 2rem 16px 30px;
    &::-webkit-scrollbar {
      width: 8px;
      background-color: #545353;
      border-top-right-radius: 5em;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-FFFFFF;
    }
  }

  .radio_row_flex {
    display: flex;
    // gap: 1.438rem 0;
    flex-wrap: wrap;
    margin: 1.625em 0 -1.688em 0;
  }
  .form_data_wrapper {
    display: flex;
    flex-direction: row;
    .form_input {
      margin-right: 1em;
      padding-left: 1.5em;
      padding-right: 0.5em;
    }
  }
  .radio_input_wrapper {
    // &:nth-child(n + 2) {
    //   margin-top: 1.5em;
    //   margin-left: 1em;
    // }
    // margin-bottom: 1.438rem;
    margin-right: 2.813em;
    .radio_input {
      display: none;
    }

    .radio_label {
      color: $color-000000;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      width: fit-content;
      margin-bottom: 0.25rem;
    }

    .label_text {
      font-size: 0.813rem;
      line-height: 22px;
      padding-left: 1em;
      text-transform: uppercase;
      font-family: "GT America Mono";
    }
    .radio_border {
      width: 1.125em;
      height: 1.125em;
      display: inline-block;
      outline: solid 2px $color-000000;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .radio_fill {
      display: inline-block;
      width: 0.625em;
      height: 0.625em;
      border-radius: 50%;
    }
    .radio_input:checked ~ .radio_label .radio_fill {
      transition: all 0.5s;
      background-color: $color-000000;
    }
  }

  .contact_no_flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .contact_left {
      width: 100%;
      max-width: 30%;
      position: relative;
      .select_box_el__value-container {
        padding: 17px 0.5em 16px 0.5em;
      }
      // .select_box_el__control {
      //   min-height: 4.9em;
      // }
      .select_box_el__placeholder,
      .select_box_el__input-container,
      .select_box_el__single-value {
        margin-left: 1.3em;
        padding: 0;
        margin-top: 0;
      }
      .select_box_el__single-value {
        margin-right: 2em;
      }

      .inputdownarrow {
        top: 1.5rem;
        right: 1.5rem;
      }
    }
    .contact_right {
      width: 100%;
      max-width: 67%;
    }
  }
  .goback_text {
    text-transform: uppercase;
    text-align: center;
    margin-top: 1em;
    span {
      cursor: pointer;
    }
  }

  .checkbox_input_wrapper {
    margin-right: 2.813em;
    padding-bottom: 1.688em;
    .checkox_input {
      display: none;
    }
    .checkbox_label {
      color: $color-000000;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      width: fit-content;
      margin-bottom: 0;
      text-transform: uppercase;
    }
    .checkbox_outer {
      width: 1.125rem;
      height: 1.125rem;
      border-radius: 50%;
      border: 1px solid $color-000000;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .checkbox_right_tick {
      display: inline-block;
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 50%;
    }
    .label_text {
      font-size: 0.813rem;
      padding-left: 1em;
      font-family: "GT America Mono";
      line-height: 22px;
    }
    // .checkox_input:checked ~ .checkbox_label .checkbox_outer {
    //   transition: all 0.5s;
    //   background-color: $color-000000;
    // }
    .checkox_input:checked ~ .checkbox_label .checkbox_right_tick {
      transition: all 0.5s;
      background-color: $color-000000;
    }
  }

  .confirmation_wrapper {
    // margin-top: 1.5em;
    margin-right: 2em;
    // margin-bottom: 1.438rem;
    .confirmation_input {
      display: none;
    }
    .confirmation_label {
      color: $color-000000;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      cursor: pointer;
      width: fit-content;
      margin-bottom: 1.438em;
      margin-top: 2.5rem;
      .confirmation_outer {
        margin-top: 0.15rem;
        width: 1.125rem;
      }
    }
    .confirmation_outer {
      width: 1.125rem;
      height: 1.125rem;
      border-radius: 50%;
      border: 1px solid $color-000000;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .confirmation_right_tick {
      display: inline-block;
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 50%;
    }
    .confirmation_label_text {
      font-size: 1rem;
      padding-left: 1em;
      line-height: 22px;
    }
    .confirmation_input:checked ~ .confirmation_label .confirmation_right_tick {
      transition: all 0.5s;
      background-color: $color-000000;
    }
  }

  .file_upload_form_field {
    .adjust_width {
      width: 100%;
      max-width: 85%;
      margin-bottom: 1.438em;
    }
    .select_wrapper {
      .select_flex {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .select_left {
        width: 100%;
        max-width: 50%;
      }
      .select_right {
        width: 100%;
        max-width: 50%;
        padding-left: 1.813em;
      }
      .upload_label {
        display: block;
        width: 100%;
        appearance: none;
        -webkit-appearance: none;
        background-color: transparent !important;
        font-size: 0.813em;
        line-height: normal;
        font-weight: 400;
        letter-spacing: 0.043px;
        color: $color-000000;
        font-family: "GT America Mono";
        padding: 1.308em 2.308em;
        border: 1.5px solid $color-000000;
        border-radius: 4.875em;
        outline: none;
        text-align: center;
        text-transform: uppercase;
      }
    }
    .video_upload {
      margin-bottom: 1.438em;
    }
  }
  .video_upload {
    margin-bottom: 1.438em;
  }

  .section_desc,
  .section_subdesc,
  .link_btn {
    color: $color-000000;
    font-weight: 300;
  }
  .adjusted_margin {
    margin-bottom: 0.938rem;
  }
  .section_subdesc {
    font-size: 1.125em;
  }
  .section_subdesc.extended {
    margin-bottom: 1.222em;
  }

  // end of common css

  // step 2 css
  .form_wrapper_step2 {
    .sociallogoimg {
      width: 100%;
      max-width: 1.188rem;
      position: absolute;
      top: 32.5%;
      left: 1.5em;
    }
    .fblogo {
      max-width: 0.8rem;
      top: 30%;
    }
    .snaplogo {
      max-width: 1.5rem;
      top: 30%;
    }
    .yt_icon {
      top: 37.5%;
    }
  }
  // end of step 2 css
}

.yes_color {
  background-color: $color-000000;
}

.no_color {
  background-color: #a06d4d;
}

.rsw_2f {
  display: none;
}
.rsw_2f.rsw_3G {
  display: block;
}
.rsw_2f.rsw_3G.formChangeAnimation {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-name: enterRight;
}
// .enterRight {
// }
@keyframes enterRight {
  from {
    display: none;
    opacity: 0;
  }

  to {
    display: block;
    opacity: 1;
  }
}

@media (max-width: 991px) {
  .registerformsec {
    padding: 14.7em 0 8.8em;
    .file_upload_form_field {
      .adjust_width {
        max-width: 100%;
      }
      .select_wrapper {
        .upload_label {
          font-size: 1.3em;
          letter-spacing: 0.036px;
        }
      }
    }
    .registerform_heading {
      text-align: center;
    }
    .col2 {
      padding-left: 0;
      border-left: 0;
      border-top: 1px solid $color-000000;
      margin-top: 4.9em;
      padding-top: 4.9em;
    }
    .form_with_icon {
      .sociallogoimg {
        max-width: 1.5em;
        max-height: 1.5em;
        object-fit: contain;
        top: 1.5em;
      }
      .fblogo {
        top: 1.5em;
      }
    }
    .radio_row_flex {
      flex-direction: column;
      margin-top: 2em;
    }
    .radio_input_wrapper {
      .radio_border {
        width: 1.5em;
        height: 1.5em;
      }
      .radio_fill {
        width: 1em;
        height: 1em;
      }
    }
    .form_field_wrapper {
      .form_input {
        font-size: 1.125em;
      }
    }
    .select_box_wrapper {
      .select_box_el__input-container {
        font-size: 1.125em;
        line-height: 1.7em;
      }
      .select_box_el__placeholder {
        font-size: 1.125em;
        line-height: 1.7em;
      }
    }
    // .project-button {
    //   background-color: transparent;
    //   &::before,
    //   &::after {
    //     display: none;
    //   }
    //   &:hover {
    //     color: $color-000000;
    //   }
    // }
    .project-button {
      font-size: 0.75rem;
    }
    .checkbox_input_wrapper {
      .checkbox_outer {
        width: 1.5rem;
        height: 1.5rem;
      }
      .checkbox_right_tick {
        width: 1rem;
        height: 1rem;
      }
    }
    .confirmation_wrapper {
      .confirmation_label {
        .confirmation_outer {
          margin-top: 0.05rem;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
      .confirmation_right_tick {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

@media (max-width: 767px) {
  .registerformsec {
    .file_upload_form_field {
      .select_wrapper {
        .select_flex {
          flex-direction: column;
        }
        .select_left,
        .select_right {
          max-width: 100%;
          text-align: center;
        }
        .select_right {
          padding-top: 0.5em;
          padding-left: 0;
        }
      }
      .message_text {
        font-size: 1.4em;
      }
    }
    .word_break {
      white-space: initial;
    }
    .confirmation_wrapper {
      .confirmation_label_text {
        font-size: 0.938rem;
      }
    }
    .goback_text {
      font-size: 1.4em;
    }
    .form_field_wrapper {
      max-width: 100%;
      margin-bottom: 1.5em;
      &.first_question_box {
        margin-top: 2.5em;
      }
      &.question_box {
        margin-bottom: 4em;
      }
    }

    .logout_text {
      white-space: initial;
      font-size: 1.3em;
    }
    // .project-button {
    //   margin-top: 0;
    //   border: none;
    //   &::after,
    //   &::before {
    //     height: 51%;
    //   }
    // }
  }
}

@media (max-width: 600px) {
  .registerformsec {
    .form_with_icon {
      .error_message {
        display: none;
      }
    }
    .my_container {
      padding: 0 2.4em 0 2.7em;
    }
    .form_field_wrapper {
      .form_input {
        font-size: 13px;
        line-height: 18px;
        padding: 17px 32px 16px 30px;
      }
      .btn_wrapper {
        flex-direction: column;
        align-items: center;
        .project-button {
          margin: 1em 0 0;
          &:first-child {
            margin: 0;
          }
        }
        // .project-button {
        //   background-color: transparent;
        //   &::before,
        //   &::after {
        //     display: none;
        //   }
        // }
      }
    }
    .form_with_icon .form_input {
      padding: 17px 32px 16px 3.5rem;
    }
    .select_box_wrapper {
      .select_box_el__control {
        border-radius: 4.875em;
      }
      .select_box_el__placeholder {
        font-size: 11px;
        line-height: 18px;
      }
      .select_box_el__single-value {
        font-size: 11px;
        line-height: 18px;
      }
      .select_box_el__input-container {
        font-size: 11px;
        line-height: 18px;
      }
      .select_box_el__value-container {
        padding: 17px 3rem 16px 30px;
      }
      .inputdownarrow {
        right: 1rem;
      }
    }
    .checkbox_input_wrapper {
      // margin-bottom: 2.7em;
      // &:last-child {
      //   margin-bottom: 0;
      // }
    }
    .radio_input_wrapper {
      margin-bottom: 2.7em;
      .radio_border {
        width: 18px;
        height: 18px;
      }
      .radio_input:checked ~ .radio_label .radio_fill {
        width: 10px;
        height: 10px;
      }
    }
    .contact_no_flex {
      .contact_left {
        .select_box_el__placeholder,
        .select_box_el__input-container,
        .select_box_el__single-value {
          margin-bottom: 0;
        }
        .inputdownarrow {
          right: 1rem;
        }
      }
    }
    .form_with_icon {
      .sociallogoimg {
        max-width: 2em;
        max-height: 2em;
        object-fit: contain;
        top: 1.75em;
      }
      .yt_icon {
        top: 1.3rem;
      }
    }
    .checkbox_input_wrapper {
      .checkbox_outer {
        width: 18px;
        height: 18px;
      }
      .checkbox_right_tick {
        width: 10px;
        height: 10px;
      }
    }
    .confirmation_wrapper {
      .confirmation_label {
        .confirmation_outer {
          margin-top: 0.5rem;
          width: 18px;
          height: 16px;
        }
        .confirmation_right_tick {
          width: 10px;
          height: 10px;
        }
      }
      .confirmation_right_tick {
        width: 10px;
        height: 10px;
      }
      .confirmation_label_text {
        max-width: 95%;
      }
    }
    .section_subdesc {
      font-size: 1.5em;
    }
    .section_subdesc.extended {
      margin-bottom: 1.333em;
    }
    .logout_text {
      line-height: 22px;
    }
    .btn_wrapper {
      .project-button {
        max-width: 240px;
      }
    }
    .btn_wrapper_margin {
      margin: 4em 0 0;
    }
    .mobile_space {
      margin-bottom: 40px;
    }
    .adjusted_margin {
      margin-bottom: 30px;
    }
  }
}

.logout-toast {
  position: absolute;
  width: 100%;
  min-width: 35em;
  max-width: 35em;
}

@media (min-width: 1800px) {
  .logout-toast {
    left: -25em;
  }
}
@media (max-width: 1799px) {
  .logout-toast {
    left: -15em;
  }
}
@media (max-width: 1299px) {
  .logout-toast {
    left: -11em;
  }
}
@media (max-width: 767px) {
  .logout-toast {
    position: relative;
    left: 0;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}
