@import "../../common.scss";

.bannersec {
  position: relative;
  background-color: $color-FFFFFF;
  .split_banner {
    color: $color-FFFFFF;
    .split_sec01 {
      position: relative;
      .left_img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        width: 100%;
        height: 100vh;
        z-index: -1;
      }
    }

    .split_sec02 {
      .right_img {
        width: 100%;
        height: 100vh;
      }
    }
  }
  .grid_banner {
    width: 100%;
    color: $color-FFFFFF;
    display: flex;
    .split_sec01 {
      width: 100%;
      position: relative;
      .left_img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        width: 100%;
        height: 100vh;
        z-index: -1;
      }
    }

    .split_sec02 {
      width: 100%;
      background: #018bca;
      max-width: 40%;
      .grid_container {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        .grid_item {
          height: 100%;
          .judge_banner_img {
            width: 100%;
            height: 100%;
            max-width: 100%;
          }
        }
      }
      .right_img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        // object-position: center right;
        max-height: 100vh;
      }
    }
  }
  .full_img_banner {
    color: $color-FFFFFF;
    .fullsize_bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      width: 100%;
      height: 100vh;
      z-index: -1;
    }
    .video_banner {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      height: 100vh;
      object-fit: cover;
      object-position: right;
      z-index: -1;
      .video_source {
        width: 100%;
        max-width: 100%;
        height: 100%;
        min-height: 100vh;
        max-height: 100%;
      }
    }
  }

  .swiper-pagination-bullet {
    display: block;
    background: rgba($color: $color-FFFFFF, $alpha: 1);
    width: 6px;
    height: 6px;
  }
  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 2px;
    overflow: hidden;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    width: initial;
    top: 0;
    right: 1.125em;
    bottom: 0;
    left: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.home_sec2 {
  position: relative;
  padding: 0;
  background-color: $color-FFFFFF;
  .sec2_bg_img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  .my_container {
    max-width: 100%;
    padding: 0 2.5em;
  }
  .text_container {
    position: relative;
    padding: 15.5em 0 13.563em;
    text-align: left;
    color: $color-FFFFFF;
    z-index: 1;
    .section_heading {
      width: 100%;
      text-transform: uppercase;
      color: $color-FFFFFF;
      max-width: 11em;
    }
    .section_desc {
      max-width: 30em;
      margin: 1.111em 0 0;
      color: $color-FFFFFF;
    }
  }
}

.home_sec3 {
  padding: 0;
  background-color: $color-FFFFFF;
  .my_container {
    max-width: 100%;
    padding: 0;
  }
  .row {
    align-items: stretch;
    cursor: pointer;
  }
  .text_container {
    padding: 6.25em 0;
    text-align: left;
    max-width: 32.438em;
    margin: 0 0 0 3.125em;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    .quote_text {
      @include fontStyle(1.125em, 1.4em, normal, 300);
      margin: 0 0 1.111em;
    }
    .name {
      @include fontStyle(0.875em, 1.4em, normal, 500);
      text-transform: uppercase;
    }
    .title {
      @include fontStyle(0.688em, 1.545em, 0.44px, 300);
      text-transform: uppercase;
      margin: 5px 0 0;
    }
  }
  .quote_potrait {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 51.188em;
    object-fit: cover;
    object-position: top;
  }
  .swiper-pagination > .swiper-pagination-bullet {
    background: $color-FFFFFF;
    opacity: 0.5;
    margin: 0 2px 1.5em !important;
  }
  .swiper-pagination > .swiper-pagination-bullet-active {
    background: $color-FFFFFF;
    opacity: 1;
  }
}

.home_sec4 {
  padding: 8.188em 0 7.938em;
  .judges_heading {
    @include fontStyle(1.875em, 1.2em, normal, 200);
    text-transform: uppercase;
    text-align: center;
  }
  .judges_wrapper {
    padding: 6.25em 0 6.688em;
    margin: 0 -1.25em;
    .judge_profile {
      padding: 0 1.25em;
      .judge_img {
        width: 100%;
        margin-bottom: 0.938em;
      }
      .judge_name {
        @include fontStyle(0.875em, 1.4em, normal, 500);
        text-transform: uppercase;
      }
      .judge_desc {
        @include fontStyle(0.688em, 1.545em, 0.44px, 300);
        padding: 0.45em 0 0;
        text-transform: uppercase;
      }
    }
  }
  .btn_wrapper {
    width: 100%;
    max-width: 240px;
    margin: 0 auto;
  }
}

.home_sec5 {
  padding: 4.188em 0 6.188em;
  background-color: $color-FFFFFF;
  color: $color-000000;
  text-align: center;
  .my_container {
    max-width: 100%;
    padding: 0;
  }
  .text_container {
    .prize_heading {
      @include fontStyle(1.875em, 1.2em, normal, 200);
      text-transform: uppercase;
    }
    .prize_desc {
      @include fontStyle(1.125em, 1.3em, normal, 300);
      max-width: 30.167em;
      margin: 0.556em auto 0;
    }
  }
  .categories_wrapper {
    padding: 4.25em 0 6.125em;
    .category_container {
      width: 100%;
      max-width: 100%;
      padding: 3.125em 0;
      color: $color-FFFFFF;
      border-bottom: 1px solid $color-FFFFFF;
      .category_heading {
        @include fontStyle(3.125em, 1em, normal, 200);
      }
      .category_desc {
        @include fontStyle(1.125em, 1.4em, normal, 300);
        margin: 0.556em auto 0;
      }
      &.grow_category {
        background: $color-3172A9;
        .category_desc {
          max-width: 33.333em;
        }
      }
      &.imagine_category {
        background: $color-C774A0;
        .category_desc {
          max-width: 33.333em;
        }
      }
      &.create_category {
        background: $color-FC9500;
        .category_desc {
          max-width: 40em;
        }
      }
      .prize_amount {
        padding: 1em 0 0;
      }
    }
  }
  .btn_wrapper {
    display: flex;
    justify-content: center;
  }
}

.home_sec6 {
  padding: 0;
  background-color: $color-FFFFFF;
  .my_container {
    max-width: 100%;
    padding: 0;
  }
  .support_row {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    .box {
      width: 100%;
      max-width: 50%;
    }
    .box-2 {
      border-top: 1px solid $color-BCBCBC;
    }
  }
  .masterclass_row {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    .box {
      width: 100%;
      max-width: 50%;
    }
  }
  .text_container {
    text-align: left;
    margin: 0 auto;
    &.grow_text_container {
      height: 100%;
      max-width: 35.625em;
      padding: 3.438em 0 8em;
      margin: 0 0 0 2.5em;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .desc_wrapper {
        padding: 0 0 1.875em;
        &:last-child {
          padding: 0;
        }
        .desc_pointer {
          @include fontStyle(0.875em, 1.4em, normal, 500);
          margin: 0 0 0.357em;
        }
        .sec_desc {
          @include fontStyle(1.125em, 1.4em, normal, 300);
        }
      }
    }
    &.masterclass_text_container {
      height: 100%;
      max-width: 36.875em;
      padding: 22.063em 0 3.438em 2.5em;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin: 0;
      .desc_wrapper {
        padding: 0 0 1.875em;
        .sec_desc {
          @include fontStyle(1.125em, 1.4em, normal, 300);
        }
      }
    }
    .sec_heading {
      @include fontStyle(1.875em, 1.2em, normal, 200);
      text-transform: uppercase;
      &.support_heading {
        margin: 0 0 1em;
      }
      &.masterclass_heading {
        margin: 0 0 0.833em;
      }
    }
  }
  .main_img {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
}

.home_sec7 {
  padding: 12.5em 0;
  .press_swiper_wrapper {
    .swiper-wrapper {
      align-items: center;
    }
  }
  .press_heading {
    @include fontStyle(1.875em, 1.2em, normal, 200);
    text-transform: uppercase;
    text-align: center;
  }
  .press_swiper_wrapper {
    margin: 5.063em 0 4.5em;
    .swiper-wrapper {
      align-items: center;
    }
    .logo_wrapper {
      min-height: 63px;
      display: flex;
      align-items: center;
    }
    .brandlogo {
      width: 100%;
      max-width: 100%;
    }
  }
  .btn_wrapper {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .home_sec2 {
    .text_container {
      max-width: 100%;
      .section_desc,
      .section_subdesc {
        max-width: 100%;
      }
    }
  }

  .home_sec3 {
    .text_container {
      padding: 5em 1.5em;
      margin: 0 auto;
      .quote_text {
        font-size: 1.5em;
      }
      .name,
      .title {
        font-size: 1.4em;
      }
    }
  }

  .home_sec4 {
    .judges_heading {
      font-size: 2.5em;
    }
    .judges_wrapper {
      .judge_profile {
        .judge_name {
          font-size: 1rem;
        }
        .judge_desc {
          font-size: 0.875rem;
        }
      }
    }
  }

  .home_sec5 {
    .text_container {
      .prize_heading {
        font-size: 2.5em;
      }
      .prize_desc {
        font-size: 1.5em;
      }
    }
    .categories_wrapper {
      .category_container {
        .category_desc {
          font-size: 1.5em;
        }
        &.grow_category {
          .category_desc {
            max-width: 70%;
          }
        }
        &.imagine_category {
          .category_desc {
            max-width: 70%;
          }
        }
        &.create_category {
          .category_desc {
            max-width: 80%;
          }
        }
        .prize_amount {
          font-size: 1.5em;
        }
      }
    }
  }

  .home_sec6 {
    .text_container {
      .sec_heading {
        font-size: 2.5em;
      }
      &.grow_text_container,
      &.masterclass_text_container {
        padding: 5em 2.5em;
        .desc_wrapper {
          .sec_desc {
            font-size: 1.5em;
          }
        }
      }
    }
  }

  .home_sec7 {
    .press_heading {
      font-size: 2.5em;
    }
  }
}

@media (max-width: 600px) {
  .home_sec2 {
    .my_container {
      padding: 0 2em;
    }
    .text_container {
      padding: 26.4em 0 13.4em;
      .section_heading {
        font-size: 3em;
        line-height: 1.2em;
        max-width: 11.634em;
      }
      .section_desc {
        max-width: 20em;
        font-size: 1.5em;
        line-height: 1.33em;
        margin: 1em 0 0;
      }
    }
  }

  .home_sec3 {
    .text_container {
      max-width: 100%;
      padding: 2em 6.4em 10.4em 1.8em;
      .quote_text {
        line-height: 1.333em;
        margin: 0 0 1.333em;
      }
      .name {
        max-width: 15.5em;
      }
      .title {
        max-width: 19.727em;
        font-size: 1.1em;
      }
    }
  }

  .home_sec4 {
    padding: 10em 0 8.2em;
    .judges_heading {
      margin: 0 auto;
      max-width: 10.32em;
      font-size: 2.5em;
    }
    .judges_wrapper {
      padding: 6.3em 4em 4.1em;
      margin: 0;
      .judge_profile {
        padding: 0 0 3.5em;
        .judge_img {
          margin-bottom: 0.8em;
        }
        .judge_name {
          font-size: 1.4em;
        }
        .judge_desc {
          font-size: 0.688rem;
          padding: 0.365em 0 0;
        }
      }
    }
    .btn_wrapper {
      width: 100%;
      max-width: 180px;
      display: flex;
      justify-content: center;
      .project_button {
        max-width: 180px;
      }
    }
  }

  .home_sec5 {
    padding: 5.5em 0;
    .text_container {
      margin: 0 auto;
      .prize_heading {
        font-size: 2.5em;
      }
      .prize_desc {
        max-width: 100%;
        font-size: 1.5em;
        margin: 0.667em auto 0;
        br {
          display: block;
        }
      }
    }
    .categories_wrapper {
      padding: 5.5em 0;
      .category_container {
        padding: 4.5em 0;
        .category_heading {
          font-size: 2.5em;
        }
        .category_desc {
          font-size: 1.5em;
          margin: 0.667em auto 0;
        }
        &.grow_category {
          .category_desc {
            max-width: 75%;
          }
        }
        &.imagine_category {
          .category_desc {
            max-width: 75%;
          }
        }
        &.create_category {
          .category_desc {
            max-width: 80%;
          }
        }
      }
    }
  }

  .home_sec6 {
    .support_row,
    .masterclass_row {
      align-items: flex-start;
      .box {
        width: 100%;
        max-width: 100%;
      }
    }
    .text_container {
      max-width: 100%;
      &.grow_text_container {
        padding: 3em 6.7em 8em 2.6em;
        max-width: 100%;
        margin: 0;
        .desc_wrapper {
          padding: 0 0 2.5em;
          &.first_desc_wrapper {
            padding: 0 0 3em;
          }
          .desc_pointer {
            font-size: 1.4em;
          }
          .sec_desc {
            font-size: 1.5em;
          }
        }
      }
      &.masterclass_text_container {
        padding: 3em 6.7em 8em 2.8em;
        max-width: 100%;
        .desc_wrapper {
          padding: 0 0 3em;
          .sec_desc {
            font-size: 1.5em;
          }
        }
      }
      .sec_heading {
        font-size: 2.5em;
        &.support_heading,
        &.masterclass_heading {
          margin: 0 0 0.6em;
        }
      }
      .cta_wrapper {
        width: 100%;
        max-width: 240px;
        .project-button {
          padding: 1em 1.364em;
          max-width: 215px;
        }
      }
    }
  }

  .home_sec7 {
    padding: 8em 0 15em;
    .press_swiper_wrapper {
      margin: 4em 0;
    }
    .btn_wrapper {
      max-width: 240px;
      margin: 0 auto;
      .project-button {
        max-width: 116px;
        min-width: 116px;
        white-space: nowrap;
      }
    }
  }
}
