@import "../../common.scss";

.voting_imagine_sec2,
.voting_grow_sec2,
.voting_create_sec2 {
  position: relative;

  .close_icon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: rotate(90deg);
    }
  }

  .imagine_row {
    .imagine_col {
      padding: 0;
    }

    .imagine_finalist_details {
      height: 100%;
      padding: 2em 5.125em 2em;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center
    }

    .finalist_name {
      @include textStyle(1.563em, 1.2em, 0);
      color: $color-000000;
      font-weight: 200;
      text-transform: uppercase;
    }

    .finalist_desg {
      @include textStyle(0.875em, 1.4em, 0);
      color: $color-000000;
      font-weight: 500;
      text-transform: uppercase;
      margin: 1.071em 0 0;
    }

    .finalist_desc {
      @include textStyle(0.938em, 1.333em, 0);
      color: $color-000000;
      font-weight: 300;
      margin: 2.667em 0;
    }

    .finalist_btn_container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 1.25em;
    }

    .website_btn_wrapper {
      width: 100%;
      max-width: fit-content;

      .external-button {
        font-family: $GT;
        @include textStyle(0.813em, 1.205em, 0.33%);
        border-bottom: 1px solid $color-000000;
        cursor: pointer;
        margin: 0 1.154em;
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
          color: $color-FFFFFF;
          border-bottom: 1px solid $color-FFFFFF;
        }
      }
    }

    .vote_btn_wrapper {
      width: 100%;
      max-width: fit-content;
      margin: 0 auto;

      .voting_btn {
        margin: 0;

        .project-button {
          @include textStyle(0.813em, 1em, 0.33%);
          border: 1px solid $color-000000;
          cursor: pointer;
          padding: 0.923em 3.269em;
          text-transform: uppercase;
          border-radius: 5px;
          color: $color-000000;
          text-transform: uppercase;

          &:hover {
            color: var(--hover-color) !important;
            border: 1.5px solid $color-000000;
          }

          &::before {
            background-color: $color-000000;
          }

          &::after {
            background-color: $color-000000;
          }
        }
      }

      .project-button {
        border: 1.5px solid $color-000000;
        background-color: transparent;
        // padding: 0.353em;
        padding: 0.7em 0.5em;
        color: $color-000000;

        &:hover {
          color: #fbf7f3;
        }
      }

      .project-button.blue-color {
        border: 1.5px solid #9fa6d4;
        background-color: #9fa6d4;

        &:hover {
          background-color: #fbf7f3;
          // background-color: #9fa6d4;
          color: $color-000000;
          border: 1.5px solid $color-000000;
          // border: 1.5px solid #9fa6d4;
        }

        &::before {
          background-color: #fbf7f3;
          // background-color: #9fa6d4;
        }

        &::after {
          background-color: #fbf7f3;
          // background-color: #9fa6d4;
        }
      }

      .project-button.yellow-color {
        &:hover {
          background-color: #fdbc00;
          border: 1.5px solid #fdbc00;
        }

        &::before {
          background-color: #fdbc00;
        }

        &::after {
          background-color: #fdbc00;
        }
      }
    }

    .vote_grow_color {
      .project-button {

        // background-color: #9fa6d4;
        &:hover {
          // color: #fbf7f3;
          border: 1.5px solid #9fa6d4;
          background-color: #9fa6d4;
        }

        &::before {
          background-color: #9fa6d4;
        }

        &::after {
          background-color: #9fa6d4;
        }
      }
    }

    .vote_create_color {
      .project-button {

        // background-color: #fdbc00;
        &:hover {
          // color: #fbf7f3;
          border: 1.5px solid #fdbc00;
          background-color: #fdbc00;
        }

        &::before {
          background-color: #fdbc00;
        }

        &::after {
          background-color: #fdbc00;
        }
      }
    }

    .project-button {
      border: 1.5px solid $color-000000;
      background-color: transparent;
      // padding: 0.415em;
      padding: 0.684em 0.5em;
      ;
      color: $color-000000;
      letter-spacing: 0.33px;

      &:hover {
        // color: #fbf7f3;
        color: $color-000000;
      }

      &::before {
        // background-color: $color-000000;
        background-color: transparent;
      }

      &::after {
        // background-color: $color-000000;
        background-color: transparent;
      }
    }

    .imagine_finalist_image {
      height: 100%;
    }

    .finalist_img {
      width: 100%;
      // max-width: 44.333125em;
      max-width: 100%;
    }
  }
}

.voting_grow_sec2 {
  .imagine_row {
    .website_btn_wrapper {
      .project-button {
        &:hover {
          background-color: #9fa6d4;
          border: 1.5px solid #9fa6d4;
        }

        &::before {
          background-color: #9fa6d4;
        }

        &::after {
          background-color: #9fa6d4;
        }
      }
    }
  }
}

.voting_create_sec2 {
  .imagine_row {
    .website_btn_wrapper {
      .project-button {
        &:hover {
          background-color: #fdbc00;
          border: 1.5px solid #fdbc00;
        }

        &::before {
          background-color: #fdbc00;
        }

        &::after {
          background-color: #fdbc00;
        }
      }
    }
  }
}


@media screen and (min-height: 1080px) {
  .voting_imagine_sec2 {
    .imagine_row {
      min-height: 80vh;
    }
  }

  .voting_imagine_sec2 .imagine_row .finalist_img,
  .voting_grow_sec2 .imagine_row .finalist_img,
  .voting_create_sec2 .imagine_row .finalist_img {
    height: 100%;
  }
}

@media (max-width: 992px) {

  .voting_imagine_sec2,
  .voting_grow_sec2,
  .voting_create_sec2 {
    .imagine_row {

      // .finalist_desc {
      //   font-size: 1.6em;
      // }
      .imagine_finalist_image {
        text-align: center;
      }

      .finalist_img {
        height: 100%;
      }
    }
  }
}

@media (max-width: 600px) {

  .voting_imagine_sec2,
  .voting_grow_sec2,
  .voting_create_sec2 {
    .imagine_row {
      .imagine_finalist_details {
        padding: 5.8em 3.1em 11.4em;
      }

      .finalist_name {
        @include textStyle(2.5em, 1.2em, 0);
      }

      .finalist_desg {
        @include textStyle(1.4em, 1.4em, 0);
        margin: 1.071em 0 0;
      }

      .finalist_desc {
        @include textStyle(1.5em, 1.333em, 0);
        margin: 2em 0 2.67em;
      }

      .imagine_finalist_image {
        height: auto;
        padding: 10.6em 2.6em 0;
      }

      .finalist_img {
        // max-width: 37.5em;
        max-width: 100%;
      }

      .finalist_btn_container {
        margin: 0 0 3em;
      }

      .website_btn_wrapper {
        .external-button {
          @include textStyle(1.3em, 1.4em, 0);
        }

        .project-button {
          // padding: 0.285em;
          padding: 0.285em;
        }

        .project-button {
          padding: 0.285em;
        }
      }

      .vote_btn_wrapper {
        .voting_btn {
          max-width: fit-content;
          margin: 0;

          .project-button {
            max-width: fit-content;
            @include textStyle(1.3em, 1.4em, 0);
          }
        }
      }

    }
  }
}