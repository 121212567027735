@import "../../common.scss";

.winners2024_sec2 {
  padding: 16em 0 15.25em;
  border-bottom: 1px solid #bcbcbc;
  .winners_content {
    width: 100%;
    max-width: 40.625em;
    margin: 0 auto;
    .winners_text {
      color: #000;
      font-size: 1.125em;
      font-weight: 300;
      line-height: 1.4;
      text-align: center;
    }
    .link {
      color: inherit;
      text-decoration: underline;
    }
  }
}

.winners2024_sec3 {
  .details_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 2em 7.53125em 2em 7.59375em;
    .category_head {
      color: #3172A9;
      text-align: center;
      font-size: 1.5625em;
      font-weight: 200;
      line-height: normal;
      text-transform: uppercase;
      br {
        display: none;
      }
    }
    .detail_wrapper {
      margin: 1.875em 0 1.25em;
    }
    .brand {
      color: #000;
      text-align: center;
      font-size: 0.875em;
      font-weight: 500;
      line-height: 1.4;
      text-transform: uppercase;
    }
    .winner_name {
      color: #000;
      text-align: center;
      font-size: 0.875em;
      font-weight: 500;
      line-height: 1.4;
      text-transform: uppercase;
    }
    .desc {
      color: #000;
      text-align: center;
      font-size: 0.9375em;
      font-weight: 300;
      line-height: 1.334;
    }
    .winners_btn_container {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 1.25em;
      .winners_btn_wrapper {
        margin-right: 1.875em;
        &:last-child {
          margin-right: 0;
        }
      }
      .winners_cta {
        color: #000;
        text-align: center;
        font-family: $GT;
        font-size: 0.8125em;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.043px;
        text-decoration-line: underline;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }

  .winners_img_container {
    width: 100%;
    max-width: 100%;
    height: 100%;
    .winners_img {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 992px) {
  .winners2024_sec2 {
    .winners_content {
      max-width: 50em;
      .winners_text {
        font-size: 1.5em;
      }
    }
  }

  .winners2024_sec3 {
    .details_container {
      padding: 2em 3em 2em 3em;
      .detail_wrapper {
        margin: 2.5em 0 1.667em;
      }
      .category_head {
        font-size: 1.5625em;
      }
      .brand {
        font-size: 1.1667em;
      }
      .winner_name {
        font-size: 1.1667em;
      }
      .desc {
        font-size: 1.25em;
      }
      .winners_btn_container {
        margin-top: 2.5em;
        .winners_btn_wrapper {
          margin-right: 1.875em;
          &:last-child {
            margin-right: 0;
          }
        }
        .winners_cta {
          font-size: 1.08334em;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .winners2024_sec2 {
    padding: 10em 0;
    border-bottom: none;
    .winners_content {
      max-width: 100%;
      padding: 0 10.5px 0 10.5px;
      .winners_text {
        font-size: 1.5em;
        font-weight: 300;
        line-height: 1.334; 
      }
    }
  }

  .winners2024_sec3 {
    padding: 0 0 6em;
    .details_container {
      padding: 3em 4.65em 6em;
      .detail_wrapper {
        margin: 2.5em 0 1.5em;
      }
      .category_head {
        font-size: 3em;
        font-weight: 200;
        letter-spacing: -0.05em;
        line-height: 1.1;
        br {
          display: block;
        }
      }
      .winner_name {
        font-size: 1.4em;
        font-weight: 500;
        line-height: 1.4;
      }
      .brand {
        font-size: 1.4em;
        font-weight: 500;
        line-height: 1.4;
      }
      .desc {
        font-size: 1.5em;
        font-weight: 300;
        line-height: 1.334;
      }
      .winners_btn_container {
        margin-top: 1.5em;
       .winners_btn_wrapper {
        margin-right: 3em;
       }
        .winners_cta {
          font-size: 1.1em;
          font-weight: 400;
          letter-spacing: 0.0033em;
          line-height: 1.205;
        }
      }
    }
  }
}
