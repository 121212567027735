@import "../../common.scss";
.header.active {
  background-color: transparent;
}
.header.fixed {
  position: fixed;
  background-color: $color-FFFFFF;
  .apply_btns_wrapper {
    font-family: "GT America Mono";
    a {
      text-decoration: none;
    }
  }
}
.nav_icon.fixed {
  position: fixed;
}
.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  .header_flex {
    // border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    // border-bottom: 1px solid $color-FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25em 2.5em;
    position: relative;
    min-height: 4.75em;
  }
  .brandlogo_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .brandlogowhite {
      width: 100%;
      max-width: 19.5em;
      object-fit: contain;
      object-position: left;
    }
  }
  .apply_btns_wrapper {
    display: inline-block;
    width: 100%;
    max-width: 240px;
    // max-width: 16.875rem;
    // min-width: 16.875rem;
    a {
      text-decoration: none;
    }
    .apply_btn {
      padding: 0.958em 2.625em;
      height: 2.917em;
      &:hover {
        border: none;
        font-weight: bold;
      }
    }
    .header_cta {
      max-width: 150px;
    }
  }
}
.nav_icon {
  position: absolute;
  width: 1.75em;
  height: 1.75em;
  z-index: 7;
  top: 1.5em;
  right: 2.5em;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  span {
    display: block;
    width: 100%;
    height: 0.1em;
    background-color: #fff;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  &.open_nav {
    // transform: translateY(-0.5em);
    span {
      background-color: $color-000000;
    }
  }
  &.open_nav span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: translateY(0.6em) rotate(135deg);
  }

  &.open_nav span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }

  &.open_nav span:nth-child(3) {
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: translateY(-0.5em) rotate(-135deg);
  }
}
.full_nav_wrapper {
  //   display: none;
  background-color: rgba($color: $color-000000, $alpha: 0.2);
  position: fixed;
  opacity: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: 0.6s opacity;
  display: flex;
  .hamburger_empty_wrapper {
    width: 100%;
    height: 100%;
    max-width: 66.5%;
    z-index: 1;
    background-color: transparent;
  }
  .hamburger_inside_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 33.5%;
    padding: 4.5rem 2.375em 0;
    float: right;
    background-color: $color-FFFFFF;
    z-index: 2;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .main_nav_links_wrapper {
    flex-grow: 1;
  }
  .header_flex {
    border-bottom: 1px solid $color-000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    min-height: 4.75em;
  }
  .brandlogo_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .brandlogowhite {
      width: 100%;
      max-width: 11.813em;
    }
  }
  .apply_btns_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .apply_btn {
      padding: 0 1.55em;
      height: 2.917em;
      &:hover {
        border: none;
      }
    }
  }
  .nav_links_list {
    li {
      border-bottom: 1px solid $color-000000;
      padding: 1em 0;
      font-size: 0.938em;
      line-height: 1.5em;
      letter-spacing: 0.6px;
      &:last-child {
        border: none;
      }
    }
    .nav_link {
      color: $color-000000;
      text-transform: uppercase;
      font-weight: 300;
      &:hover {
        text-decoration: none;
      }
    }
    .winner_drop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      .open_close_icon {
        max-width: 1.25em;
        margin: 0 0 0 1em;
      }
    }
    .winners_list {
      display: none;
      color: $color-000000;
      padding: 0.5em 0 0 2.5em;
      &.active {
        display: block;
      }
      .list-item {
        padding: 0.25em 0;
        border: none;
        font-size: 1em;
        .winner_link {
          text-transform: uppercase;
          text-decoration: none;
        }
      }
    }
  }
  .social_icons_wrapper {
    margin-top: 1.063em;
    a {
      margin-left: 2em;
      &:first-child {
        margin-left: 0;
      }
    }
    img {
      max-width: 1.563em;
      transition: 0.3s transform;
      &:hover {
        transform: scale(1.3);
      }
    }
  }
  .footer_flex {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    margin-top: 4.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 1em;
    .footer_left {
      padding-top: 1.5em;
      width: 100%;
      max-width: 69.5%;
    }
    .footer_right {
      padding-top: 1.5em;
      width: 100%;
      max-width: 29.5%;
    }
    .footer_left {
      .link1 {
        font-weight: 300;
      }
      .link1,
      .link2,
      .link3 {
        font-size: 0.75em;
        color: $color-000000;
        font-family: "GT America Mono";
        text-transform: uppercase;
        margin-bottom: 0.469em;
        a {
          color: $color-000000;
        }
      }
      .link3 {
        margin: 0;
      }
      .line2_img {
        margin: 0 0 0.75em;
        img {
          max-width: 14.25em;
        }
      }

      .footer_form_field {
        font-size: 1.125em;
        color: $color-FFFFFF;
        font-family: "GT America Mono";
        padding-top: 1.444em;
        padding-bottom: 1.444em;
        padding-left: 1.54em;
        background-color: transparent;
        border: 1.5px solid $color-FFFFFF;
        width: 100%;
        display: block;
        max-width: 51%;
        border-radius: 2.389em;
        &::-webkit-input-placeholder {
          /* Edge */
          color: $color-FFFFFF;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $color-FFFFFF;
        }

        &::placeholder {
          color: $color-FFFFFF;
        }
      }
    }
    .footer_right {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-direction: column;
      .footer_link {
        display: block;
        color: $color-000000;
        font-family: "GT America Mono";
        text-transform: uppercase;
        font-size: 0.75em;
        &:nth-child(n + 2) {
          margin-top: 0.4em;
        }
      }
      .footer_social_icons_wrapper {
        margin-top: 0.325em;
        display: flex;
        align-items: flex-end;
        a {
          margin-left: 0.75em;
          &:first-child {
            margin-left: 0;
          }
        }
        img {
          max-width: 0.938em;
          max-height: 0.938em;
          object-fit: cover;
          vertical-align: middle;
          transition: 0.3s transform;
          &:hover {
            transform: scale(1.3);
          }
        }
      }
    }
  }
}
.full_nav_wrapper.active {
  z-index: 6;
  opacity: 1;
  .brandlogo_wrapper {
    display: none;
  }
  .header_flex {
    border: none;
  }
  .main_nav_links_wrapper {
    padding: 1.333em 0 0;
  }
}
.nav_icon.active {
  span {
    background-color: $color-000000;
  }
}
@media (min-width: 2560px) {
  .header {
    .header_flex {
      min-height: 7em;
    }
  }
}

@media (min-width: 1800px) {
  .header {
    .brandlogo_wrapper {
      .brandlogowhite {
        min-width: 13.25em;
        max-width: 13.25em;
      }
    }
  }
  .nav_icon {
    top: 2em;
    span {
      height: 0.1em;
    }
  }
}
@media (max-width: 1400px) {
  .nav_icon.open_nav span:nth-child(3) {
    transform: translateY(-0.6em) rotate(-135deg);
  }
}
@media (max-width: 1279px) {
  .full_nav_wrapper {
    .footer_flex {
      width: 98%;
      // left: 50%;
      // transform: translateX(-50%);
    }
  }
}
@media (max-width: 1024px) {
  .full_nav_wrapper {
    .footer_flex {
      .footer_right {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

@media (max-width: 991px) {
  .header {
    .brandlogo_wrapper {
      left: 1.625rem;
      transform: translate(0%, -50%);
    }
    .header_flex {
      height: 7em;
    }
    .apply_btns_wrapper {
      display: none;
    }
  }
  .nav_icon {
    top: 2.5em;
  }
}

@media (max-width: 767px) {
  .nav_icon {
    right: 1.5em;
  }
  .full_nav_wrapper {
    .hamburger_empty_wrapper {
      max-width: 5%;
    }
    .hamburger_inside_wrapper {
      max-width: 95%;
    }
    .header_flex {
      height: 7em;
      padding-top: 3.563em;
      padding-bottom: 3.563em;
    }
    .apply_btns_wrapper {
      margin-top: 1.5em;
      margin-bottom: 1.5em;
      .apply_btn {
        padding: 0 3.55em;
      }
    }
    .nav_links_list {
      .nav_link {
        font-size: 1.4em;
      }
      .winners_list {
        .list-item {
          font-size: 1.4em;
        }
      }
    }
    .footer_flex {
      // flex-direction: column;
      width: 100%;
      margin-top: 5em;
      .footer_left,
      .footer_right {
        max-width: 100%;
      }
      .footer_right {
        padding-top: 0;
        border-top: 0;
        flex-direction: column;
        .footer_link {
          font-size: 0.75rem;
          line-height: 1.714em;
        }
        .col2_right {
          margin-top: 5.6px;
          padding-bottom: 2em;
        }
      }
      .footer_left {
        padding-top: 1em;
        .line1 {
          font-size: 1.4em;
        }
        .line2_img {
          margin-bottom: 1.5em;
          img {
            max-width: 90%;
          }
        }
      }
    }
    .footer {
      margin: 0 -15px;
      .footer_flex {
        margin-top: 0;
        .footer_left {
          border-top: none;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .header {
    .header_flex {
      height: auto;
      padding: 17px 15px;
      min-height: 62px;
    }
    .brandlogo_wrapper {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
    }
  }
  .full_nav_wrapper {
    max-width: 100%;
    .hamburger_empty_wrapper {
      max-width: 15%;
    }
    .hamburger_inside_wrapper {
      max-width: 85%;
    }
    .nav_links_list {
      .nav_link {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.6px;
      }
    }
    .footer_flex {
      flex-direction: column;
      .footer_left {
        .link1,
        .link2,
        .link3 {
          font-size: 12px;
          line-height: 22px;
        }
        .link3 {
          margin-bottom: 0.469em;
        }
      }
      .footer_right {
        flex-direction: row;
        align-items: center;
        .footer_social_icons_wrapper {
          img {
            max-width: 15px;
            max-height: 15px;
          }
        }
      }
    }
  }
  .nav_icon {
    top: 28px;
    width: 28px;
    height: 2.4em;
    span {
      height: 2px;
    }

    &.open_nav {
      height: 2em;
    }
  }
}
