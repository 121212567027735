@import "../../common.scss";

.contactsec {
  padding: 14.375em 0 9.375em;

  .section_subheading {
    text-align: center;
  }

  .mail_box_wrapper {
    max-width: 47.875em;
    margin: 0 auto;
    padding: 6.25em 0 0;

    .contact_box {
      padding: 0 0 6.25em;
      text-align: center;

      .title {
        @include fontStyle(0.875em, 1.4em, normal, 500);
        text-transform: uppercase;
      }

      .redirection {
        display: block;
        @include fontStyle(0.938em, 1.4em, normal, 400);
        color: $color-000000;
        text-decoration: underline;
        margin: 0.667em 0 0;
      }
    }

    .contact_box_1,
    .contact_box_3 {
      padding-right: 4.688em;
    }

    // .contact_box_2,
    // .contact_box_4 {
    //   padding-left: 4.688em;
    // }
  }

  .social_wrapper {
    width: 100%;
    max-width: 47.875em;
    margin: 0 auto;

    .title {
      @include fontStyle(0.875em, 1.4em, normal, 500);
      text-transform: uppercase;
      text-align: center;
    }

    .social_flex {
      display: flex;
      justify-content: center;
      align-items: center;

      .social_link {
        display: block;
        margin: 0.625em 0.75em 0;

        .social_icon {
          width: 1.438em;
          height: 1.438em;
          object-fit: contain;
        }
      }
    }
  }
}

@media (min-height: 1040px) {
  .contactsec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 85vh;
  }
}

@media (max-width: 992px) {
  .contactsec {
    .section_subheading {
      font-size: 2.5em;
    }

    .mail_box_wrapper {
      padding: 15em 4.3em 0;

      .contact_box {
        padding: 0 0 5em;
        text-align: left;

        .title {
          font-size: 1.4em;
        }

        .redirection {
          font-size: 1.5em;
        }
      }
    }

    .social_wrapper {
      padding: 0 4.3em;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 1.4em;
        text-align: left;
      }

      .social_flex {
        justify-content: flex-start;

        .social_link {
          margin: 1.5em 2.5em 0 0;

          .social_icon {
            width: 2.4em;
            height: 2.4em;
          }
        }
      }
    }
  }
}

@media screen and (max-width:768px) {
  .contactsec {
    .social_wrapper {
      align-items: flex-start;
    }
  }
}

@media (max-width: 600px) {
  .contactsec {
    padding: 16.4em 0 15em;

    .section_subheading {
      padding: 0 0 4.28em;
    }

    .mail_box_wrapper {
      border-top: 1px solid $color-BCBCBC;
    }
  }
}