@import "../../common.scss";

.banner_img_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  .banner-img {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    object-position: top center;
  }
  .text_container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-FFFFFF;
    .banner-title {
      text-align: center;
    }

    &.text_with_logo {
      flex-direction: column;
      .bny_logo {
        display: block;
        width: 100%;
        max-width: 27.5em;
        padding: 0.5em 0 0;
      }
    }
  }
}

@media (max-width: 600px) {
  .banner_img_wrapper {
    .text_container {
      .banner-title {
        font-size: 3em;
        line-height: 1.2em;
      }
      &.text_with_logo {
        .bny_logo {
          max-width: 25em;
        }
      }
    }
  }
}
